/* eslint-disable react/no-unstable-nested-components */
import { Table, Typography } from "antd";
import userSlice from "app/store/user/user.slice";
import { variance_columns } from "./variance_columns";
import VarianceTableSummary from "./variance_table_summary";

const VarianceTableHeader = ({ type }: { type: "cost" | "revenue" }) => {
    const { user } = userSlice((state) => state);

    return (
        <>
            <Table
                className="budget-sheet__meta-table budget-sheet__main-header-table"
                showHeader={false}
                columns={variance_columns({ with: user })}
                summary={() => (
                    <VarianceTableSummary
                        items={{
                            0: (
                                <div className="budget-sheet__header">
                                    <Typography.Text className="budget-sheet__title">
                                        {type === "cost" ? "Costs" : "Revenues"}
                                    </Typography.Text>
                                </div>
                            ),
                        }}
                    />
                )}
            />

            <Table
                className="budget-sheet__meta-table"
                columns={variance_columns({ with: user, type })}
            />
        </>
    );
};

export default VarianceTableHeader;

import { ListingType } from "app/types";

export const sortListingsInOrder = (
    listings: ListingType[],
    currentListingSelection: number | undefined
): { firstListing: ListingType | undefined; restListings: ListingType[] } => {
    const restListings = listings.filter(
        (listing: ListingType) => listing.pk !== currentListingSelection
    );
    const firstListing = listings.find(
        (listing: ListingType) => listing.pk === currentListingSelection
    );

    return { restListings, firstListing };
};

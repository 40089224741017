export const prepareRaceBody = (formData: any, mode: "add" | "edit") => {
    if (mode === "add") Reflect.deleteProperty(formData, "pk");
    const { type, postal_code, timezone, country, events, region } = formData;
    const body = { ...formData };
    const createTypeObject = null;

    if (postal_code) {
        body.postal_code = postal_code.toString();
    }

    if (timezone) {
        if (timezone?.code === "BST") body.timezone = { code: timezone?.code, pk: timezone?.pk };
        else body.timezone = { code: timezone?.code };
    }

    if (country) {
        body.country = { name: country?.name };
    }

    if (region?.name) {
        body.region = { name: region?.name };
    } else if (region?.code) {
        body.region = { code: region?.code };
    } else {
        body.region = region;
    }

    // Prepare the logo for submission
    if (typeof formData.logo === "object" && mode === "edit" && formData.logo?.url)
        body.logo = formData.logo.url;

    if (type) {
        // Transform Race type
        body.type = !type
            ? createTypeObject
            : {
                  name: type.name,
                  discipline__name: type?.discipline__name
                      ? type.discipline__name
                      : type.discipline,
              };
    }

    if (events) {
        const newEvents = [...events];
        body.events = newEvents.map((ev) => ({
            distance: ev.distance,
            distance_units: ev.distance_units,
            entry_fee: ev.entry_fee,
            name: ev.name,
            participants: ev.participants,
            start_time: ev.start_time,
            entry_fee_schedule: ev.entry_fee_schedule,
            pk: ev.pk,
        }));
    }

    return body;
};

import { useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { _isEmpty } from "app/utils/helpers";
import { IUser } from "app/store/types/user.types";
import { BUSINESS_SIDE_MENU } from "app/lib/navigation/navigation";
import { useQuoteRequestsCount } from "app/utils/api/queries/quote_requests";
import { ListingType } from "app/types";
import GlobalModals from "app/components/modules/global_modals";
import URL from "app/constants/route_urls";
import LoadingScreen from "app/components/elements/loading_screen";
import TopNavigation from "app/components/modules/navigation/top_navigation";
import ListingSelect from "app/components/modules/listing_select/listing_select";
import SideNavigation from "app/components/modules/navigation/side_navigation";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import listingsSlice from "app/store/listings/listings.slice";
import "./index.scss";

// TODO -> Change the 'navbar' so it doesn't come from the 'modalsSlice'
const VendorLayout = () => {
    const location = useLocation();
    const { isAuthenticated, user, getUser } = userSlice();
    const { navbar, toggleNavbar } = modalsSlice((state) => state);
    const { currentListingSelection, listing } = listingsSlice((state) => state);
    const { data: quoteRequestsCount } = useQuoteRequestsCount(currentListingSelection, false, {
        enabled: currentListingSelection !== undefined,
    });

    const prepareBusinessItems = BUSINESS_SIDE_MENU.map((menu) => ({
        ...menu,
        children: menu.children.map((child) => {
            if (child?.count) return { ...child, count: quoteRequestsCount };
            return child;
        }),
    }));

    useEffect(() => {
        if (isAuthenticated && _isEmpty(user)) getUser();
    }, [user, isAuthenticated, getUser]);

    if (_isEmpty(user)) return <LoadingScreen type="layout" />;
    if (!isAuthenticated) return <Outlet />;
    if (location?.pathname === URL.BUSINESS_DASHBOARD || location?.pathname === "/business")
        return <Navigate to={URL.BUSINESS_LISTINGS} />;

    return (
        <main className={`layout ${navbar.isToggled ? "isToggled" : ""}`}>
            <div
                role="presentation"
                className={`layout__overlay ${navbar.isToggled ? "isVisible" : ""}`}
                onClick={toggleNavbar}
            />
            <GlobalModals />
            <TopNavigation
                user={user as IUser}
                isNavbarToggled={navbar.isToggled}
                toggleNavbar={toggleNavbar}
                selectionTool={<ListingSelect />}
                listing={listing as ListingType}
            />
            <SideNavigation
                user={user as IUser}
                isNavbarToggled={navbar.isToggled}
                toggleNavbar={toggleNavbar}
                menuLinks={prepareBusinessItems}
            />
            <div
                className={`main-content ${navbar.isToggled ? "isToggled" : ""}`}
                style={{ backgroundColor: "#f9fafc", minHeight: "100vh" }}
            >
                <Outlet />
            </div>
        </main>
    );
};

export default VendorLayout;

import { ReactElement, useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { Button, Input, Typography } from "antd";
import { useFormik } from "formik";
import {
    UserNewPasswordSchema as validationSchema,
    IFormikNewPassword,
} from "app/lib/validation_schemas/auth.schema";
import { blurred, inputError, errorValidationMessage } from "app/utils/validation";
import { _isEmpty } from "app/utils/helpers";
import Dialog from "app/components/elements/dialog";
import userSlice from "app/store/user/user.slice";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import logo from "assets/logo_full.svg";
import URL from "app/constants/route_urls";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import "./index.scss";

const { Text } = Typography;

function ResetPasswordConfirm(): ReactElement {
    const match = useMatch(URL.VERIFY_PASSWORD_RESET);
    const navigate = useNavigate();
    const { isAuthenticated } = userSlice((state) => state);

    const [params, setParams] = useState<{ uid: string; token: string }>({ uid: "", token: "" });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apiErrors, setApiErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        if (match && !_isEmpty(match.params)) {
            if ("uid" in match.params && "token" in match.params) {
                setParams({ uid: match.params.uid as string, token: match.params.token as string });
            } else navigate(URL.LOGIN);
        }
    }, [match, navigate]);

    useEffect(() => {
        if (isAuthenticated) navigate(URL.DASHBOARD);
    }, [isAuthenticated, navigate]);

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm } =
        useFormik({
            initialValues: {
                new_password1: "",
                new_password2: "",
            },
            validationSchema,
            onSubmit: async (values: {
                new_password1: string;
                new_password2: string;
            }): Promise<void> => {
                try {
                    setIsLoading(true);
                    const { new_password1, new_password2 }: { [key: string]: string } = values;

                    const { data } = await API.post(API_URL.PASSWORD_RESET_CONFIRM, {
                        new_password1,
                        new_password2,
                        uid: params.uid,
                        token: params.token,
                    });

                    setIsLoading(false);
                    Storage.set(STORAGE_CONSTANTS.passwordResetSuccessMessage, data.detail);

                    resetForm();
                    navigate(URL.LOGIN);
                } catch (error) {
                    setIsLoading(false);
                    setApiErrors(error.response.data);
                }
            },
        } as IFormikNewPassword);

    const displayNonFieldErrors = (): JSX.Element | undefined | null => {
        if (apiErrors && Object.keys(apiErrors).length !== 0) {
            if ("token" in apiErrors || "uid" in apiErrors) {
                return (
                    <Dialog
                        message="Something went wrong. Please use the link we sent you via email and try again"
                        type="error"
                    />
                );
            }
        }
        return null;
    };

    // Button disabled status
    const buttonDisabled = (): boolean | undefined | boolean => {
        const checkValues = Object.values(values).some((val: string) => val.length === 0);
        if (isLoading || (errors && Object.keys(errors).length !== 0 && checkValues)) return true;
        return false;
    };

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href="/" rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {displayNonFieldErrors()}
                        <form onSubmit={handleSubmit}>
                            <div className="auth-wrap__form-group">
                                <Text className="ant-label" strong>
                                    New password
                                </Text>
                                <Input.Password
                                    name="new_password1"
                                    value={values.new_password1 || ""}
                                    placeholder="Enter your password"
                                    status={
                                        inputError("new_password1", errors, values, touched)
                                            ? "error"
                                            : ""
                                    }
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(event);
                                    }}
                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleBlur(event);
                                        blurred("new_password1", event, errors);
                                    }}
                                />
                                <span className="auth-wrap__span-error">
                                    {errorValidationMessage(
                                        "new_password1",
                                        errors,
                                        values,
                                        touched,
                                        apiErrors
                                    )}
                                </span>
                            </div>
                            <div className="auth-wrap__form-group">
                                <Text className="ant-label" strong>
                                    Confirm new password
                                </Text>
                                <Input.Password
                                    name="new_password2"
                                    value={values.new_password2 || ""}
                                    placeholder="Enter your password"
                                    status={
                                        inputError("new_password2", errors, values, touched)
                                            ? "error"
                                            : ""
                                    }
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleChange(event);
                                    }}
                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleBlur(event);
                                        blurred("new_password2", event, errors);
                                    }}
                                />

                                <span className="auth-wrap__span-error">
                                    {errorValidationMessage(
                                        "new_password2",
                                        errors,
                                        values,
                                        touched,
                                        apiErrors
                                    )}
                                </span>
                            </div>
                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={buttonDisabled()}
                                    loading={isLoading}
                                >
                                    Reset password
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordConfirm;

export const DateUtils = (() => ({
    formatDateString(dateStr: string, includeTime = false): string {
        const newDate: Date = new Date(dateStr);
        const year: string = newDate.getFullYear().toString();
        const month: string = newDate.toLocaleString("default", { month: "long" }).slice(0, 3);
        const day = String(newDate.getDate());

        let formattedDate = `${month} ${day}, ${year}`;

        if (includeTime) {
            const hours: number = newDate.getHours();
            const minutes: number = newDate.getMinutes();
            const ampm: string = hours >= 12 ? "pm" : "am";
            const formattedHours: number = hours % 12 || 12;
            const formattedMinutes: string = minutes < 10 ? `0${minutes}` : String(minutes);
            const dot = "\u2022";
            formattedDate += ` ${dot} ${formattedHours}:${formattedMinutes} ${ampm}`;
        }

        return formattedDate;
    },
    // Works with format "MMM DD, YYYY" and "DD MMMM YYYY"
    formatDateRegex(dateStr: string): string {
        const regex = /^(\w+|\d{1,2})\s+(\w+),?\s+(\d{4})$/;
        const [, dayOrMonth, monthOrDay, year] = regex.exec(dateStr)!;
        const isDayFirst = !Number.isNaN(Number(dayOrMonth));
        const [day, month] = isDayFirst ? [dayOrMonth, monthOrDay] : [monthOrDay, dayOrMonth];
        const monthNumber = new Date(Date.parse(`${month} 1, 2000`)).getMonth() + 1;
        const formattedDate = `${year}-${monthNumber.toString().padStart(2, "0")}-${day
            .toString()
            .padStart(2, "0")}`;
        return formattedDate;
    },

    daysUntil(date: string): number {
        const futureDate = new Date(date);
        const today = new Date();
        const difference = futureDate.getTime() - today.getTime();
        const days = Math.ceil(difference / (1000 * 60 * 60 * 24));
        return days;
    },
}))();

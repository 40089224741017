import { useState, ReactElement, useEffect } from "react";
import { SpinnerOutlined } from "rdhq-icons";
import { Link, useLocation } from "react-router-dom";
import { Button, Input, Typography } from "antd";
import { useFormik } from "formik";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { NonFieldErrorType } from "app/utils/api/axios/service_error";
import { blurred, inputError, errorValidationMessage } from "app/utils/validation";
import {
    UserSignUpSchema as validationSchema,
    IFormikRegister,
} from "app/lib/validation_schemas/auth.schema";
import { _isEmpty } from "app/utils/helpers";
import Dialog from "app/components/elements/dialog";
import GoogleAuthLogin from "app/components/modules/google_auth";
import FacebookAuthLogin from "app/components/modules/facebook_auth";
import logo from "assets/logo_full.svg";
import URL from "app/constants/route_urls";
import Select from "app/components/elements/form/select";
import userSlice, { SOCIAL_PROVIDERS } from "app/store/user/user.slice";
import { IUserLocation } from "app/store/types/user.types";
import "./index.scss";

type SelectOptions = { value: string; name: string; icon: string };
type FieldTypes = "email" | "password" | "username" | "location";

const { Text } = Typography;

function Register(): ReactElement {
    const location = useLocation();

    const [isGoogleLoading, setIsGoogleLoading] = useState<boolean>(false);
    const [isFacebookLoading, setIsFacebookLoading] = useState<boolean>(false);
    const [isRegisterLoading, setIsRegisterLoading] = useState<boolean>(false);
    const [nonFieldErrors, setNonFieldErrors] = useState<NonFieldErrorType>([]);
    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
    const [serverErrors, setServerErrors] = useState<string>("");

    const { userLocations, getUserLocations, registerUser, socialLogin } = userSlice(
        (state) => state
    );

    useEffect(() => {
        getUserLocations();
    }, [getUserLocations]);

    const [isTouched, setIsTouched] = useState<{
        [field in FieldTypes]: boolean;
    }>({
        username: false,
        email: false,
        password: false,
        location: false,
    });

    // Formik
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        errors,
        touched,
        resetForm,
    } = useFormik({
        initialValues: {
            username: "",
            email: "",
            location: "",
            password1: "",
        },
        validationSchema,
        onSubmit: async (values): Promise<void> => {
            setNonFieldErrors([]);
            setServerErrors("");
            setIsRegisterLoading(true);

            const { username, email, location, password1 }: { [key: string]: string } = values;
            const [error] = await tryCatch(registerUser)(username, email, password1, location);

            if (error) {
                if (error.statusCode > 404) {
                    setServerErrors("Something went wrong. Please try again.");
                    return;
                }
                setIsRegisterLoading(false);
                setNonFieldErrors(error.nonFieldErrors);
                setFieldErrors(error.fieldErrors);
                return;
            }

            resetForm();
            setIsRegisterLoading(false);
        },
    } as IFormikRegister);

    const displayNonFieldErrors = (): JSX.Element[] | undefined | null => {
        if (!_isEmpty(nonFieldErrors)) {
            return nonFieldErrors.map((error: string) => (
                <Dialog message={error} key={error} type="error" />
            ));
        }
        return null;
    };

    const socialHandlers = {
        [SOCIAL_PROVIDERS.GOOGLE]: () => setIsGoogleLoading(!isGoogleLoading),
        [SOCIAL_PROVIDERS.FACEBOOK]: () => setIsFacebookLoading(!isFacebookLoading),
    };

    // Social login function
    const socialLoginHandler = async (
        socialToken: string,
        provider: SOCIAL_PROVIDERS.GOOGLE | SOCIAL_PROVIDERS.FACEBOOK
    ): Promise<void> => {
        setNonFieldErrors([]);
        socialHandlers[provider]();

        const [error] = await tryCatch(socialLogin)(socialToken, provider);
        if (error) socialHandlers[provider]();

        socialHandlers[provider]();
        resetForm();
    };

    // Touched Status utility
    const setTouchedStatus = (field: FieldTypes): void => {
        if (isTouched[field]) return;
        setIsTouched({
            ...isTouched,
            [field]: true,
        });
    };

    const selectOptions = (): SelectOptions[] | boolean =>
        !_isEmpty(userLocations) &&
        userLocations !== undefined &&
        userLocations?.reduce((acc: SelectOptions[], item: IUserLocation | undefined) => {
            if (item) acc.push({ value: item?.name, name: item?.name, icon: item?.ccy });
            return acc;
        }, [] as SelectOptions[]);

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="auth-wrap">
                    <div className="auth-wrap__header">
                        <a href="/" rel="noopener noreferrer">
                            <div className="auth-wrap__logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </a>
                    </div>
                    <div className="auth-wrap__content">
                        {!_isEmpty(serverErrors) && <Dialog type="error" message={serverErrors} />}
                        {displayNonFieldErrors()}
                        <div className="auth-wrap__social-group">
                            <div className="auth-wrap__social-group-item">
                                <GoogleAuthLogin
                                    text="Continue with Google"
                                    isLoading={isGoogleLoading}
                                    onError={() =>
                                        setNonFieldErrors([
                                            "Something went wrong. If the problem persists, please contact us.",
                                        ])
                                    }
                                    tokenHandler={(token: string) => {
                                        setNonFieldErrors([]);
                                        socialLoginHandler(token, SOCIAL_PROVIDERS.GOOGLE);
                                    }}
                                />
                            </div>
                            <div className="auth-wrap__social-group-item">
                                {isGoogleLoading && <SpinnerOutlined />}
                            </div>
                        </div>
                        <div className="auth-wrap__social-group">
                            <div className="auth-wrap__social-group-item">
                                <FacebookAuthLogin
                                    text="Continue with Facebook"
                                    isLoading={isFacebookLoading}
                                    onError={(err: string) => {
                                        setNonFieldErrors([err]);
                                    }}
                                    tokenHandler={(token: string) => {
                                        setNonFieldErrors([]);
                                        socialLoginHandler(token, SOCIAL_PROVIDERS.FACEBOOK);
                                    }}
                                />
                            </div>
                            <div className="auth-wrap__social-group-item">
                                {isFacebookLoading && <SpinnerOutlined />}
                            </div>
                        </div>
                        <div className="auth-wrap__divider">
                            <Typography.Text>Or sign up with e-mail</Typography.Text>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="auth-wrap__form-group">
                                <Text className="ant-label" strong>
                                    Username
                                </Text>
                                <Input
                                    name="username"
                                    type="text"
                                    value={values.username || ""}
                                    placeholder="e.g. Run for Hunger 5K"
                                    status={
                                        inputError("username", errors, values, touched)
                                            ? "error"
                                            : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleBlur(event);
                                        blurred("username", event, errors);
                                        setTouchedStatus("username");
                                    }}
                                />
                                <Typography.Text className="ant-error-label">
                                    {isTouched.username &&
                                        errorValidationMessage(
                                            "username",
                                            errors,
                                            values,
                                            touched,
                                            fieldErrors
                                        )}
                                </Typography.Text>
                            </div>
                            <div className="auth-wrap__form-group">
                                <Text className="ant-label" strong>
                                    Email
                                </Text>
                                <Input
                                    name="email"
                                    type="email"
                                    value={values.email || ""}
                                    placeholder="e.g. john@runforthehunger5k.com"
                                    status={
                                        inputError("email", errors, values, touched) ? "error" : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleBlur(event);
                                        blurred("email", event, errors);
                                        setTouchedStatus("email");
                                    }}
                                />
                                <Typography.Text className="ant-error-label">
                                    {isTouched.email &&
                                        errorValidationMessage(
                                            "email",
                                            errors,
                                            values,
                                            touched,
                                            fieldErrors
                                        )}
                                </Typography.Text>
                            </div>
                            <div className="auth-wrap__form-group">
                                <Text className="ant-label" strong>
                                    Location
                                </Text>
                                <Select
                                    name="location"
                                    value={values.location}
                                    options={selectOptions()}
                                    error={inputError("location", errors, values, touched)}
                                    onChange={(val) => setFieldValue("location", val)}
                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleBlur(event);
                                        blurred("location", event, errors);
                                        setTouchedStatus("location");
                                    }}
                                />

                                <Typography.Text className="ant-error-label">
                                    {isTouched.location &&
                                        errorValidationMessage(
                                            "location",
                                            errors,
                                            values,
                                            touched,
                                            fieldErrors
                                        )}
                                </Typography.Text>
                            </div>
                            <div className="auth-wrap__form-group">
                                <Text className="ant-label" strong>
                                    Password
                                </Text>
                                <Input.Password
                                    name="password1"
                                    value={values.password1 || ""}
                                    placeholder="Enter your password"
                                    status={
                                        inputError("password1", errors, values, touched)
                                            ? "error"
                                            : ""
                                    }
                                    onChange={handleChange}
                                    onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleBlur(event);
                                        blurred("password1", event, errors);
                                        setTouchedStatus("password");
                                    }}
                                />

                                <Typography.Text className="ant-error-label">
                                    {isTouched.password &&
                                        errorValidationMessage(
                                            "password1",
                                            errors,
                                            values,
                                            touched,
                                            fieldErrors
                                        )}
                                </Typography.Text>
                            </div>

                            <div className="auth-wrap__form-group--submit">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isRegisterLoading}
                                    disabled={
                                        isRegisterLoading || isFacebookLoading || isGoogleLoading
                                    }
                                >
                                    Sign up
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="auth__linking">
                    <Typography.Text>
                        Already have an account?{" "}
                        <Link
                            to={URL.LOGIN + (location?.search || "")}
                            state={location.state}
                            className="ant-link"
                        >
                            Sign in here
                        </Link>
                    </Typography.Text>
                </div>
            </div>
        </div>
    );
}

export default Register;

import { ReactElement, ReactNode, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Races from "app/components/templates/races/upcoming_races";
import WithMyRaces from "app/components/templates/races";
import STORAGE_CONSTANTS from "app/constants/storage";
import { _isEmpty } from "app/utils/helpers";
import Storage from "app/utils/storage/local";
import racesSlice from "app/store/races/races.slice";
import { useGetRaces } from "app/utils/api/queries/races.query";
import { QUERY_KEYS } from "app/utils/api/queries/constants";
import { Typography } from "antd";

const UpcomingRaceContent = (): JSX.Element => <Races />;

const UpcomingRaces = (): ReactElement => {
    const UpcomingRacesWithMyRaces = WithMyRaces(UpcomingRaceContent);
    const queryClient = useQueryClient();

    const upcomingRacesState = racesSlice((state) => state.races);
    const updatePageNumber = racesSlice((state) => state.updatePageNumber);

    const { currentPage, location } = upcomingRacesState.upcoming;

    const { data: races } = useGetRaces({
        filter: "upcoming",
        page: currentPage,
    });

    const { count } = races ?? {};

    // If the user's location changes then re-fetch the races
    useEffect(() => {
        const userLocation = Storage.get(STORAGE_CONSTANTS.userLocation);
        const currentRaceLocation = location;

        if (!_isEmpty(currentRaceLocation)) {
            if (userLocation !== currentRaceLocation) {
                queryClient.invalidateQueries({
                    queryKey: [QUERY_KEYS.RACES, "upcoming", currentPage],
                });
            }
        }
    }, [currentPage, location, queryClient]);

    // reset page numbers to 1 when leaving the page
    useEffect(
        () => () => {
            updatePageNumber("upcoming", 1);
        },
        [updatePageNumber]
    );

    return (
        <UpcomingRacesWithMyRaces
            title="Upcoming races"
            subTitle={
                (<Typography.Text>A list of your upcoming races.</Typography.Text>) as ReactNode
            }
            count={
                count > 0 && (
                    <Typography.Text>
                        <strong>
                            <strong>{count}</strong> upcoming race
                            {count > 1 ? "s" : ""}
                        </strong>
                    </Typography.Text>
                )
            }
        />
    );
};

export default UpcomingRaces;

import { ReactNode, useState } from "react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { IUser } from "app/store/types/user.types";
import userSlice from "app/store/user/user.slice";
import User from "app/utils/user";
import modalsSlice from "app/store/modals/modals.slice";
import "./index.scss";

const ProTooltip = ({
    children,
    placement,
    customText = "This functionality is only available to Pro members.",
    condition,
    custom_cta,
}: {
    children: ReactNode;
    placement?: TooltipPlacement;
    customText?: string;
    condition?: boolean;
    custom_cta?: ReactNode;
}) => {
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const [open, setOpen] = useState(false);
    const skipTooltip = condition || UserModule.isPremiumUser();

    const { toggleMembersProgramModal } = modalsSlice((state) => state);

    const render_cta = () => {
        if (custom_cta) return custom_cta;
        if (!custom_cta && custom_cta !== null)
            return (
                <span
                    role="link"
                    aria-hidden="true"
                    className="link-inline"
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                        toggleMembersProgramModal();
                    }}
                >
                    Upgrade plan
                </span>
            );
        return null;
    };

    const cta = render_cta();

    return (
        <Tooltip
            title={
                skipTooltip ? undefined : (
                    <span>
                        {customText} {cta && cta}
                    </span>
                )
            }
            open={open}
            onOpenChange={setOpen}
            placement={placement}
        >
            {children}
        </Tooltip>
    );
};

export default ProTooltip;

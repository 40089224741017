import { IUser } from "app/store/types/user.types";
import userSlice from "app/store/user/user.slice";
import { useFreeTrials } from "app/utils/api/queries/user.query";
import User from "app/utils/user";

export const useCanAccessBB = () => {
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    const { data: bbFreeTrials } = useFreeTrials(
        {
            enabled: !UserModule.isPremiumUser(),
        },
        "budget_builder"
    );

    const freeTrial = bbFreeTrials?.length === 1 ? bbFreeTrials[0] : null;

    return Boolean(UserModule.isPremiumUser() || freeTrial?.active);
};

export const kFormatter = (val: string | number): string => {
    if (typeof val !== "string" && typeof val !== "number")
        throw new Error("Expected value should be either a string or a number");

    let num: number;
    if (typeof val === "string") num = parseFloat(val);
    else num = val;

    const absNum = Math.abs(num);

    // Helper function to remove unnecessary decimal places
    const removeTrailingZeros = (n: string): string => n.replace(/\.0+$/, "");

    // Format for millions
    if (absNum >= 1e6)
        return `${removeTrailingZeros((Math.sign(num) * (absNum / 1e6)).toFixed(1))}m`;

    // Format for thousands
    if (absNum >= 1e3)
        return `${removeTrailingZeros((Math.sign(num) * (absNum / 1e3)).toFixed(1))}k`;

    // For numbers less than 1000
    return `${removeTrailingZeros((Math.sign(num) * absNum).toFixed(1))}`;
};

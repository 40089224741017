export function groupBy<T>(arr: T[], property: keyof T) {
    return Object.values(
        arr.reduce((acc: Record<string, { label: string; options: T[] }>, obj: T) => {
            const key = String(obj[property]);
            if (!acc[key]) {
                acc[key] = {
                    label: key.replace(/\b\w/g, (match) => match.toUpperCase()),
                    options: [],
                };
            }
            acc[key].options.push(obj);
            return acc;
        }, {})
    );
}

import { useQuery } from "@tanstack/react-query";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import { SponsorType } from "app/types/sponsors/sponsor.types";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import { QUERY_KEYS } from "./constants";

const useSponsors = (
    pk: number | string,
    options?: UseQueryOptionsType
): UseQueryReturnType<SponsorType[] | undefined> => {
    const { data, isPending, isError, error } = useQuery<SponsorType[]>({
        queryKey: [QUERY_KEYS.SPONSORS, pk],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<SponsorType[]>(`${API_URL.SPONSORS}${pk}/sponsors/`, {
                signal,
            });
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

export { useSponsors };

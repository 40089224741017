import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { RaceMarketerFormType } from "app/lib/validation_schemas/marketer.schema";
import API_URL from "app/constants/api_urls";
import API from "../axios";
import { QUERY_KEYS } from "../queries/constants";

export const usePostOrganisationDetails = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<
        void,
        AxiosError<{ message: string }>,
        { pk: number; args: RaceMarketerFormType }
    >({
        mutationFn: async ({ pk, args }) => {
            const { data } = await API.patch(
                `${API_URL.MARKETER_LIST_RACE}${pk}/listing_report/submit/`,
                { org_details: args }
            );

            return data;
        },
        onSuccess: (data, { pk }) => {
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.RACES, "upcoming"],
            });

            queryClient.setQueryData([QUERY_KEYS.RACE_REPORT, pk], data);

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

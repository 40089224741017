import { Table } from "antd";
import { ReactNode } from "react";

// Number of all columns including invisible ones
type Index = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

type Items = {
    [key in Index]?: ReactNode | string;
};

// items is a key value pair where key is 1 to 9 and value is the value of the cell
const BudgetTableHeaderRow = ({
    items,
    withStyling = true,
    classNames,
}: {
    items: Items;
    withStyling?: boolean;
    classNames?: {
        [key in Index]?: string;
    };
}) => (
    <Table.Summary.Row>
        <Table.Summary.Cell className={classNames && classNames[0]} index={0}>
            {items[0]}
        </Table.Summary.Cell>
        <Table.Summary.Cell className={classNames && classNames[1]} index={1}>
            {items[1]}
        </Table.Summary.Cell>
        <Table.Summary.Cell className={classNames && classNames[2]} index={2}>
            {items[2]}
        </Table.Summary.Cell>
        <Table.Summary.Cell className={classNames && classNames[3]} index={3}>
            {items[3]}
        </Table.Summary.Cell>
        <Table.Summary.Cell className={classNames && classNames[4]} index={4}>
            {items[4]}
        </Table.Summary.Cell>
        <Table.Summary.Cell
            index={5}
            className={`
                ${classNames && classNames[5]} ${
                    withStyling
                        ? "budget-sheet__current-cell-wrap budget-sheet__border-left-cell"
                        : ""
                }
            `}
            colSpan={2}
        >
            {items[5]}
        </Table.Summary.Cell>
        <Table.Summary.Cell
            align="right"
            index={6}
            className={`${classNames && classNames[6]} ${
                withStyling ? "budget-sheet__current-cell-wrap" : ""
            }`}
        >
            {items[6]}
        </Table.Summary.Cell>
        <Table.Summary.Cell
            index={7}
            className={`${classNames && classNames[7]} ${
                withStyling ? "budget-sheet__proj-cell-wrap budget-sheet__border-right-cell" : ""
            }`}
            colSpan={2}
        >
            {items[7]}
        </Table.Summary.Cell>
    </Table.Summary.Row>
);

export default BudgetTableHeaderRow;

import { NavigateFunction } from "react-router-dom";
import { IUser } from "app/store/types/user.types";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import { CurrentRaceType } from "app/store/races/races.types";

export const listingReportActionNavigate = (
    pk: number,
    name: string,
    type: "upcoming" | "past" | undefined,
    navigate: NavigateFunction,
    url: string,
    user: Partial<IUser>,
    setCurrentRace: (currentRace: Partial<CurrentRaceType>) => void
) => {
    const currentRace = { pk, name, type };
    Storage.set(`${STORAGE_CONSTANTS.currentRace}.${user?.email}`, JSON.stringify(currentRace));
    setCurrentRace(currentRace);
    navigate(url);
};

import { MutableRefObject } from "react";
import { formatISO, parseISO } from "date-fns";
import { IUser } from "app/store/types/user.types";
import { RaceDetailsType } from "app/types/races/race.types";
import { _isEmpty } from "app/utils/helpers";

// TODO -> Add propert TS types
export const defaultRaceData = (
    race: any,
    raceMode: MutableRefObject<string>,
    user: IUser
): RaceDetailsType => {
    let raceLogo: object | undefined;
    let raceType:
        | { pk?: number; name?: string; discipline?: string; display_name?: string }
        | undefined;

    // This will be true in case of edit or import-add, for add 'race' is empty
    if (!_isEmpty(race)) {
        // Race Type should be in format {name:'urlString', discipline:'urlString'}
        // Logo should be in format {url:'urlString'} or {base64:'data:string'}
        if (raceMode.current === "add" || raceMode.current === "copy") {
            if (race.type) {
                const { name, discipline_name, discipline__name, discipline } = race.type;
                raceType = {
                    name,
                    discipline: discipline || discipline_name || discipline__name,
                };
            }

            if (raceMode.current === "copy") raceLogo = { url: race.logo || "" };
            else raceLogo = race?.logo;
        } else if (raceMode.current === "edit") {
            raceType = race?.type;
            raceLogo = { url: race.logo || "" };
        }
    }
    const { pk, username, email, location } = user;
    // On import mode there is no pk, so we generate random id for each event
    const events = race.events?.length
        ? race.events.map((ev: any, idx: number) => (ev.pk ? ev : { ...ev, id: idx }))
        : [
              {
                  // id is added only for array key purposes
                  id: 0,
                  name: "",
                  distance: "",
                  distance_units: "",
                  start_time: "",
                  entry_fee: "",
                  participants: "",
              },
          ];

    return {
        owner: { pk, username, email },
        country: location,
        pk: race.pk,
        name: race.name || "",
        description: race.description || "",
        address: race.address || "",
        city: race.city || "",
        // This id is generated as a substitute to event.pk
        events: events.map((ev: any) =>
            Object.fromEntries(Object.entries(ev).map(([key, value]) => [key, value]))
        ),
        logo: raceLogo,
        postal_code: race.postal_code || "",
        region: race.region,
        registration_page: race.registration_page || "",
        start_date: race?.start_date
            ? formatISO(parseISO(race.start_date), { representation: "date" })
            : "",
        timezone: race.timezone,
        type: raceType as any,
        venue: race.venue || "",
        website: race.website || "",
        reg_provider: race.reg_provider || "",
        reg_provider_page: race.reg_provider_page || "",
    };
};

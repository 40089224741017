import { Field, FormikProps } from "formik";
import { Button, Dropdown, Tooltip, Typography } from "antd";
import { DoubleArrowUp } from "rdhq-icons";
import { addDays, format, isAfter, isBefore, isSameDay, parseISO, subDays } from "date-fns";
import React, { useEffect } from "react";
import { BudgetEventInfoType } from "app/types/budget/budget.types";
import { FormikDatePicker, FormikNumber } from "app/components/elements/Formik";
import userSlice from "app/store/user/user.slice";
import MISC_CONSTANTS from "app/constants/misc";
import { BinOutlined, DatepickerOutlined, DotsOutlined, ThinPlusOutlined } from "assets";
import ProTooltip from "app/components/elements/wrappers/ProTooltip";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";

const ExtendedFormikDatePicker = (props: any) => (
    <FormikDatePicker
        {...props}
        value={props?.field?.value ? parseISO(props?.field?.value) : undefined}
    />
);

const EventNode = ({
    node,
    index,
    setFieldValue,
    remove,
    errors,
    insert,
    allNodes,
}: {
    node: BudgetEventInfoType["nodes"][0];
    index: number;
    setFieldValue: (fieldName: string, value: string | null | number) => void;
    remove: (index: number) => void;
    errors: any;
    insert: <X = any>(index: number, value: X) => void;
    allNodes: BudgetEventInfoType["nodes"];
}) => {
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    const canAccessBB = useCanAccessBB();

    const startDate = allNodes[index + 1] ? addDays(new Date(allNodes[index + 1].date), 1) : null;
    const defaultNextEndDate = format(subDays(new Date(allNodes[index].date), 1), "yyyy-MM-dd");

    const formattedStartDate = startDate ? format(startDate, MISC_CONSTANTS.dateFormat) : "";
    const formattedEndDate = format(new Date(node?.date), MISC_CONSTANTS.dateFormat);

    const isFirst = index === 0;
    const isLast = index === allNodes.length - 1;

    const nextDate = new Date(allNodes[index + 1]?.date);
    const prevDate = new Date(allNodes[index - 1]?.date);

    const disabledDate = (current: Date) => {
        if (current) {
            return isSameDay(current, prevDate) || isAfter(current, prevDate);
        }

        return true;
    };

    const StartDateElement = (
        <Tooltip
            title="The event start date generates automatically according to your end periods"
            getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
        >
            <span className="edit-event-modal__date-readonly">{formattedStartDate}</span>
        </Tooltip>
    );

    const EndDateElement = (
        <Tooltip
            title="This date is set to race date and cannot be changed."
            getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
        >
            <span className="edit-event-modal__date-readonly edit-event-modal__date-readonly--end-date">
                {formattedEndDate}
            </span>
        </Tooltip>
    );

    const SelectDateField = (
        <Field
            name={`nodes.${index}.date`}
            required
            setFieldValue={(key: string, value: string | null) => setFieldValue(key, value)}
            component={ExtendedFormikDatePicker}
            suffixIcon={null}
            disabledDate={disabledDate}
            // extendOnChange={(newDate: Date) => {
            //     if (nextDate) {
            //         if (isBefore(newDate, nextDate) || isSameDay(newDate, nextDate)) {
            //             setFieldValue(
            //                 `nodes.${index + 1}.date`,
            //                 format(subDays(newDate, 1), MISC_CONSTANTS.fieldDateFormat)
            //             );
            //         }
            //     }
            // }}
        />
    );

    const CalendarIcon = <DatepickerOutlined className="edit-event-modal__date-icon" />;

    const ChevronIcon = <DoubleArrowUp className="edit-event-modal__chevron-icon" />;

    const renderPeriodField = () => {
        // Is the only node -> Show up to last date (uneditable)
        // Up to + last date disabled
        if (isFirst && isLast) {
            return (
                <div className="edit-event-modal__date-field">
                    {CalendarIcon}
                    <span className="edit-event-modal__date-dimmed">Dates up to</span>
                    {EndDateElement}
                </div>
            );
        }

        // Is the first node but not the only one -> Show first date + last date (uneditable)
        // first date + last date disabled
        if (isFirst && !isLast) {
            return (
                <div className="edit-event-modal__date-field">
                    {CalendarIcon}
                    {StartDateElement}
                    {ChevronIcon}
                    {EndDateElement}
                </div>
            );
        }

        // Is the last node but not the only one -> Show up to last date (editable)
        // Up to + last date enabled
        if (!isFirst && isLast) {
            return (
                <div className="edit-event-modal__date-field">
                    {CalendarIcon}
                    <span className="edit-event-modal__date-dimmed edit-event-modal__date-dimmed--last ">
                        Dates up to
                    </span>

                    <span className="hidden">{ChevronIcon}</span>

                    {SelectDateField}
                </div>
            );
        }

        // Is any node but the first and last one -> show start date and last date (ediable)
        return (
            <div className="edit-event-modal__date-field">
                {CalendarIcon}
                {StartDateElement}
                {ChevronIcon}
                {SelectDateField}
            </div>
        );
    };

    const handleDeleteNode = () => {
        const newPrevNodeParticipantsProj =
            node.participants_proj + allNodes[index - 1].participants_proj;

        const newPrevNodeParticipantsCurr =
            node.participants_curr + allNodes[index - 1].participants_curr;

        remove(index);
        setFieldValue(`nodes.${index - 1}.participants_proj`, newPrevNodeParticipantsProj);
        setFieldValue(`nodes.${index - 1}.participants_curr`, newPrevNodeParticipantsCurr);
    };

    // Automatically update participants_proj to match participants_curr if past date
    useEffect(() => {
        if (isAfter(new Date(), new Date(node?.date))) {
            setFieldValue(`nodes.${index}.participants_proj`, node.participants_curr);
        }
    }, [node.participants_curr, node.date, setFieldValue, index]);

    // Automatically update every subsequent date to be at least one date before the previous date
    useEffect(() => {
        let i = 0;

        while (allNodes[i + 1]) {
            if (!isBefore(new Date(allNodes[i + 1].date), new Date(allNodes[i].date))) {
                setFieldValue(
                    `nodes.${i + 1}.date`,
                    format(subDays(new Date(allNodes[i].date), 1), MISC_CONSTANTS.fieldDateFormat)
                );
            }
            i += 1;
        }
    }, [allNodes, setFieldValue]);

    const checkRegParticipantsDisabled = () => {
        // startDate is in the past
        if (startDate && isAfter(new Date(), startDate)) {
            return false;
        }

        // no startDate (dates up to)
        if (!startDate) {
            return false;
        }

        // startDate in the past and endDate in the future
        if (isAfter(startDate, new Date()) && isBefore(new Date(node.date), new Date())) {
            return false;
        }

        // startDate in the future
        if (isBefore(startDate, new Date())) {
            return false;
        }

        return true;
    };

    const regParticipantsDisabled = checkRegParticipantsDisabled();

    return (
        <div className="edit-event-modal__node">
            <div className="edit-event-modal__period">
                {index === 0 && (
                    <Tooltip
                        title="The start and end date for a given registration period."
                        placement="topLeft"
                    >
                        <Typography.Text className="ant-label" strong>
                            <span className="tooltip-underline">Period</span>{" "}
                            <span className="ant-label-required">*</span>
                        </Typography.Text>
                    </Tooltip>
                )}

                <div className="edit-event-modal__date-field-wrapper">{renderPeriodField()}</div>
            </div>

            <Field
                label={index === 0 && "Entry fee"}
                name={`nodes.${index}.entry_fee`}
                prefix={user?.location?.ccy_symbol}
                className={`eventinputs-entry-fee eventinput edit-event-modal__number-field ${
                    errors?.nodes?.[index]?.hasOwnProperty("entry_fee") ? "hasError" : ""
                }`}
                tooltipText="The entry fee for a given registration period."
                tooltipPlacement="top"
                component={FormikNumber}
                required
                setFieldValue={setFieldValue}
            />

            <ProTooltip
                customText="Tracking of registered participants is only available to Pro members."
                custom_cta={null}
                condition={canAccessBB}
            >
                <div>
                    <Field
                        label={index === 0 && "Reg. participants"}
                        required
                        name={`nodes.${index}.participants_curr`}
                        component={FormikNumber}
                        min={0}
                        setFieldValue={setFieldValue}
                        disabled={regParticipantsDisabled || !canAccessBB}
                        tooltipText="The number of people currently registered in a given registration period."
                        tooltipPlacement="top"
                        className="edit-event-modal__number-field"
                    />
                </div>
            </ProTooltip>

            <Field
                label={index === 0 && "Proj. participants"}
                required
                name={`nodes.${index}.participants_proj`}
                component={FormikNumber}
                setFieldValue={setFieldValue}
                tooltipText="The number of people expected to have registered by the end of a given pricing period."
                tooltipPlacement="top"
                className="edit-event-modal__number-field"
            />

            {/* {!(isFirst && isLast) && (
                    <Popconfirm
                        title="Delete this node?"
                        onConfirm={handleDeleteNode}
                        okText="Delete"
                        cancelText="Cancel"
                        okButtonProps={{
                            danger: true,
                        }}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                        className={isFirst ? "hidden" : ""}
                    >
                        <Button disabled={index === 0} className="ant-custom-btn-icon" danger>
                            <BinOutlined />
                        </Button>
                    </Popconfirm>
                )} */}

            <Dropdown
                menu={{
                    items: [
                        {
                            key: "1",
                            label: (
                                <ProTooltip
                                    customText="Multiple pricing periods are only available to Pro members."
                                    custom_cta={null}
                                    condition={canAccessBB}
                                >
                                    <div className="edit-event-modal__dots-dropdown-item">
                                        <ThinPlusOutlined />
                                        Add period
                                    </div>
                                </ProTooltip>
                            ),
                            disabled: !canAccessBB,
                            onClick: () => {
                                if (canAccessBB) {
                                    insert(index + 1, {
                                        date: defaultNextEndDate,
                                        temp_pk: Math.random(),
                                        participants_curr: allNodes[index].participants_curr,
                                        participants_proj: allNodes[index].participants_proj,
                                        entry_fee: allNodes[index].entry_fee,
                                    });

                                    setFieldValue(`nodes.${index}.participants_curr`, 0);
                                    setFieldValue(`nodes.${index}.participants_proj`, 0);
                                }
                            },
                        },
                        {
                            key: "2",
                            label: (
                                <div className="edit-event-modal__dots-dropdown-item edit-event-modal__dots-dropdown-item--delete">
                                    <BinOutlined />
                                    Delete period
                                </div>
                            ),
                            className: isFirst ? "d-none" : "",
                            onClick: handleDeleteNode,
                        },
                    ],
                }}
                placement="bottomLeft"
                trigger={["click"]}
                autoAdjustOverflow={false}
                getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
                rootClassName="edit-event-modal__dots-dropdown"
            >
                <Button className="edit-event-modal__dots-btn" disabled={isFirst && !isLast}>
                    <DotsOutlined />
                </Button>
            </Dropdown>
        </div>
    );
};

export default EventNode;

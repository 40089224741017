import { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import userSlice from "app/store/user/user.slice";
import LoadingScreen from "app/components/elements/loading_screen";
import URL from "app/constants/route_urls";
import racesSlice from "app/store/races/races.slice";

function Logout(): ReactElement {
    const navigate = useNavigate();
    const { user, logoutUser } = userSlice((state) => state);
    const { flushRacesStore } = racesSlice((state) => state);
    const location = useLocation();
    const queryClient = useQueryClient();

    useEffect(() => {
        const logout = async (): Promise<void> => {
            const response = await logoutUser();
            if (response?.status === 200) {
                if (window && window.Intercom) window.Intercom("shutdown");
                flushRacesStore();
                queryClient.clear();
                navigate(URL.LOGIN, {
                    state: {
                        // helps when user is signing in to a different account
                        from: location?.state?.from,
                    },
                    replace: true,
                });
            }
        };

        logout();
    }, [logoutUser, location?.state?.from, navigate, user.email, flushRacesStore, queryClient]);

    return <LoadingScreen type="layout" />;
}

export default Logout;

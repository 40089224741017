import { BudgetItemsGroupType, BudgetItemType } from "app/types/budget/budget.types";

export const groupObjectsByCategory = (objects: BudgetItemType[]): BudgetItemsGroupType[] => {
    const groupedObjects: { [group: string]: BudgetItemType[] } = {};

    objects.forEach((obj) => {
        const group = obj?.category?.group || "";

        if (!groupedObjects[group]) {
            groupedObjects[group] = [];
        }

        groupedObjects[group].push(obj);
    });

    const result = Object.entries(groupedObjects).map(([group, items]) => ({
        name: group,
        items,
    }));

    return result;
};

export const getVarianceBgColor = (pctString: string) => {
    const val = parseFloat(pctString) / 100;

    // val is infinite
    if (Number.isNaN(val)) {
        return "rgb(107 183 107)";
    }

    // val equals 0
    if (val === 0) {
        return "#fff";
    }

    // val between 0% and 20%
    if (val >= 0 && val < 0.2) {
        return "#F0FAF0";
    }

    // val between 20% and 40%
    if (val >= 0.2 && val < 0.4) {
        return "#E0F5E0";
    }

    // val between 40% and 60%
    if (val >= 0.4 && val < 0.6) {
        return "#D1F0D1";
    }

    // val between 60% and 80%
    if (val >= 0.6 && val < 0.8) {
        return "#C2EBC2";
    }

    // val more than 80%
    if (val >= 0.8) {
        return "#B3E5B3";
    }

    // val between 0% and -20%
    if (val <= 0 && val > -0.2) {
        return "#FEEEEC";
    }

    // val between -20% and -40%
    if (val <= -0.2 && val > -0.4) {
        return "#FCDCD9";
    }

    // val between -40% and -60%
    if (val <= -0.4 && val > -0.6) {
        return "#FFC6C2";
    }

    // val between -60% and -80%
    if (val <= -0.6 && val > -0.8) {
        return "#FFB3AD";
    }

    // val more than -80%
    if (val <= -0.8) {
        return "#FFA099";
    }

    // val is 0
    return "#fff";
};

import * as yup from "yup";
import i18n from "i18next";

export interface IFormikMarketerForm {
    initialValues: {
        organisation: string;
        org_fname: string;
        org_lname: string;
        org_email: string;
        org_phone: string;
    };
    validationSchema: yup.SchemaOf<RaceMarketerFormType>;
    onSubmit: (arg: {
        organisation: string;
        org_fname: string;
        org_lname: string;
        org_email: string;
        org_phone: string;
    }) => void;
}

export type RaceMarketerFormType = {
    organisation: string;
    org_fname: string;
    org_lname: string;
    org_email: string;
    org_phone: string;
};

export const MarketerFormSchema = yup.object().shape({
    organisation: yup
        .string()
        .required(i18n.t("yup.required"))
        .min(2, i18n.t("yup.min", { num: "2" })),
    org_fname: yup
        .string()
        .required(i18n.t("yup.required"))
        .min(2, i18n.t("yup.min", { num: "2" })),
    org_lname: yup
        .string()
        .required(i18n.t("yup.required"))
        .min(2, i18n.t("yup.min", { num: "2" })),
    org_email: yup.string().required(i18n.t("yup.required")).email("Must be a valid email"),
    org_phone: yup
        .string()
        .required(i18n.t("yup.required"))
        .matches(/\d+/g, "Must contain numbers"),
});

import { useQuery } from "@tanstack/react-query";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import { UserFreeTrial } from "app/types/user/free_trials.type";
import { QUERY_KEYS } from "./constants";

const useCustomerPortal = (
    options?: UseQueryOptionsType
): UseQueryReturnType<{ url: string } | undefined> => {
    let apiUrl = API_URL.CUSTOMER_PORTAL;
    if (!import.meta.env.PROD) apiUrl += "?liveMode=false";
    const { data, isPending, isError, error } = useQuery<{ url: string }>({
        queryKey: [QUERY_KEYS.CUSTOMER_PORTAL],
        queryFn: async () => {
            const { data } = await API.get<{ url: string }>(apiUrl);
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

export const useFreeTrials = (
    options?: UseQueryOptionsType,
    app_slug?: string
): UseQueryReturnType<UserFreeTrial[] | undefined> => {
    const { data, isPending, isError, error, isFetching, isRefetching } = useQuery<UserFreeTrial[]>(
        {
            queryKey: [QUERY_KEYS.USER_FREE_TRIALS],
            queryFn: async ({ signal }) => {
                const { data } = await API.get<UserFreeTrial[]>(
                    `${API_URL.FREE_TRIALS}${app_slug ? `?app=${app_slug}` : ""}`,
                    {
                        signal,
                    }
                );
                return data;
            },
            throwOnError: true,
            ...options,
        }
    );

    return {
        data,
        isLoading: isFetching || isRefetching,
        isError,
        error,
    };
};

export { useCustomerPortal };

import { useQuery } from "@tanstack/react-query";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import { ListingsFormData, ListingType } from "app/types";
import { removeHtmlTagsAndEntities } from "app/utils/helpers";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";

const useVendorListingsFormData = (
    options?: UseQueryOptionsType
): UseQueryReturnType<ListingsFormData | undefined> => {
    const { isPending, isError, data, error } = useQuery({
        queryKey: ["vendor_listings_form_data"],
        queryFn: async () => {
            const { data } = await API.get<ListingsFormData>(API_URL.BUSINESS_LISTINGS_FORM_DATA);
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

const useVendorListings = (
    options?: UseQueryOptionsType
): UseQueryReturnType<ListingType[] | undefined> => {
    const { isPending, isError, data, error } = useQuery({
        queryKey: ["vendor_listings"],
        queryFn: async () => {
            const { data } = await API.get<ListingType[]>(API_URL.BUSINESS_LISTINGS);
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

const useVendorListingDetails = (
    id: string | number,
    options?: UseQueryOptionsType
): UseQueryReturnType<ListingType | any> => {
    const { isLoading, isError, data, error } = useQuery({
        queryKey: ["vendor_listing_details", id],
        queryFn: async () => {
            const { data } = await API.get(`${API_URL.BUSINESS_LISTINGS}${id}`);
            return {
                id: data.pk,
                title: data.title,
                description: removeHtmlTagsAndEntities(data?.description),
                thumbnail_image: data.thumbnail_image,
                contact_website: data.contact_website,
                contact_email: data.contact_email,
                contact_phone: data.contact_phone,
                categories: data.categories,
            };
        },

        ...options,
    });

    return {
        data,
        isLoading,
        isError,
        error,
    };
};

export { useVendorListings, useVendorListingDetails, useVendorListingsFormData };

export const imageValidator = (file: File): [boolean, string | undefined] => {
    const ALLOWED_FORMATS = ["image/png", "image/jpeg", "image/jpg"];
    const conversionFactor = 1024 * 1024;
    const imageSize = +(file.size / conversionFactor).toFixed(1);

    if (imageSize > 2) return [false, "Image file size should not exceed 2 mb"];
    try {
        if (!ALLOWED_FORMATS.includes(file.type))
            return [false, "Only .jpg, .jpeg, .png file types are accepted"];
    } catch (err) {
        return [false, "Something weng wrong while uploading your image. Please try again"];
    }
    return [true, undefined];
};

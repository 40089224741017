const userAgent = navigator.userAgent.toLowerCase();

const isChrome = userAgent.includes("chrome") && !userAgent.includes("edge");
const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
const isSafari = userAgent.includes("safari") && !userAgent.includes("chrome");
const isEdge = userAgent.includes("edge");
const isOpera = userAgent.includes("opr") || userAgent.includes("opera");
const isBrave = userAgent.includes("brave");

export const getEmailHelpLink = () => {
    if (isChrome || isEdge)
        return "https://support.google.com/mail/thread/58665583/service-handler-icon-not-showing?hl=en";

    if (isFirefox)
        return "https://support.mozilla.org/en-US/kb/change-program-used-open-email-links";

    if (isSafari) return "https://support.apple.com/en-us/102362";

    if (isOpera)
        return "https://forums.opera.com/topic/58774/mailto-link-on-a-webpage#:~:text=If%20you%20also%20want%20clicking,and%20choose%20%22set%20default%22.";

    if (isBrave) return "https://community.brave.com/t/how-can-i-change-the-default-webmail/508758";

    return "https://support.google.com/mail/thread/58665583/service-handler-icon-not-showing?hl=en";
};

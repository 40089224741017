import { ReactElement, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import URL from "app/constants/route_urls";

const OLD_DASHBOARD_ROUTES = [
    { key: "PROFILE", url: "/dashboard/profile/", redirectTo: URL.PROFILE },
    { key: "ACCOUNT", url: "/dashboard/account/", redirectTo: URL.ACCOUNT },
    { key: "UPCOMING_RACES", url: "/dashboard/races/", redirectTo: URL.UPCOMING_RACES },
    // { key: "RACE", url: "/dashboard/race/", redirectTo: URL.RACES },
    { key: "DOCUMENTS", url: "/dashboard/documents/", redirectTo: URL.DOCUMENTS },
    { key: "OFFERS", url: "/dashboard/offers/", redirectTo: URL.OFFERS },
    { key: "REPORTS", url: "/dashboard/listing-reports/", redirectTo: URL.REPORTS },
    { key: "LISTING_REPORT", url: "/dashboard/listing-report", redirectTo: URL.REPORTS },
    { key: "SPONSORS", url: "/dashboard/sponsor-finder/", redirectTo: URL.SPONSORS },
    { key: "MEMBERS_JOIN", url: "/dashboard/members/join/", redirectTo: URL.MEMBERS_JOIN },
    {
        key: "LISTING_CREDITS",
        url: "/dashboard/members/listing-credits/",
        redirectTo: URL.LISTING_CREDITS,
    },
    // { key: "QUOTES", url: "/dashboard/quotes" },
];

interface Route {
    key: string;
    url: string;
    redirectTo: string;
}

const matchRoute = (locationPathname: string, routes: Route[]): string => {
    const pathnameWords = locationPathname.split("/").filter((word) => word);

    const matchedRoute = routes
        .map((route) => {
            const routeWords = route.url.split("/").filter((word) => word);
            const matchLength = pathnameWords.reduce(
                (acc, pathnameWord) =>
                    routeWords.some((routeWord) => routeWord.startsWith(pathnameWord))
                        ? acc + 1
                        : acc,
                0
            );

            return {
                matchLength,
                redirectTo: route.redirectTo,
            };
        })
        .sort((a, b) => b.matchLength - a.matchLength)
        .find((route) => route.matchLength > 0);

    return matchedRoute ? matchedRoute.redirectTo : "";
};

const Home = (): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        let matchingRoute = matchRoute(currentPath, OLD_DASHBOARD_ROUTES);
        if (matchingRoute === URL.PROFILE) matchingRoute = URL.UPCOMING_RACES;
        navigate(matchingRoute);
    }, [location.pathname, navigate]);

    return <div />;
};

export default Home;

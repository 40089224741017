import { useRef, useEffect, ReactElement, useState } from "react";

const BlurredImage = ({
    children,
    blurPercentage = 3,
    condition,
}: {
    children: ReactElement<HTMLImageElement>;
    blurPercentage?: number;
    condition: boolean;
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [blurAmount, setBlurAmount] = useState(0);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const img = children.props.src ? new Image() : null;
        if (!img) return;

        img.src = children.props.src;

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            // Calculate blur amount based on image dimensions
            const maxDimension = Math.max(img.width, img.height);
            const calculatedBlurAmount = (maxDimension * blurPercentage) / 100;
            setBlurAmount(calculatedBlurAmount);

            // Draw the original image
            ctx.drawImage(img, 0, 0, img.width, img.height);

            // Apply blur effect
            ctx.filter = `blur(${calculatedBlurAmount}px)`;

            ctx.drawImage(canvas, 0, 0);

            // Reset the filter
            ctx.filter = "none";
        };
    }, [children, blurPercentage]);

    if (condition) return children;

    return <canvas ref={canvasRef} style={{ filter: `blur(${blurAmount}px)` }} />;
};

export default BlurredImage;

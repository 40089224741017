import { ReactElement, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { message, Button, Typography } from "antd";
import { DateUtils, _isEmpty } from "app/utils/helpers";
import { useCustomerPortal } from "app/utils/api/queries/user.query";
import { IUser } from "app/store/types/user.types";
import URL from "app/constants/route_urls";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import User from "app/utils/user";
import PageWithLoader from "app/hoc/page_with_loader";
import userSlice from "app/store/user/user.slice";
import "./index.scss";

const { Text, Title } = Typography;

// TODO -> Update the link for the Stripe PORTAL
// TODO -> Add error toast when something goes wrong
const Account = (): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    const { data, isError, isLoading } = useCustomerPortal();

    useEffect(() => {
        if (!_isEmpty(isError)) {
            message.error({
                content: "Something went wrong while getting your payment details.",
            });
        }
    }, [isError]);

    const renderPlanLink = (): JSX.Element | null =>
        data?.url ? (
            <a
                href={data?.url}
                className={`link account__payment-details-link ${isLoading ? "isDisabled" : ""} `}
            >
                View subscriptions & update payment details
            </a>
        ) : null;

    const renderBuyListingCreditsLink = (): JSX.Element | boolean => (
        <Button
            className="ant-btn-outline"
            type="default"
            size="middle"
            onClick={() => navigate(URL.LISTING_CREDITS)}
        >
            Buy listing credits
        </Button>
    );

    const renderPlanRenewalDate = (): JSX.Element | null => {
        if (!UserModule.isPlanUpgradable() && !_isEmpty(UserModule.renewalDate())) {
            return (
                <div className="account__plan-item">
                    <div className="account__plan-info">
                        <Typography.Title level={4}>Plan renewal date:</Typography.Title>
                        <Text>
                            {UserModule.renewalDate() &&
                                DateUtils.formatDateString(UserModule.renewalDate() as string)}
                        </Text>
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderUpgradeButton = (): JSX.Element | null => {
        if (!UserModule.isPremiumUser()) {
            return (
                <div className="account__plan-cta">
                    <Button
                        className="ant-btn-outline"
                        type="default"
                        size="middle"
                        onClick={() => {
                            navigate(URL.MEMBERS_JOIN);
                            Storage.set(
                                STORAGE_CONSTANTS.paymentRedirect,
                                location.pathname || URL.DASHBOARD
                            );
                        }}
                    >
                        Upgrade
                    </Button>
                </div>
            );
        }
        return null;
    };

    return (
        <PageWithLoader isLoading={isLoading}>
            <div className="account">
                <div className="account__wrap">
                    <div className="account__top">
                        <Title level={3}>Account</Title>
                        <Text>Manage your subscription and account settings.</Text>
                    </div>
                    <div className="account__plan">
                        <div className="account__plan-item">
                            <div className="account__plan-info">
                                <Typography.Title level={4}>Member plan:</Typography.Title>
                                <Text>{user?.plan?.display_name}</Text>
                            </div>
                            {renderUpgradeButton()}
                        </div>
                        {renderPlanRenewalDate()}
                    </div>
                    <div className="account__listing-credits">
                        <Title level={4}>Race calendar promo credits</Title>
                        <div className="account__listing-credits-item">
                            <div className="account__listing-credits-title">
                                <Text>Remaining member plan credits:</Text>
                            </div>
                            <div className="account__listing-credits-count">
                                <Text>
                                    {!_isEmpty(user?.listing_credits) &&
                                        user?.listing_credits?.subscription}
                                </Text>
                            </div>
                        </div>
                        <div className="account__listing-credits-item">
                            <div className="account__listing-credits-title">
                                <Text>Additional unused credits:</Text>
                            </div>
                            <div className="account__listing-credits-count">
                                <Text>
                                    {!_isEmpty(user?.listing_credits) &&
                                        user?.listing_credits?.addon}
                                </Text>
                            </div>
                        </div>
                        <div className="account__listing-credits-item">
                            <div className="account__listing-credits-title">
                                <Text>Your total available promo credits:</Text>
                            </div>
                            <div className="account__listing-credits-count">
                                <Text>
                                    {!_isEmpty(user?.listing_credits) &&
                                        user?.listing_credits?.total}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <div className="account__footer">
                        <div className="account__footer-item">{renderBuyListingCreditsLink()}</div>
                        <div className="account__footer-item">{renderPlanLink()}</div>
                    </div>
                </div>
            </div>
        </PageWithLoader>
    );
};

export default Account;

import { RaceDetailsEventType, RaceDetailsType } from "app/types/races/race.types";
import { detectChangedNodes } from "./detect_changed_nodes";

export const detectChangedRaceData = ({
    originalData,
    newData,
}: {
    originalData: RaceDetailsType;
    newData: RaceDetailsType;
}) => {
    const updatedData = Object.keys(newData).reduce((acc, key) => {
        if (key === "events") {
            acc[key] = detectChangedNodes<Partial<RaceDetailsEventType>>({
                oldNodes: originalData[key],
                newNodes: newData[key],
            });
        }

        // add any changed value other than the custom handled events
        if (newData[key] !== originalData[key]) {
            if (key !== "events") {
                acc[key] = newData[key];
            }
        }

        // always include the race pk
        if (key === "pk") {
            acc[key] = newData[key];
        }

        return acc;
    }, {} as Partial<RaceDetailsType>);

    if (Reflect.has(updatedData, "redirect")) Reflect.deleteProperty(updatedData, "redirect");
    return updatedData;
};

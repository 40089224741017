import { ReactNode } from "react";
import { Typography } from "antd";
import LoadingScreen from "app/components/elements/loading_screen";
import "./index.scss";

type LoaderProps = {
    text?: string;
};

const PageWithLoader = ({
    isLoading,
    children,
    text,
    error,
    errorText,
}: {
    isLoading: boolean;
    children: ReactNode;
    text?: string;
    error?: boolean | string;
    errorText?: string;
}): JSX.Element => {
    const loaderProps: LoaderProps = {};
    const defaultErrorText = "Something went wrong. Please try again.";
    if (text) loaderProps.text = text;

    // eslint-disable-next-line react/jsx-props-no-spreading
    if (isLoading) return <LoadingScreen type="page" {...loaderProps} />;
    if (error) return <Typography.Text>{errorText || defaultErrorText}</Typography.Text>;

    return <div className="page-with-loader">{children}</div>;
};

export default PageWithLoader;

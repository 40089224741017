import { FieldInputProps, FormikState } from "formik";
import { TimePicker, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { TooltipPlacement } from "antd/es/tooltip";
import { fieldError } from "app/utils/helpers/formik";
import "./index.scss";

export default ({
    label,
    required,
    field,
    form,
    className,
    setFieldValue,
    tooltipText,
    tooltipPlacement = "right",
}: {
    label: string;
    required: boolean;
    field: FieldInputProps<string>;
    form: FormikState<string>;
    className?: string;
    setFieldValue: (fieldName: string, value: string | null) => void;
    tooltipText?: string;
    tooltipPlacement?: TooltipPlacement;
}) => {
    const error = fieldError(form, field);

    const format = ["HH:mm", "HH", "h:mm"];

    return (
        <div className={`${className} ${error && !form.isSubmitting ? "hasError" : ""}`}>
            {label && (
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <Typography.Text className="ant-label" strong>
                        <span className={`${tooltipText ? "tooltip-underline" : ""}`}>{label}</span>{" "}
                        {required ? <span className="ant-label-required">*</span> : null}
                    </Typography.Text>
                </Tooltip>
            )}

            <TimePicker
                value={field.value ? dayjs(field.value, format) : null}
                onChange={(time, timeString) => {
                    if (typeof timeString === "string") {
                        setFieldValue(field.name, timeString);
                    }
                }}
                format={format}
                suffixIcon={null}
                showNow={false}
                placement="topLeft"
                needConfirm={false}
                placeholder="00:00"
                popupClassName="d-none"
            />
            {error && !form.isSubmitting && (
                <Typography.Text className="ant-error-label">{error}</Typography.Text>
            )}
        </div>
    );
};

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { RaceReportType } from "app/store/races/races.types";
import API_URL from "app/constants/api_urls";
import { UseQueryOptionsType, UseQueryReturnType } from ".";
import { QUERY_KEYS } from "./constants";
import API from "../axios";

export const useGetRaceReport = (
    props: {
        pk: number | string;
    },
    options?: UseQueryOptionsType
): UseQueryReturnType<Omit<RaceReportType, "events"> | undefined> => {
    const { pk } = props;

    const { data, isError, error, refetch, isFetching, isRefetching } = useQuery<
        Omit<RaceReportType, "events">
    >({
        queryKey: [QUERY_KEYS.RACE_REPORT, pk],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<Omit<RaceReportType, "events">>(
                `${API_URL.MARKETER_REPORT}${pk}/listing_report/`,
                {
                    signal,
                }
            );

            return data;
        },
        retry(failureCount, error) {
            if ((error as AxiosError).response?.status === 404) return false;
            return failureCount < 3;
        },
        ...options,
    });

    return {
        data,
        isLoading: isFetching || isRefetching,
        isError,
        error,
        refetch,
    };
};

export const useGetReportStatus = (
    props: {
        pk: number | string;
    },
    options?: UseQueryOptionsType
): UseQueryReturnType<Omit<RaceReportType, "events"> | undefined> => {
    const queryClient = useQueryClient();
    const { pk } = props;

    const { data, isPending, isError, error, isFetching } = useQuery<
        Omit<RaceReportType, "events">
    >({
        queryKey: [QUERY_KEYS.RACE_REPORT_STATUS, pk],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<Omit<RaceReportType, "events">>(
                `${API_URL.MARKETER_REPORT}${pk}/listing_report/exists/`,
                {
                    signal,
                }
            );

            queryClient.setQueryData([QUERY_KEYS.RACE_REPORT, pk], data);

            return data;
        },
        retry(failureCount, error) {
            if ((error as AxiosError).response?.status === 404) return false;
            return failureCount < 3;
        },
        ...options,
        refetchOnMount: true,
        staleTime: 0,
    });

    return {
        data,
        isLoading: isFetching,
        isError,
        error,
    };
};

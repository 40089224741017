import { FieldInputProps, FormikState } from "formik";
import { Input, Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { fieldError } from "app/utils/helpers/formik";

export default ({
    label,
    required,
    field,
    form,
    type = "text",
    maxLength,
    tooltipText,
    tooltipPlacement = "right",
    ...rest
}: {
    label: string;
    required: boolean;
    field: FieldInputProps<string>;
    form: FormikState<string>;
    className?: string;
    type: "text" | "number";
    tooltipText?: string;
    tooltipPlacement?: TooltipPlacement;
    maxLength?: number;
}) => {
    const error = fieldError(form, field);

    return (
        <div>
            {label && (
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <Typography.Text className="ant-label" strong>
                        <span className={`${tooltipText ? "tooltip-underline" : ""}`}>{label}</span>{" "}
                        {required ? <span className="ant-label-required">*</span> : null}
                    </Typography.Text>
                </Tooltip>
            )}
            <Input
                id={field.name}
                status={`${error ? "error" : ""}`}
                type={type}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                data-hj-allow
                maxLength={maxLength}
                {...rest}
            />
            {error && !form.isSubmitting && (
                <Typography.Text className="ant-error-label">{error}</Typography.Text>
            )}
        </div>
    );
};

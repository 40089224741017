import { useState, useEffect } from "react";
import "./index.scss";

const AnimatedPending = () => {
    const [animationIndex, setAnimationIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 500);
        return () => clearInterval(interval);
    }, []);

    return (
        <span className="animated-pending">
            Pending
            <span className={`animated-pending__dot ${animationIndex > 0 ? "isVisible" : ""}`}>
                .
            </span>
            <span className={`animated-pending__dot ${animationIndex > 1 ? "isVisible" : ""}`}>
                .
            </span>
            <span className={`animated-pending__dot ${animationIndex > 2 ? "isVisible" : ""}`}>
                .
            </span>
        </span>
    );
};

export default AnimatedPending;

import { ReactElement } from "react";
import { Select, Col, Row } from "antd";
import { useSearchParams } from "react-router-dom";

export const PARAM_TYPE = "type";
export enum QuoteType {
    ACTIVE = "active",
    EXPIRED = "expired",
    ALL = "all",
}
const QuoteRequestsFilters = (): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const type = searchParams.get(PARAM_TYPE) || QuoteType.ALL;

    const onTypeChange = (type: string): void => {
        if (!type || type === QuoteType.ALL) {
            searchParams.delete(PARAM_TYPE);
            setSearchParams(searchParams);
            return;
        }
        setSearchParams({ type });
    };

    return (
        <div className="vendor-quote-requests__filters">
            <Row gutter={16}>
                <Col
                    xxl={{ span: 3 }}
                    lg={{ span: 6 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                >
                    <Select
                        style={{ width: "100%" }}
                        value={type}
                        onChange={onTypeChange}
                        options={[
                            {
                                id: 1,
                                value: QuoteType.ALL,
                                label: "All requests",
                            },
                            {
                                id: 2,
                                value: QuoteType.ACTIVE,
                                label: "Active requests",
                            },
                            {
                                id: 3,
                                value: QuoteType.EXPIRED,
                                label: "Expired requests",
                            },
                        ]}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default QuoteRequestsFilters;

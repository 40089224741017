import { useCallback } from "react";
import STORAGE_CONSTANTS from "app/constants/storage";
import { RaceDetailsType } from "app/types/races/race.types";
import userSlice from "app/store/user/user.slice";
import Storage from "app/utils/storage/local";
import racesSlice from "app/store/races/races.slice";

export const useRacesWidget = () => {
    const { user } = userSlice((state) => state);
    const { setCurrentRace } = racesSlice((state) => state);

    const handleSelectRaceString = useCallback(
        (value: string): void => {
            Storage.set(`${STORAGE_CONSTANTS.currentRace}.${user?.email}`, value);
            setCurrentRace(JSON.parse(value));
        },
        [setCurrentRace, user?.email]
    );

    const stringifyRaceObject = useCallback((raceObj: Partial<RaceDetailsType>) => {
        const { pk, name, display_group } = raceObj;
        return JSON.stringify({ pk, name, type: display_group });
    }, []);

    return {
        handleSelectRaceString,
        stringifyRaceObject,
    };
};

import { AxiosServiceError } from "../api/axios/service_error";

export function tryCatch<T, Args extends any[]>(
    fn: (...args: Args) => Promise<T>
): (...args: Args) => Promise<[AxiosServiceError | null, T | undefined]> {
    return async (...args: Args): Promise<[AxiosServiceError | null, T | undefined]> => {
        try {
            const result: T = await fn(...args);
            return [null, result];
        } catch (error) {
            // Check if the error is already an AxiosServiceError
            if (error instanceof AxiosServiceError) {
                return [error, undefined];
            }

            // If it's not, wrap it in a new AxiosServiceError
            const axiosError = new AxiosServiceError(error);
            return [axiosError, undefined];
        }
    };
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import translationEN_GB from "locales/en-GB/translation.json";
import translationEN_US from "locales/en-US/translation.json";
import translationEN_AU from "locales/en-AU/translation.json";
import translationEN_CA from "locales/en-CA/translation.json";

const resources = {
    "en-US": {
        translation: translationEN_US,
    },
    "en-GB": {
        translation: translationEN_GB,
    },
    "en-AU": {
        translation: translationEN_AU,
    },
    "en-CA": {
        translation: translationEN_CA,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: Storage.get(STORAGE_CONSTANTS.userLocation) || "en-US",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

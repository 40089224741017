import {
    UpcomingRacesOutlined,
    PastRacesOutlined,
    VendorOffersOutlined,
    DocsOutlined,
    CalendarWizardOutlined,
    SponsorFinderOutlined,
    RaceDirectorHubOutlined,
    BudgetBuilderOutlined,
    QuoteRequestsOutlined,
    BadgesOutlined,
    ListingsOutlined,
} from "assets";
import URL from "app/constants/route_urls";
import { SideNavMenuItems } from "./types";

export const DASHBOARD_SIDE_MENU: SideNavMenuItems = [
    {
        id: 1,
        title: "",
        type: "races",
        children: [
            {
                id: 1 - 1,
                title: "Upcoming Races",
                img: () => <UpcomingRacesOutlined />,
                imgAlt: "upcoming_races",
                type: "races",
                href: URL.UPCOMING_RACES,
                label: "Upcoming Races",
                current: true,
                key: "upcoming_races",
                route: "/races/upcoming/",
            },
            {
                id: 1 - 2,
                title: "Past Races",
                img: () => <PastRacesOutlined />,
                imgAlt: "past_races",
                type: "races",
                href: URL.PAST_RACES,
                label: "Past Races",
                current: true,
                key: "past_races",
                route: "/races/past/",
            },
        ],
    },
    {
        id: 2,
        title: "Plan",
        type: "offers",
        children: [
            {
                id: 2 - 5,
                title: "Budget Builder",
                img: () => <BudgetBuilderOutlined />,
                imgAlt: "rdhq_budget_builder",
                type: "budget_builder",
                href: URL.BUDGET_BUILDER,
                label: "Budget Builder",
                current: true,
                key: "budget_builder",
                route: "/budget-builder/",
                isNew: true,
            },
            {
                id: 2 - 1,
                title: "Vendor Offers",
                img: () => <VendorOffersOutlined />,
                imgAlt: "rdhq_offers",
                type: "offers",
                href: URL.OFFERS,
                label: "Vendor Offers",
                current: true,
                key: "vendor_offers",
                route: "/offers/",
            },
            {
                id: 2 - 4,
                title: "Documents & Templates",
                img: () => <DocsOutlined />,
                imgAlt: "rdhq_documents_templates",
                type: "documents",
                href: URL.DOCUMENTS,
                label: "Documents & Templates",
                current: true,
                key: "docs_and_templates",
                route: "/documents/",
            },
        ],
    },
    {
        id: 3,
        title: "Promote",
        type: "cal_submit",
        children: [
            {
                id: 3 - 1,
                title: "Race Calendar Wizard",
                img: () => <CalendarWizardOutlined />,
                imgAlt: "rdhq_calendar-wizard",
                type: "cal_submit",
                href: URL.REPORTS,
                label: "Race Calendar Wizard",
                current: true,
                key: "race_calendar_wizard",
                route: "/race-calendar-wizard/",
            },
        ],
    },
    {
        id: 4,
        title: "Grow",
        type: "sponsor_finder",
        children: [
            {
                id: 4 - 1,
                title: "Sponsor Finder",
                img: () => <SponsorFinderOutlined />,
                imgAlt: "rdhq_sponsor_finder",
                type: "sponsor_finder",
                href: URL.SPONSORS,
                label: "Sponsor Finder",
                current: true,
                key: "sponsor_finder",
                route: "/sponsor-finder/",
            },
        ],
    },
    {
        id: 5,
        title: "Connect",
        type: "external",
        children: [
            {
                id: 5 - 1,
                title: "Race Directors Hub",
                img: () => <RaceDirectorHubOutlined />,
                imgAlt: "race_directors_hub",
                type: "#",
                href: "https://www.facebook.com/groups/racedirectorshq/",
                label: "Race directors hub",
                current: true,
                external: true,
                key: "race_directors_hub",
                route: "#",
            },
        ],
    },
];

export const BUSINESS_SIDE_MENU: SideNavMenuItems = [
    {
        id: 1,
        title: "",
        type: "business",
        children: [
            {
                id: 1 - 1,
                title: "Listings",
                img: () => <ListingsOutlined />,
                imgAlt: "business_listings",
                type: "business_listings",
                href: URL.BUSINESS_LISTINGS,
                label: "Listings",
                current: true,
                key: "business_listings",
                route: URL.BUSINESS_LISTINGS,
                count: false,
            },
        ],
    },
    {
        id: 3,
        title: "Grow",
        type: "quote_requests",
        children: [
            {
                id: 3 - 1,
                title: "Quote Requests",
                img: () => <QuoteRequestsOutlined />,
                imgAlt: "quote_requests",
                type: "quote_requests",
                href: URL.BUSINESS_QUOTES,
                label: "Quote Requests",
                current: true,
                key: "quote_requests",
                route: URL.BUSINESS_QUOTES,
                count: true,
            },
        ],
    },
    {
        id: 2,
        title: "Promote",
        type: "badges",
        children: [
            {
                id: 2 - 1,
                title: "Badges",
                img: () => <BadgesOutlined />,
                imgAlt: "badges",
                type: "badges",
                href: URL.BUSINESS_BADGES,
                label: "Badges",
                current: true,
                key: "badges",
                route: URL.BUSINESS_BADGES,
                count: false,
            },
        ],
    },
];

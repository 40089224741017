import { ReactElement, useEffect, useRef } from "react";
import { Button, Tooltip, Skeleton, Select, Empty } from "antd";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { ChevronDownOutlined } from "rdhq-icons";
import { PenOutlined, PlusOutlined } from "assets";
import { _isEmpty } from "app/utils/helpers";
import { kFormatter } from "app/utils/helpers/kFormatter";
import URL from "app/constants/route_urls";
import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";
import { useRacesWidget } from "app/hooks/useRacesWidget";
import API from "app/utils/api/axios";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import API_URL from "app/constants/api_urls";
import "./index.scss";
import racesSlice from "app/store/races/races.slice";
import { useGetWidgetRaces } from "app/utils/api/queries/races.query";
import { useGetRaceReport } from "app/utils/api/queries/race_marketer.query";
import { AxiosError } from "axios";

/**
 * TODO Conduct better logic with the race Select Widget when the user changes location
 * TODO If current race is empty remove local storage
 */

const RaceSelect = (): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data: widgetRaces, isError, isLoading } = useGetWidgetRaces({ display: "menu" });
    const { currentRace } = racesSlice();
    const runOnceRef = useRef<boolean>(false);
    const { user } = userSlice((state) => state);
    const queryParams = new URLSearchParams(location.search);
    const raceFromParam = queryParams.get("race");
    const raceFromLocalStorage = Storage.get(`${STORAGE_CONSTANTS.currentRace}.${user?.email}`);
    const [searchParams, setSearchParams] = useSearchParams();
    const { toggleImportRaceModal } = modalsSlice((state) => state);

    const { isLoading: reportIsLoading } = useGetRaceReport(
        { pk: currentRace.pk! },
        {
            enabled: false,
        }
    );

    const { handleSelectRaceString, stringifyRaceObject } = useRacesWidget();

    // Make sure there's a race selected at all times
    useEffect(() => {
        if (!_isEmpty(currentRace)) return;

        const { pk: pkFromStorage } = JSON.parse(raceFromLocalStorage || "{}");
        const isRaceFromStorage = !_isEmpty(JSON.parse(raceFromLocalStorage || "{}"));
        const upcomingWidgetRaces = widgetRaces?.filter((race) => race.groupLabel === "upcoming");
        const pastWidgetRaces = widgetRaces?.filter((race) => race.groupLabel === "past");

        if (raceFromParam) {
            API.get(`${API_URL.RACE_DETAILS}${raceFromParam}/`)
                .then((response) => {
                    handleSelectRaceString(stringifyRaceObject(response.data));
                    searchParams.delete("race");
                    setSearchParams(searchParams);
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 404) {
                        navigate("/404");
                    }
                });
            return;
        }

        // make sure pk exists in localstorage and is a valid pk
        if (
            !runOnceRef.current &&
            pkFromStorage &&
            typeof pkFromStorage === "number" &&
            isRaceFromStorage
        ) {
            API.get(`${API_URL.RACE_DETAILS}${pkFromStorage}/`)
                .then((response) => {
                    if (response) {
                        handleSelectRaceString(stringifyRaceObject(response.data));
                    }
                })
                .catch(() => {
                    Storage.remove(`${STORAGE_CONSTANTS.currentRace}.${user?.email}`);
                });

            return;
        }

        if (!!upcomingWidgetRaces && upcomingWidgetRaces?.length > 0) {
            handleSelectRaceString(stringifyRaceObject(JSON.parse(upcomingWidgetRaces[0].value!)));
            return;
        }

        if (!!pastWidgetRaces && pastWidgetRaces?.length > 0) {
            handleSelectRaceString(
                stringifyRaceObject(JSON.parse(pastWidgetRaces[pastWidgetRaces.length - 1].value!))
            );
        }
    }, [
        handleSelectRaceString,
        raceFromParam,
        widgetRaces,
        searchParams,
        setSearchParams,
        stringifyRaceObject,
        user?.email,
        raceFromLocalStorage,
        currentRace,
    ]);

    const editRaceHandler = (): void => {
        Storage.set(STORAGE_CONSTANTS.editRaceRedirect, location?.pathname || URL.RACES);
        navigate(`${URL.RACES}${currentRace.pk}/edit/`);
    };

    const isRaceReport = location.pathname.includes(URL.REPORTS);

    const options = widgetRaces?.map((race) => {
        const value = JSON.parse(race.value || "{}");
        const newValue = {
            pk: value.pk,
            name: value.name,
            type: value.display_group || value.type,
        };

        return {
            label: race.name,
            groupLabel: race.groupLabel,
            value: JSON.stringify(newValue),
        };
    });

    const upcomingOptions = options?.filter((o) => o.groupLabel === "upcoming");
    const pastOptions = options?.filter((o) => o.groupLabel === "past");

    // Render Select content
    const renderRaceSelectContent = (): JSX.Element => {
        if (isLoading)
            return <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active loading />;

        return (
            <>
                <div className="top-navbar__race-select-item navbar-select__item">
                    <Select
                        style={{ width: "100%" }}
                        status={isError ? "error" : ""}
                        value={currentRace?.name}
                        popupClassName="navbar-select__selector"
                        disabled={_isEmpty(widgetRaces) || (isRaceReport && reportIsLoading)}
                        options={[
                            {
                                label: (
                                    <div className="navbar-select__group-label">
                                        Upcoming{" "}
                                        <span className="navbar-select__group-count">
                                            {kFormatter(upcomingOptions?.length as number)}
                                        </span>
                                    </div>
                                ),
                                title: "upcoming",
                                options: upcomingOptions,
                            },
                            {
                                label: (
                                    <div className="navbar-select__group-label">
                                        Past{" "}
                                        <span className="navbar-select__group-count">
                                            {kFormatter(pastOptions?.length as number)}
                                        </span>
                                    </div>
                                ),
                                title: "past",
                                options: pastOptions,
                            },
                        ]}
                        showSearch
                        onChange={(value: string) => handleSelectRaceString(value)}
                        labelInValue={false}
                        notFoundContent={
                            <Empty
                                image={Empty.PRESENTED_IMAGE_DEFAULT}
                                description="No races found"
                            />
                        }
                        suffixIcon={_isEmpty(currentRace.name) ? null : <ChevronDownOutlined />}
                    />
                </div>
                <div className="top-navbar__race-select-item">
                    {!_isEmpty(currentRace) && (
                        <Tooltip title="Edit race" placement="bottom">
                            <Button
                                className="ant-custom-btn-icon-outline"
                                onClick={editRaceHandler}
                            >
                                <PenOutlined color="#090B0B" />
                            </Button>
                        </Tooltip>
                    )}
                    <Tooltip title="Add race" placement="bottom">
                        <Button
                            className="ant-custom-btn-icon-solid-secondary"
                            onClick={toggleImportRaceModal}
                        >
                            <PlusOutlined fontSize="1.1rem" />
                        </Button>
                    </Tooltip>
                </div>
            </>
        );
    };

    return (
        <>
            {/* {contextHolder} */}
            <div className={`top-navbar__race-select-wrap ${isLoading ? "isLoading" : ""}`}>
                {renderRaceSelectContent()}
            </div>
        </>
    );
};

export default RaceSelect;

import { useState } from "react";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";

const useClaimOffer = (
    pk: number
): {
    isLoading: boolean;
    success: string;
    error: string;
    handler: (props: {
        content: string;
        author_phone: string;
        race: { [key: string]: number };
        source: "dashboard_offers";
    }) => Promise<boolean>;
    clearClaimOffer: () => void;
} => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const claimOffer = async (claimOfferObj: {
        content: string;
        author_phone: string;
        race: { [key: string]: number } | null;
    }): Promise<boolean> => {
        try {
            setIsLoading(true);
            setSuccess("");
            setError("");

            if (!claimOfferObj?.race?.pk) Reflect.deleteProperty(claimOfferObj, "race");
            await API.post(`${API_URL.CLAIM_VENDOR_OFFER}${pk}/claim/`, claimOfferObj);

            setIsLoading(false);
            setSuccess("Your message has been sent");
            return true;
        } catch (error) {
            if (error?.response.status >= 400 && error.response.status < 500) {
                setError(error.response.data.author_phone);
                setIsLoading(false);
                return false;
            }
            setIsLoading(false);
            setError("Something went wrong. Please try again.");
            return false;
        }
    };

    return {
        isLoading,
        success,
        error,
        handler: (props: {
            content: string;
            author_phone: string;
            race: { [key: string]: number };
        }) => claimOffer(props),
        clearClaimOffer: () => {
            setError("");
            setSuccess("");
            setIsLoading(false);
        },
    };
};

export default useClaimOffer;

import { useState, useEffect, ReactElement, useCallback } from "react";
import { VendorOffersFilled } from "rdhq-icons";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Button, Typography } from "antd";
import { ProfileOutlined, PhoneOutlined, EmailOutlined } from "assets";
import API from "app/utils/api/axios";
import { _isEmpty, DateUtils } from "app/utils/helpers";
import PageWithLoader from "app/hoc/page_with_loader";
import API_URL from "app/constants/api_urls";
import userSlice from "app/store/user/user.slice";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import URL from "app/constants/route_urls";
import { QuoteType } from "./index.types";
import "./index.scss";

const { Title } = Typography;

/**
 * TODO Make better error handlings
 * TODO Improve the stylings
 * TODO check memory leak
 */

const Quotes = (): ReactElement | null => {
    const params = useParams();
    const { isLoading } = userSlice((state) => state);
    const navigate = useNavigate();

    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    const [loading, setLoading] = useState<boolean>(true);
    const [quote, setQuote] = useState<Partial<QuoteType>>({});
    const [error, setError] = useState<number>();
    const [emailError, setEmailError] = useState(false);

    const userAgent = navigator.userAgent.toLowerCase();

    const isChrome = userAgent.includes("chrome") && !userAgent.includes("edge");
    const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
    const isSafari = userAgent.includes("safari") && !userAgent.includes("chrome");
    const isEdge = userAgent.includes("edge");
    const isOpera = userAgent.includes("opr") || userAgent.includes("opera");
    const isBrave = userAgent.includes("brave");

    const getEmailHelpLink = () => {
        if (isChrome || isEdge)
            return "https://support.google.com/mail/thread/58665583/service-handler-icon-not-showing?hl=en";

        if (isFirefox)
            return "https://support.mozilla.org/en-US/kb/change-program-used-open-email-links";

        if (isSafari) return "https://support.apple.com/en-us/102362";

        if (isOpera)
            return "https://forums.opera.com/topic/58774/mailto-link-on-a-webpage#:~:text=If%20you%20also%20want%20clicking,and%20choose%20%22set%20default%22.";

        if (isBrave)
            return "https://community.brave.com/t/how-can-i-change-the-default-webmail/508758";

        return "https://support.google.com/mail/thread/58665583/service-handler-icon-not-showing?hl=en";
    };

    const getQuoteWithCallback = useCallback(
        async ({ signal }: { signal: AbortSignal }) => {
            try {
                setLoading(true);
                const { data } = await API.get(`${API_URL.QUOTES}${params.pk}/`, { signal });
                setQuote(data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err.response?.status || 500);
                if (err.response?.status === 404) {
                    navigate("/404/");
                }
            }
        },
        [params, navigate]
    );

    useEffect(() => {
        const abortController = new AbortController();
        getQuoteWithCallback(abortController);
        return () => {
            abortController.abort();
        };
    }, [getQuoteWithCallback]);

    if (error! >= 500)
        return <Typography.Text>Something went wrong. Please try again later.</Typography.Text>;

    const createEmailBody = () => {
        const userName = quote?.request?.author_name;
        const userEmail = quote?.request?.author_email;
        const receivedDate = DateUtils.formatDateString(quote?.request?.date_created as string);
        const content = quote?.request?.content;

        // Split the content into lines and add ">" at the beginning of each line
        const contentWithQuotes = content!
            .split("\n")
            .map((line) => `> ${line}`)
            .join("\n");

        const emailBody = `> From: ${userName} (${userEmail})\n> Received on: ${receivedDate}\n>\n${contentWithQuotes}`;

        return `mailto:${quote?.request?.author_email}?subject=${encodeURIComponent(
            `Re: Quote request for ${quote?.request?.category_short_description}`
        )}&body=${encodeURIComponent(`\n\n${emailBody}`)}`;
    };

    const handleReply = () => {
        const emailBody = createEmailBody();

        // Set a flag to track whether the link was clicked
        let linkClicked = false;

        // Open the email link
        window.location.href = emailBody;

        // Set a timeout for checking if the browser responded
        const timeoutDuration = 2000;
        const timeoutId = setTimeout(() => {
            if (!linkClicked) {
                setEmailError(true);
            }
        }, timeoutDuration);

        // Event listener to detect when the link is clicked
        window.addEventListener("beforeunload", () => {
            linkClicked = true;
            clearTimeout(timeoutId); // Clear the timeout since the browser has responded
        });
    };

    // TODO: remove restrictions when business dashboard is fully released
    if (
        UserModule.isStaff ||
        import.meta.url.includes("dash-staging") ||
        import.meta.env.MODE === "development"
    ) {
        return <Navigate to={`${URL.BUSINESS_QUOTES}${params.pk}`} />;
    }

    return (
        <PageWithLoader isLoading={loading || isLoading || _isEmpty(quote)}>
            <div className="quote">
                <div className="quote__wrap">
                    <div className="quote__content-wrap">
                        <div className="quote__header">
                            <Title level={1}>Quote Request</Title>
                        </div>

                        <div className="quote__info">
                            <Typography.Title level={4} className="quote__info-title">
                                To: {quote?.listing?.title}
                            </Typography.Title>

                            <Typography.Title level={5} className="quote__date-submitted">
                                Received on{" "}
                                {DateUtils.formatDateString(quote?.date_received as string)}
                            </Typography.Title>
                        </div>

                        <div className="quote__meta">
                            <div className="quote__meta-card">
                                <div className="quote__meta-card-info">
                                    <Typography.Text className="quote__meta-card-title">
                                        From:
                                    </Typography.Text>
                                    <Typography.Text className="quote__meta-card-value">
                                        {quote?.request?.author_name}
                                    </Typography.Text>
                                </div>

                                <ProfileOutlined className="quote__meta-card-icon" />
                            </div>
                            <div className="quote__meta-card">
                                <div className="quote__meta-card-info">
                                    <Typography.Text className="quote__meta-card-title">
                                        Email:
                                    </Typography.Text>
                                    <span
                                        // eslint-disable-next-line no-return-assign
                                        onClick={() => (window.location.href = createEmailBody())}
                                        role="link"
                                        tabIndex={0}
                                        className="quote__meta-card-value pointer"
                                    >
                                        {quote?.request?.author_email}
                                    </span>
                                </div>

                                <EmailOutlined className="quote__meta-card-icon" />
                            </div>
                            <div className="quote__meta-card">
                                <div className="quote__meta-card-info">
                                    <Typography.Text className="quote__meta-card-title">
                                        Phone number:
                                    </Typography.Text>
                                    <a
                                        href={`tel:${quote?.request?.author_phone}`}
                                        className="link-unstyled quote__meta-card-value"
                                    >
                                        {quote?.request?.author_phone}
                                    </a>
                                </div>

                                <PhoneOutlined className="quote__meta-card-icon" />
                            </div>
                        </div>

                        <div className="quote__content">
                            <Typography.Text className="quote__content-title">
                                Message:
                            </Typography.Text>
                            <Typography.Text>{quote?.request?.content}</Typography.Text>
                        </div>

                        {!_isEmpty(quote?.request?.offer) && (
                            <Typography.Text className="quote__offer">
                                <VendorOffersFilled className="quote__offer-icon" />
                                This quote request was made in relation to the offer:{" "}
                                {quote?.request?.offer?.title}
                                <Button type="link" className="quote__offer-cta">
                                    <a
                                        className="link-unstyled"
                                        href={`${quote?.request?.offer?.listing_url}#offers` || ""}
                                    >
                                        View offer
                                    </a>
                                </Button>
                            </Typography.Text>
                        )}

                        {!_isEmpty(quote?.request?.race) && (
                            <div className="quote__race">
                                <Typography.Text className="quote__race-info">
                                    The following race details were attached to this request:
                                </Typography.Text>

                                <ul className="quote__race-list">
                                    <li>
                                        Race name: <span>{quote?.request?.race.name}</span>
                                    </li>
                                    <li>
                                        Race date:{" "}
                                        <span>
                                            {DateUtils.formatDateString(
                                                quote?.request?.race?.start_date as string
                                            )}
                                        </span>
                                    </li>
                                    <li>
                                        Race location:{" "}
                                        <span>{quote?.request?.race.full_address}</span>
                                    </li>
                                    <li>
                                        Race website:{" "}
                                        <a className="link" href={quote?.request?.race?.website}>
                                            {quote?.request?.race.website}
                                        </a>
                                    </li>
                                    <li>
                                        Number of participants:{" "}
                                        <span>{quote?.request?.race.num_participants}</span>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <div className="quote__reply-wrap">
                            <Button
                                type="primary"
                                className="responsive-cta quote__reply"
                                // eslint-disable-next-line no-return-assign
                                onClick={handleReply}
                            >
                                <span>Reply</span>
                            </Button>

                            {emailError && (
                                <div className="quote__email-error">
                                    <Typography.Text>
                                        Button not working? Check out{" "}
                                        <a
                                            href={getEmailHelpLink()}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="link"
                                        >
                                            these instructions
                                        </a>{" "}
                                        to fix it.
                                    </Typography.Text>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </PageWithLoader>
    );
};

export default Quotes;

import { ReactNode, ReactElement, useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button, Checkbox, Modal, Tabs, Typography, message } from "antd";
import { differenceInCalendarDays, differenceInDays } from "date-fns";
import { CheckOutlined } from "rdhq-icons";
import { useGetBudget } from "app/utils/api/queries/budget.query";
import EventInfoTable from "app/components/modules/budget/event_info_table";
import PageWithLoader from "app/hoc/page_with_loader";
import BudgetSheet from "app/components/modules/budget/budget_sheet";
import { _isEmpty } from "app/utils/helpers";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import "./index.scss";
import racesSlice from "app/store/races/races.slice";
import { useGetWidgetRaces } from "app/utils/api/queries/races.query";
import VarianceSheet from "app/components/modules/budget/variance_sheet";
import { useFreeTrials } from "app/utils/api/queries/user.query";
import API from "app/utils/api/axios";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import { useStartFreeTrial } from "app/utils/api/mutations/user.mutation";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import { ExclamationOutlined, StartTrialOutlined } from "assets";
import URL from "app/constants/route_urls";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";

const BudgetHeader = () => (
    <div className="budget__header page__header">
        <Typography.Title level={1}>Budget Builder</Typography.Title>

        <div className="page__header-desc">
            <Typography.Text>
                Plan your race budget and manage your financial projections.
            </Typography.Text>
        </div>
    </div>
);

const BudgetBuilder = (): ReactElement | null => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const isPremiumUser = UserModule.isPremiumUser();
    const { data: widgetRaces, isLoading: isWidgetRacesLoading } = useGetWidgetRaces({
        display: "menu",
    });
    const { currentRace } = racesSlice((state) => state);
    const { toggleImportRaceModal, modals, toggleBudgetBuilderTrialModal } = modalsSlice(
        (state) => state
    );
    const racePk = currentRace.pk!;
    const {
        data,
        isLoading: isBudgetLoading,
        isError,
        error,
    } = useGetBudget(racePk, {
        enabled: !!currentRace.pk,
        refetchOnMount: true,
    });
    const dontAskAgainFromStorage = JSON.parse(
        Storage.get(`${user.pk}.${currentRace.pk}.${STORAGE_CONSTANTS.bbTrialDontAskAgain}`) ||
            "false"
    );

    const bbTrialEndedFromStorage = JSON.parse(
        Storage.get(`${user.pk}.${STORAGE_CONSTANTS.bbTrialEnded}`) || "false"
    );

    const [isExpiredModalVisible, setIsExpiredModalVisible] = useState(false);

    const {
        data: bbFreeTrials,
        isLoading: isFreeTrialsLoading,
        isError: isFreeTrialError,
    } = useFreeTrials(
        {
            enabled: !isPremiumUser,
        },
        "budget_builder"
    );

    const { mutate: startFreeTrial, isPending: isStartingFreeTrial } = useStartFreeTrial({
        onSuccess: () => {
            toggleBudgetBuilderTrialModal();
            message.success("Your 14-day free trial of Budget Builder has started");
        },

        onError: () => {
            message.error("Something went wrong.");
        },
    });

    const freeTrial = bbFreeTrials?.length === 1 ? bbFreeTrials[0] : null;

    const isLoading = isBudgetLoading || isWidgetRacesLoading || isFreeTrialsLoading;

    const tabItems = [
        { key: "1", label: "Budget", children: <BudgetSheet data={data} /> },
        { key: "2", label: "Variance", children: <VarianceSheet data={data} /> },
    ];

    const { toggleMembersProgramModal } = modalsSlice((state) => state);

    // Conditions to allow free trial feature
    const isFreeTrialAllowed = !isLoading && !isPremiumUser;

    // Conditions to allow showing start trial modal
    const shouldShowStartTrialModal =
        isFreeTrialAllowed && !freeTrial?.start_date && !dontAskAgainFromStorage;

    // Conditions to allow showing trial expired modal
    const shouldShowExpiredTrialModal =
        isFreeTrialAllowed &&
        freeTrial?.start_date &&
        !freeTrial.active &&
        !bbTrialEndedFromStorage;

    // Call on event free trial modal is acknowledged
    const handleStartTrialModalShown = () =>
        Storage.set(
            `${user.pk}.${currentRace.pk}.${STORAGE_CONSTANTS.bbTrialDontAskAgain}`,
            JSON.stringify(true)
        );

    // Call on event expired trial modal is acknowledged
    const handleExpiredTrialModalShown = () =>
        Storage.set(`${user.pk}.${STORAGE_CONSTANTS.bbTrialEnded}`, JSON.stringify(true));

    // Show start trial modal for basic users
    // useEffect(() => {
    //     if (shouldShowStartTrialModal) {
    //         toggleBudgetBuilderTrialModal();
    //     }
    // }, [shouldShowStartTrialModal, toggleBudgetBuilderTrialModal]);

    // Show trial expired modal if trial is expired
    useEffect(() => {
        if (shouldShowExpiredTrialModal) {
            setIsExpiredModalVisible(true);
        }
    }, [shouldShowExpiredTrialModal, setIsExpiredModalVisible]);

    if (
        !isLoading &&
        (isError || (_isEmpty(currentRace) && !!widgetRaces && widgetRaces.length > 0))
    )
        return (
            <>
                <BudgetHeader />
                <div className="budget__error">
                    {error?.response?.status === 404 ? (
                        <Typography.Text>No budget available for this race.</Typography.Text>
                    ) : (
                        <Typography.Text>Something went wrong. Please try again.</Typography.Text>
                    )}
                </div>
            </>
        );

    if (!!widgetRaces && widgetRaces.length === 0 && !isWidgetRacesLoading) {
        return (
            <>
                <BudgetHeader />
                <div className="budget__error">
                    <Typography.Text>
                        To use this tool, please{" "}
                        <span onClick={toggleImportRaceModal} role="presentation" className="link">
                            add a race{" "}
                        </span>
                        first.
                    </Typography.Text>
                </div>
            </>
        );
    }

    const calcTrialEndsWithin = () => {
        if (freeTrial) {
            return differenceInCalendarDays(new Date(freeTrial?.end_date), new Date());
        }

        return 0;
    };

    return (
        <PageWithLoader isLoading={isLoading}>
            <div className="budget">
                <BudgetHeader />

                {!isPremiumUser && !isFreeTrialsLoading && !isFreeTrialError && (
                    <div className="page__toolbar budget__toolbar">
                        <div />

                        {freeTrial && !_isEmpty(freeTrial) && (
                            <Typography.Text className="budget__header-trial-info">
                                {freeTrial.active && (
                                    <>
                                        <ExclamationOutlined />
                                        Your trial ends in {calcTrialEndsWithin()} day
                                        {calcTrialEndsWithin() > 1 ? "s" : ""}
                                    </>
                                )}
                            </Typography.Text>
                        )}
                    </div>
                )}
                <div className="budget__event-info-table">
                    <EventInfoTable />
                </div>
                <Tabs defaultActiveKey="1" items={tabItems} />
            </div>

            <Modal
                open={modals.budgetBuilderTrialModal?.isVisible || false}
                title="Get the most out of your budget"
                width={480}
                className="budget__modal"
                centered
                destroyOnClose
                closable={false}
                footer={
                    <div className="budget__modal-actions">
                        <Button
                            className="responsive-cta budget__modal-cta"
                            type="primary"
                            size="middle"
                            onClick={() => startFreeTrial({ app: "budget_builder" })}
                            loading={isStartingFreeTrial}
                            disabled={false}
                        >
                            Start free trial
                        </Button>

                        <Button
                            className="responsive-cta ant-btn-primary-outline budget__modal-cta"
                            type="default"
                            size="middle"
                            onClick={(): void => {
                                handleStartTrialModalShown();
                                toggleBudgetBuilderTrialModal();
                            }}
                        >
                            Continue with basic functionality
                        </Button>

                        <Typography.Text className="budget__modal-info">
                            14-day trial period. No credit card required.
                        </Typography.Text>
                    </div>
                }
            >
                <Typography.Text>
                    Try the Pro version of Budget Builder absolutely free for 14 days.
                </Typography.Text>
                <Typography.Text>All the basic features of Budget Builder plus:</Typography.Text>

                <ul className="global-modal-content__list">
                    <li className="global-modal-content__list-item">
                        <span>
                            <CheckOutlined />
                        </span>
                        Get on top of your profit margins, breakeven numbers and other key financial
                        metrics.
                    </li>
                    <li className="global-modal-content__list-item">
                        <span>
                            <CheckOutlined />
                        </span>
                        Track your budget progress daily based on your current participant count.
                    </li>
                    <li className="global-modal-content__list-item">
                        <span>
                            <CheckOutlined />
                        </span>
                        See where you are overspending with budget variance analysis.
                    </li>
                </ul>
            </Modal>

            <Modal
                open={isExpiredModalVisible}
                title="Your free trial has ended"
                width={480}
                className="budget__modal"
                centered
                afterOpenChange={handleExpiredTrialModalShown}
                destroyOnClose
                closable={false}
                footer={
                    <div className="budget__modal-actions">
                        <Button
                            className="responsive-cta budget__modal-cta"
                            type="primary"
                            size="middle"
                            onClick={() => {
                                navigate(URL.MEMBERS_JOIN);
                                toggleMembersProgramModal();
                                Storage.set(
                                    STORAGE_CONSTANTS.paymentRedirect,
                                    location.pathname || URL.DASHBOARD
                                );
                            }}
                            loading={isStartingFreeTrial}
                            disabled={false}
                        >
                            Upgrade to Pro
                        </Button>

                        <Button
                            className="responsive-cta ant-btn-primary-outline budget__modal-cta"
                            type="default"
                            size="middle"
                            onClick={(): void => {
                                setIsExpiredModalVisible(false);
                            }}
                        >
                            Continue with basic functionality
                        </Button>
                    </div>
                }
            >
                <Typography.Text>
                    To continue using Budget Builder's full functionality please upgrade to our{" "}
                    <a
                        href={PUBLIC_SITE_URL.JOIN}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                    >
                        Pro member plan
                    </a>
                    .
                </Typography.Text>
            </Modal>
        </PageWithLoader>
    );
};

export default BudgetBuilder;

import { useQuery } from "@tanstack/react-query";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import { VendorOfferType } from "app/types/vendors/vendors.types";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import { QUERY_KEYS } from "./constants";

export const useGetVendorOffers = (
    options?: UseQueryOptionsType
): UseQueryReturnType<VendorOfferType[] | undefined> => {
    const { data, isPending, isError, error } = useQuery({
        queryKey: [QUERY_KEYS.OFFERS],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<VendorOfferType[]>(API_URL.VENDOR_OFFERS, { signal });
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

import { ReactElement, ReactNode } from "react";
import { ConfigProvider } from "antd";
import { ErrorBoundary } from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { defaultTheme } from "app/lib/antd/default.theme";
import { AxiosInterceptor } from "app/utils/api/axios";
import Intercom from "app/components/elements/intercom/intercom";
import GTMInjector from "app/lib/scripts/inject_gtm/inject_gtm";
import HotjarInjector from "app/lib/scripts/hotjar/hotjar";
import ErrorPage from "app/views/misc/error_page";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 3,
            staleTime: 200_000,
        },
    },
});

const AppProvider = ({ children }: { children: ReactNode }): ReactElement => (
    <AxiosInterceptor>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_AUTH as string}>
            <QueryClientProvider client={queryClient}>
                <ConfigProvider theme={{ ...defaultTheme }}>
                    <ErrorBoundary fallback={<ErrorPage />}>
                        {!import.meta.env.PROD && <GTMInjector />}
                        {!import.meta.env.PROD && <Intercom />}
                        {!import.meta.env.PROD && <HotjarInjector />}
                        {children}
                    </ErrorBoundary>
                </ConfigProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </GoogleOAuthProvider>
    </AxiosInterceptor>
);
export default AppProvider;

import { Tooltip, Typography } from "antd";
import { FieldInputProps, FormikState } from "formik";
import { parseISO } from "date-fns";
import { TooltipPlacement } from "antd/es/tooltip";
import { fieldError } from "app/utils/helpers/formik";
import { _isEmpty, DateUtils } from "app/utils/helpers";
import userSlice from "app/store/user/user.slice";
import DatePicker from "app/components/elements/form/datepicker";
import "./index.scss";
import MISC_CONSTANTS from "app/constants/misc";

interface FormikProps {
    label: string;
    required: boolean;
    field: FieldInputProps<string>;
    form: FormikState<string>;
    className?: string;
    tooltipText?: string;
    tooltipPlacement?: TooltipPlacement;
    extendOnChange?: ((date: Date, dateString: string | string[]) => void) | undefined;
    setFieldValue: (fieldName: string, value: string | null) => void;
}

const FormikDatePicker = ({
    label,
    required,
    field,
    form,
    className,
    setFieldValue,
    tooltipText,
    tooltipPlacement = "right",
    extendOnChange,
    ...props
}: FormikProps) => {
    const error = fieldError(form, field);
    const { user } = userSlice((state) => state);

    const disabledDate = (current: Date): boolean => current && current.valueOf() < Date.now();

    return (
        <div className={className}>
            {label && (
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <Typography.Text className="ant-label" strong>
                        <span className={`${tooltipText ? "tooltip-underline" : ""}`}>{label}</span>{" "}
                        {required ? <span className="ant-label-required">*</span> : null}
                    </Typography.Text>
                </Tooltip>
            )}
            <DatePicker
                allowClear
                id={field.name}
                style={{ width: "100%" }}
                defaultValue={field?.value ? parseISO(field.value) : undefined}
                disabledDate={disabledDate}
                onChange={(_, dateString) => {
                    if (_isEmpty(dateString)) {
                        setFieldValue(field.name, null);
                        return;
                    }
                    if (typeof dateString === "string") {
                        setFieldValue(field.name, DateUtils.formatDateRegex(dateString));
                    }

                    if (extendOnChange) {
                        extendOnChange(_, dateString);
                    }
                }}
                onBlur={(e) => {
                    field.onBlur(e);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                format={
                    user?.location?.language === "en-us" ? MISC_CONSTANTS.dateFormat : "D MMMM YYYY"
                }
                status={error ? "error" : ""}
                getPopupContainer={(triggerNode) => triggerNode.offsetParent as HTMLElement}
                {...props}
            />
            {error && !form.isSubmitting && (
                <Typography.Text className="ant-error-label">{error}</Typography.Text>
            )}
        </div>
    );
};

export default FormikDatePicker;

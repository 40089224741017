/* eslint-disable default-param-last */
export const formatCurrency = (
    value: number | string | undefined,
    currency = "USD", // Default to 'USD' if no currency is provided
    digits = 2,
    options?: {
        minDigits?: number;
        maxDigits?: number;
    }
): string => {
    if (value === undefined || value === null || Number.isNaN(value)) return "$0.00";

    const parsedValue = typeof value === "string" ? parseFloat(value) : value;
    if (Number.isNaN(parsedValue)) return "$\u202F0.00"; // Handle non-numeric strings

    const formattedValue = parsedValue.toLocaleString("en-US", {
        style: "currency",
        currency,
        minimumFractionDigits: options?.minDigits || digits,
        maximumFractionDigits: options?.maxDigits || digits,
        currencyDisplay: "narrowSymbol",
    });

    // add a space after the $ sign
    return formattedValue.replace("$", "$\u202F");
};

import { ReactElement, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Button, Input, Typography, Tooltip, message, Upload, UploadFile, UploadProps } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { profileValidationSchema as validationSchema } from "app/lib/validation_schemas/profile.schema";
import { _isEmpty, imageValidator } from "app/utils/helpers";
import { Prompt } from "app/hooks";
import Select from "app/components/elements/form/select";
import PageWithLoader from "app/hoc/page_with_loader";
import userSlice, { UpdateUserType } from "app/store/user/user.slice";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import "./index.scss";
import { PlusOutlined } from "assets";
import { getBase64 } from "app/utils/helpers/getBase64";
import PUBLIC_SITE_URL from "app/constants/public_site_urls";

enum VerificationMessages {
    "success" = "Thank you! Your email address has been verified.",
    "error" = "Something went wrong. Please use the verification link we sent you and try again.",
}

const { Text, Title, Paragraph } = Typography;

export interface IFormikProfile {
    initialValues: {
        username: string;
        first_name: string;
        last_name: string;
    };
    validationSchema: yup.Schema<{ [key: string]: string }>;
    validateOnBlur: boolean;
    onSubmit: () => void;
}

function Profile(): ReactElement {
    const location = useLocation();
    const { updateUser, user, isLoading } = userSlice((state) => state);
    const [messageApi, contextHolder] = message.useMessage();
    const [searchParams, setSearchParams] = useSearchParams();

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const urlParams = new URLSearchParams(location.search);
    const isEmailVerified = urlParams.get("verified");
    const toastMessage =
        isEmailVerified === "true"
            ? VerificationMessages["success"]
            : VerificationMessages["error"];

    useEffect(() => {
        if (isEmailVerified !== null) {
            const emailVerified = isEmailVerified === "true" ? "success" : "error";

            messageApi[emailVerified](toastMessage);
            searchParams.delete("verified");
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Local state
    const [userIsUpdating, setUserIsUpdating] = useState<boolean>(false);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        dirty,
        setFieldError,
    } = useFormik({
        initialValues: {
            username: user?.username,
            first_name: user?.first_name,
            last_name: user?.last_name,
        },
        validationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: async ({
            username,
            first_name,
            last_name,
        }: {
            [key: string]: string;
        }): Promise<void> => {
            setUserIsUpdating(true);
            const prepareUser: Partial<UpdateUserType> = {};

            if (username) prepareUser.username = username;
            if (first_name) prepareUser.first_name = first_name;
            if (last_name) prepareUser.last_name = last_name;

            if (fileList.length > 0 && fileList[0].url && fileList[0].url !== user?.image) {
                prepareUser.image = { base64: fileList[0].url };
            }

            setUserIsUpdating(true);
            const [error] = await tryCatch(updateUser)(prepareUser);

            if (error) {
                if (!_isEmpty(error.fieldErrors)) {
                    Object.keys(error.fieldErrors).forEach((key) => {
                        if (
                            Array.isArray(error.fieldErrors[key]) &&
                            !_isEmpty(error.fieldErrors[key])
                        ) {
                            setFieldError(key, error.fieldErrors[key][0]);
                        }
                    });

                    setUserIsUpdating(false);
                    return;
                }

                setUserIsUpdating(false);
                messageApi.error(
                    "Something went wrong while updating your profile details. Please try again."
                );

                return;
            }

            setUserIsUpdating(false);
            messageApi.success("Your profile has been updated");
        },
    } as unknown as IFormikProfile);

    // Populate user image url if it already exists
    useEffect(() => {
        if (user?.image) {
            setFileList([{ url: user?.image, uid: "1", name: "" }]);
        }
    }, [user]);

    const handleAvatarChange: UploadProps["beforeUpload"] = async (file): Promise<boolean> => {
        const [isValid, errorMessage] = imageValidator(file);
        if (!isValid) {
            messageApi.error(errorMessage);
            return false;
        }

        const fileUrl = await getBase64(file);
        const prepareFile = {
            uid: file.uid,
            name: file.name,
            url: fileUrl,
        };
        setFileList([prepareFile]);

        return false;
    };

    return (
        <>
            {contextHolder}
            <Prompt
                when={dirty}
                message="Are you sure you want to leave this page? Any unsaved changes will be lost."
                beforeUnload
            />
            <PageWithLoader isLoading={_isEmpty(user) && isLoading}>
                <div className="profile">
                    <div className="profile__wrap">
                        <div className="profile__top">
                            <Title level={3}>Profile</Title>
                            <Paragraph>Edit your profile information.</Paragraph>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="profile__content">
                                <div className="profile__avatar-wrap">
                                    <div className="profile__avatar-wrap-item">
                                        <Title level={4}>Profile image</Title>
                                    </div>

                                    <div className="profile__avatar-wrap-item upload-dragger-wrap">
                                        <Upload
                                            accept="image/png, image/jpeg, image/jpg"
                                            maxCount={1}
                                            listType="picture-card"
                                            multiple={false}
                                            fileList={fileList}
                                            beforeUpload={handleAvatarChange}
                                            showUploadList={{
                                                showPreviewIcon: false,
                                                showRemoveIcon: false,
                                            }}
                                        >
                                            <button
                                                className="business-listing__upload-btn"
                                                type="button"
                                            >
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </button>
                                        </Upload>

                                        <Typography.Text>
                                            Max file size: 2mb | Accepted: .jpg, .jpeg, .png
                                        </Typography.Text>
                                    </div>
                                </div>
                                <div className="profile__inputs-wrap">
                                    <div className="profile__inputs-grp">
                                        <div className="profile__inputs-grp-item">
                                            <Text className="ant-label" strong>
                                                Username
                                            </Text>
                                            <Input
                                                type="text"
                                                name="username"
                                                value={values.username}
                                                status={!_isEmpty(errors.username) ? "error" : ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.username && touched?.username && (
                                                <span className="input-error">
                                                    {errors.username}
                                                </span>
                                            )}
                                        </div>
                                        <div className="profile__inputs-grp-item">
                                            <Text className="ant-label" strong>
                                                Email
                                            </Text>
                                            <Tooltip
                                                title={
                                                    <span>
                                                        To change this field, please{" "}
                                                        <a
                                                            href={PUBLIC_SITE_URL.CONTACT}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="link-inline"
                                                        >
                                                            contact us
                                                        </a>
                                                    </span>
                                                }
                                                placement="bottom"
                                            >
                                                <Input
                                                    type="email"
                                                    name="email"
                                                    value={user.email}
                                                    disabled
                                                    readOnly
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="profile__inputs-grp">
                                        <div className="profile__inputs-grp-item">
                                            <Text className="ant-label" strong>
                                                First name
                                            </Text>
                                            <Input
                                                type="text"
                                                name="first_name"
                                                status={!_isEmpty(errors.first_name) ? "error" : ""}
                                                value={values.first_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.first_name && touched.first_name && (
                                                <span className="input-error">
                                                    {errors.first_name}
                                                </span>
                                            )}
                                        </div>
                                        <div className="profile__inputs-grp-item">
                                            <Text className="ant-label" strong>
                                                Last name
                                            </Text>
                                            <Input
                                                type="text"
                                                name="last_name"
                                                status={!_isEmpty(errors.last_name) ? "error" : ""}
                                                value={values.last_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.last_name && touched?.last_name && (
                                                <span className="input-error">
                                                    {errors.last_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="profile__inputs-grp">
                                        <div className="profile__inputs-grp-item region">
                                            <Text className="ant-label" strong>
                                                Region
                                            </Text>
                                            <Tooltip
                                                title={
                                                    <span>
                                                        To change this field, please{" "}
                                                        <a
                                                            href={PUBLIC_SITE_URL.CONTACT}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="link-inline"
                                                        >
                                                            contact us
                                                        </a>
                                                    </span>
                                                }
                                                placement="bottom"
                                            >
                                                <span>
                                                    <Select
                                                        name="user_locations"
                                                        value={user?.location?.name || ""}
                                                        readonly
                                                    />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile__bottom">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={userIsUpdating}
                                    disabled={userIsUpdating}
                                    className="responsive-cta"
                                >
                                    Save changes
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </PageWithLoader>
        </>
    );
}

export default Profile;

import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import URL from "app/constants/route_urls";
import userSlice from "app/store/user/user.slice";

const Root = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { reAuthenticate } = userSlice((state) => state);

    useEffect(() => {
        const token: string | unknown = Storage.get(STORAGE_CONSTANTS.accessToken);
        if (token) reAuthenticate();
    }, [reAuthenticate]);

    useEffect(() => {
        if (location.pathname === "/") navigate(URL.LOGIN);
        if (location.pathname === URL.RACES || location.pathname === URL.RACES)
            navigate(URL.UPCOMING_RACES);
    }, [navigate, location.pathname]);

    return <Outlet />;
};

export default Root;

import { ReactElement, useState } from "react";
import { useFormik } from "formik";
import { message, Input, Typography, Button } from "antd";
import { blurred, inputError, errorValidationMessage } from "app/utils/validation";
import {
    MarketerFormSchema as validationSchema,
    IFormikMarketerForm,
} from "app/lib/validation_schemas/marketer.schema";
import { IUser } from "app/store/types/user.types";
import { useHasListingCredits } from "app/hooks/useHasListingCredits";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import User from "app/utils/user";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import "./index.scss";
import { EmailOutlined, PhoneOutlined } from "assets";
import { usePostOrganisationDetails } from "app/utils/api/mutations/race_marketer.mutation";

type FormTouchedFields = "organisation" | "org_fname" | "org_lname" | "org_email" | "org_phone";
type FormTouchedTypes = { [field in FormTouchedFields]: boolean };

const getStorageValues = (value: string): string => {
    const storageValues = Storage.get(STORAGE_CONSTANTS.postOrganizationValues);
    if (storageValues) {
        const parsedStorageValues = JSON.parse(storageValues);
        return parsedStorageValues[value] || "";
    }

    return "";
};

const DraftReportForm = ({
    pk,
    closeModal,
}: {
    pk: number;
    closeModal: () => void;
}): ReactElement => {
    const { user, getUser } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const { toggleMemberFeatureListReportModal } = modalsSlice((state) => state);
    const { mutateAsync: postOrganisationDetails, isPending } = usePostOrganisationDetails({
        onError: () => message.error("Something went wrong. Please try again."),
    });

    const [isTouched, setIsTouched] = useState<FormTouchedTypes>({
        organisation: false,
        org_fname: false,
        org_lname: false,
        org_email: false,
        org_phone: false,
    });

    const setTouchedStatus = (field: FormTouchedFields): void => {
        if (isTouched[field]) return;
        setIsTouched({
            ...isTouched,
            [field]: true,
        });
    };

    const userHasListingCredits = useHasListingCredits();

    const { handleSubmit, handleChange, handleBlur, values, errors, touched, resetForm } =
        useFormik({
            initialValues: {
                organisation: getStorageValues("organisation"),
                org_fname: getStorageValues("org_fname"),
                org_lname: getStorageValues("org_lname"),
                org_email: getStorageValues("org_email"),
                org_phone: getStorageValues("org_phone"),
            },
            validationSchema,
            onSubmit: async (args): Promise<void> => {
                Storage.set(STORAGE_CONSTANTS.postOrganizationValues, JSON.stringify(values));
                if (!userHasListingCredits) {
                    // Check if user has member status
                    if (!UserModule.isPremiumUser()) {
                        toggleMemberFeatureListReportModal();
                        return;
                    }
                }

                postOrganisationDetails({ pk, args }).then(() => {
                    window.scrollTo(0, 0);
                    Storage.set(STORAGE_CONSTANTS.postOrganizationValues, JSON.stringify(values));
                    resetForm();
                    getUser();
                    closeModal();
                    message.success("Your race has been queued for listing");
                });
            },
        } as IFormikMarketerForm);

    return (
        <div className="race-marketer__draft-report-form">
            <form onSubmit={handleSubmit} className="race-marketer__draft-report-form-wrap">
                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact first name</Typography.Text>
                    <Input
                        name="org_fname"
                        type="text"
                        value={values.org_fname || ""}
                        status={inputError("org_fname", errors, values, touched) ? "error" : ""}
                        autoComplete="off"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event);
                        }}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            blurred("org_fname", event, errors);
                            setTouchedStatus("org_fname");
                        }}
                        data-hj-allow
                    />
                    <span className="race-marketer__span-error">
                        {isTouched.org_fname &&
                            errorValidationMessage("org_fname", errors, values, touched, {})}
                    </span>
                </div>
                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact last name</Typography.Text>
                    <Input
                        name="org_lname"
                        type="text"
                        value={values.org_lname || ""}
                        status={inputError("org_lname", errors, values, touched) ? "error" : ""}
                        autoComplete="off"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event);
                        }}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            blurred("org_lname", event, errors);
                            setTouchedStatus("org_lname");
                        }}
                        data-hj-allow
                    />
                    <span className="race-marketer__span-error">
                        {isTouched.org_lname &&
                            errorValidationMessage("org_lname", errors, values, touched, {})}
                    </span>
                </div>
                <div className="race-marketer__draft-report-form-group full">
                    <Typography.Text className="ant-label">
                        Name of your organization
                    </Typography.Text>
                    <Input
                        name="organisation"
                        type="text"
                        value={values.organisation || ""}
                        status={inputError("organisation", errors, values, touched) ? "error" : ""}
                        autoComplete="off"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event);
                        }}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            blurred("organisation", event, errors);
                            setTouchedStatus("organisation");
                        }}
                        data-hj-allow
                    />
                    <span className="race-marketer__span-error">
                        {isTouched.organisation &&
                            errorValidationMessage("organisation", errors, values, touched, {})}
                    </span>
                </div>

                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact email</Typography.Text>
                    <Input
                        name="org_email"
                        type="email"
                        value={values.org_email || ""}
                        status={inputError("org_email", errors, values, touched) ? "error" : ""}
                        autoComplete="off"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event);
                        }}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            blurred("org_email", event, errors);
                            setTouchedStatus("org_email");
                        }}
                        prefix={<EmailOutlined />}
                        data-hj-allow
                    />
                    <span className="race-marketer__span-error">
                        {isTouched.org_email &&
                            errorValidationMessage("org_email", errors, values, touched, {})}
                    </span>
                </div>
                <div className="race-marketer__draft-report-form-group half">
                    <Typography.Text className="ant-label">Race contact phone</Typography.Text>
                    <Input
                        name="org_phone"
                        type="text"
                        value={values.org_phone || ""}
                        status={inputError("org_phone", errors, values, touched) ? "error" : ""}
                        autoComplete="off"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleChange(event);
                        }}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleBlur(event);
                            blurred("org_phone", event, errors);
                            setTouchedStatus("org_phone");
                        }}
                        data-hj-allow
                        prefix={<PhoneOutlined />}
                    />
                    <span className="race-marketer__span-error">
                        {isTouched.org_phone &&
                            errorValidationMessage("org_phone", errors, values, touched, {})}
                    </span>
                </div>
                <div className="race-marketer__draft-report-form-group full submit">
                    <Button type="primary" htmlType="submit" loading={isPending}>
                        List race
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default DraftReportForm;

import { ReactElement } from "react";
import { Typography } from "antd";
import modalsSlice from "app/store/modals/modals.slice";
import logo from "assets/logo_short.svg";
import "./index.scss";

function LoadingScreen({
    type = "layout",
    text,
}: {
    type: "layout" | "page" | "element";
    text?: string;
}): ReactElement {
    const { navbar } = modalsSlice((state) => state);
    const respectNavbar = type === "page" || type === "element";

    return (
        <div
            className={`loader-wrapper ${type} ${
                !navbar.isToggled && respectNavbar ? "isCollapsed" : ""
            }`}
        >
            <div className={`app-loading ${navbar.isToggled && respectNavbar ? "isToggled" : ""}`}>
                <img className="main-logo" src={logo} alt="" />
                <div className="logo-wrap" />
            </div>
            {text && (
                <div
                    className={`loader-text ${
                        navbar.isToggled && respectNavbar ? "isToggled" : ""
                    }`}
                >
                    <Typography.Text className="display-linebreak">{text}</Typography.Text>
                </div>
            )}
        </div>
    );
}

export default LoadingScreen;

import { object, mixed, string, number, boolean, array } from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";

const validationMessages = {
    required: "This field is required",
};

const postalCodeValidation = (
    location: string
): RequiredStringSchema<string | undefined, AnyObject> => {
    switch (location) {
        case "en-us":
            return string()
                .trim()
                .required(validationMessages.required)
                .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "This zip code is invalid");
        case "en-gb":
            return string()
                .trim()
                .required(validationMessages.required)
                .matches(
                    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i,
                    "This postcode is invalid"
                );
        case "en-ca":
            return string()
                .trim()
                .required(validationMessages.required)
                .matches(
                    /^(?:[ABCEGHJ-NPRSTVXY]\d[A-Z][ -]?\d[A-Z]\d)$/,
                    "This postal code is invalid"
                );
        case "en-au":
            return string()
                .trim()
                .required(validationMessages.required)
                .matches(
                    /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/,
                    "This postcode is invalid"
                );
        default:
            return string().trim().required(validationMessages.required);
    }
};

const timezoneValidation = (location: string) => {
    if (location !== "en-gb") {
        return object()
            .shape({
                pk: number(),
                code: string().required(validationMessages.required),
                display_name: string(),
            })
            .required(validationMessages.required);
    }

    return object().shape({
        pk: number(),
        code: string(),
        display_name: string(),
    });
};

const regionValidation = (location: "en-gb" | "en-us") => {
    if (location !== "en-gb") {
        return object()
            .shape({
                pk: number(),
                name: string().required(validationMessages.required),
            })
            .required(validationMessages.required);
    }

    return object()
        .shape({
            pk: number(),
            name: string().required(validationMessages.required),
        })
        .required(validationMessages.required);
};

export const raceValidationSchema = (location: string) =>
    object().shape({
        pk: number().optional(),
        owner: object()
            .shape({
                pk: number().required(validationMessages.required),
                username: string().required(validationMessages.required),
                email: string().email().required(validationMessages.required),
            })
            .required(validationMessages.required),
        name: string()
            .trim()
            .required(validationMessages.required)
            .test(
                "name",
                "Please don't include the race year in the race name",
                (val: string | undefined): boolean => {
                    const regex = /(\d{4})/;
                    if (val === undefined || val === null) return true;
                    if (!regex.test(val)) return true;
                    return false;
                }
            )
            .test(
                "annual",
                "Please don't include 'annual' in the race name",
                (val: string | undefined): boolean => {
                    if (val === undefined || val === null) return true;
                    if (!val.includes("annual")) return true;
                    return false;
                }
            )
            .test(
                "places",
                "Please don't include '1st', '2nd' etc in the race name",
                (val: string | undefined): boolean => {
                    const regex = /\d{1,2}(?:st|nd|rd|th)/gm;
                    if (val === undefined || val === null) return true;
                    if (!regex.test(val)) return true;
                    return false;
                }
            )
            .test(
                "presented by or sponsored by",
                "Please don't include sponsors in the race name",
                (val: string | undefined): boolean => {
                    const invalid: string[] = ["sponsored by", "presented by"];
                    if (val === undefined || val === null) return true;

                    const lowerVal = val.toLowerCase();
                    return !invalid.some((word: string) => lowerVal.includes(word.toLowerCase()));
                }
            ),
        type: object()
            .required(validationMessages.required)
            .shape({
                pk: number(),
                discipline: string(),
                name: string().required(validationMessages.required),
                display_name: string(),
            }),
        start_date: string().required(validationMessages.required),
        display_date: string(),
        weekday: string().optional(),
        start_time: string(),
        description: string()
            .trim()
            .required(validationMessages.required)
            .matches(/^[^\p{Extended_Pictographic}]+$/u, "Description cannot contain emojis"),
        events: array().of(
            object({
                pk: number().optional(),
                name: string().required(validationMessages.required),
                distance: string().required(validationMessages.required),
                distance_units: string().required(validationMessages.required),
                start_time: string().required(validationMessages.required),
                entry_fee: string().required(validationMessages.required),
                participants: string().required(validationMessages.required),
            })
        ), // RaceDetailsEventType[],
        venue: string().trim().optional().min(3, "Please provide a valid venue"),
        address: string().trim().required(validationMessages.required),
        city: string()
            .trim()
            .required(validationMessages.required)
            .matches(/^([^0-9]*)$/, "Please provide a valid city"),
        region: regionValidation(location as any),
        city_state: string(),
        country: object().shape({
            pk: number().required(validationMessages.required),
            name: string(),
            language: string(),
            ccy: string(),
            ccy_symbol: string(),
        }),
        postal_code: postalCodeValidation(location),
        full_address: string(),
        timezone: timezoneValidation(location),
        website: string().trim().required(validationMessages.required).url("Invalid website URL"),
        logo: mixed().required(validationMessages.required),
        registration_page: string()
            .trim()
            .required(validationMessages.required)
            .url("Invalid registration URL"),
        num_participants: number().optional(),
        listed: boolean(),
        display_group: string().oneOf(["past", "upcoming"]),
    });

export type NonFieldErrorType = string[];

export class AxiosServiceError extends Error {
    statusCode: number;

    nonFieldErrors: NonFieldErrorType;

    errorMessage: string;

    originalError: any;

    fieldErrors: any;

    constructor(error: any) {
        super();
        this.name = "AxiosServiceError";
        this.statusCode = error.response?.status || 500;
        this.errorMessage = error.message || "An unknown error occurred";
        this.originalError = error;
        this.nonFieldErrors = error.response?.data?.non_field_errors || [];
        this.fieldErrors = error.response?.data || {};

        if ((Error as any).captureStackTrace)
            (Error as any).captureStackTrace(this, AxiosServiceError);
    }
}

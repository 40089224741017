import { useMemo } from "react";
import { FieldInputProps, FormikState } from "formik";
import { Select, Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { ChevronDownOutlined } from "rdhq-icons";
import { fieldError } from "app/utils/helpers/formik";
import "./index.scss";

// TODO -> Some props here are redundant, try to remove them
export default ({
    label,
    required,
    options,
    field,
    form,
    description,
    onChange,
    tooltipText,
    tooltipPlacement = "right",
    searchable = false,
    iconVisible,
    initialValue,
    isDisabled = false,
    hidePlaceholder = false,
    ...rest
}: {
    label: string;
    required: boolean;
    options: any;
    field: FieldInputProps<any>;
    form: FormikState<string>;
    description?: string;
    searchable?: boolean;
    onChange?: (value?: any) => void;
    iconVisible?: boolean;
    tooltipText?: string;
    tooltipPlacement?: TooltipPlacement;
    initialValue?: string;
    isDisabled?: boolean;
    hidePlaceholder?: boolean;
}) => {
    const optionsInStandardFormat =
        Array.isArray(options) &&
        options.every(
            (o) =>
                Object.keys(o).includes("key") &&
                Object.keys(o).includes("label") &&
                Object.keys(o).includes("value")
        );

    const optionsInGroupFormat = Array.isArray(options) && options.every((o) => o.options);

    const allOptions = useMemo(() => {
        if (!options) return [];

        // If options already in the standard format (key, label, value)
        if (optionsInStandardFormat) {
            return options;
        }

        // This is for timezones, regions and types
        if (Array.isArray(options)) {
            if (optionsInGroupFormat) {
                return options.map((group) => ({
                    label: group.label,
                    options: group.options.map((o: any) => ({
                        k: o.pk,
                        label: o.display_name || o.name,
                        value: JSON.stringify(o),
                    })),
                }));
            }

            return options.map((o) => ({
                key: o.pk,
                label: o.display_name || o.name,
                value: JSON.stringify(o),
            }));
        }
        // This is for participants and distance_units
        return Object.keys(options || {}).map((key) => ({
            key: options[key],
            label: options[key],
            value: key,
        }));
    }, [options, optionsInStandardFormat, optionsInGroupFormat]);

    const error = fieldError(form, field);

    // We use additional function, because Chakra doesn't support objects as values
    const handleChange = (value: string): void => {
        let objValue;
        try {
            // eslint-disable-next-line quotes
            objValue = value.includes('"') ? JSON.parse(value) : value;
        } catch (e) {
            console.error("Error parsing", e);
        }

        field.onChange({ target: { name: field.name, value: objValue } });
    };

    const calculatePlaceholder = (): string => {
        let find;

        if (hidePlaceholder) return "";
        if (!field?.value || !options) return "";
        if (!Array.isArray(options) && options?.[field.value]) return options[field.value];

        const { code, name } = field.value;

        if (code) find = options.find((o: any) => o.code === code);
        else if (name) find = options.find((o: any) => o.name === name);

        return find?.display_name || find?.name || "Choose";
    };

    const renderSelectValue = (): string => {
        if (field.value && typeof field.value === "object") {
            if (Reflect.has(field.value, "display_name")) return field?.value?.display_name;
            if (Reflect.has(field.value, "name") && field.name === "type") {
                const prepareOptions = options
                    .reduce((acc: any, opt: any) => [...acc, opt.options], [])
                    .flat();
                const fieldType = prepareOptions.find(
                    (option: any) => option.name === field.value.name
                );
                return fieldType?.display_name || field.value.name;
            }

            if (Reflect.has(field.value, "name")) return field.value.name;

            if (Reflect.has(field.value, "code")) {
                if (field.name === "region") {
                    const val = options.find((o: any) => o.code === field.value.code);

                    return val.name;
                }
                if (field.name === "timezone") {
                    const val = options.find((o: any) => o.code === field.value.code);
                    return val.display_name;
                }
            }
        }

        if (field.value && typeof field.value === "string") {
            if (field.name.includes("distance_units")) return options[field.value];
            if (field.name.includes("participants")) return options[field.value];
            if (field.name.includes("quantity_type"))
                return options.find((o: any) => o.value === field.value).value;

            if (field.name.includes("rating")) return field.value.toString();
        }

        if (field.value && typeof field.value === "number") {
            if (field.name.includes("participants")) return options[field.value.toString()];
        }

        if (field.name.includes("event") && field.value === null) {
            return "All events";
        }

        if (field.name.includes("virtual")) {
            return options.find((o: any) => o.value === field.value)?.label || "";
        }

        return "";
    };

    return (
        <div>
            {label && (
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <Typography.Text className="ant-label" strong>
                        <span className={`${tooltipText ? "tooltip-underline" : ""}`}>{label}</span>{" "}
                        {required ? <span className="ant-label-required">*</span> : null}
                    </Typography.Text>
                </Tooltip>
            )}
            <Select
                suffixIcon={<ChevronDownOutlined />}
                data-hj-allow
                placeholder={calculatePlaceholder()}
                status={`${error ? "error" : ""}`}
                style={{ width: "100%", zIndex: 200 }}
                value={initialValue || renderSelectValue()}
                disabled={isDisabled}
                onBlur={(event) => {
                    field.onBlur(event);
                }}
                onChange={(value) => {
                    handleChange(value);

                    if (onChange) {
                        onChange(value);
                    }
                }}
                options={allOptions}
                showSearch={searchable}
                {...rest}
            />
            {error && !form.isSubmitting && (
                <Typography.Text className="ant-error-label">{error}</Typography.Text>
            )}
        </div>
    );
};

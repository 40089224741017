import { useLocation } from "react-router-dom";

export const useClearQueryParam = (queryParam: string) => {
    const location = useLocation();

    return () => {
        const query = new URLSearchParams(location?.search);
        query.delete(queryParam);
        const newUrl = `${window.location.origin}${window.location.pathname}${
            query.toString().length ? `?${query.toString()}` : ""
        }`;
        window.history.replaceState({}, document.title, newUrl);
    };
};

function isObject(object: unknown): object is Record<string, unknown> {
    return object != null && typeof object === "object";
}

export function deepEqual(
    object1: Record<string, unknown>,
    object2: Record<string, unknown>
): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) return false;

    return keys1.every((key) => {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        return (
            (areObjects &&
                deepEqual(val1 as Record<string, unknown>, val2 as Record<string, unknown>)) ||
            (!areObjects && val1 === val2)
        );
    });
}

/* eslint-disable react/no-danger */
import { ReactElement } from "react";
import { Modal, Typography } from "antd";
import DraftReportForm from "./draft_report_form";

const ListModal = ({
    isVisible,
    closeModal,
    pk,
}: {
    closeModal: () => void;
    isVisible: boolean;
    pk: number;
}): ReactElement => (
    <Modal
        title="List your race"
        open={isVisible}
        onCancel={closeModal}
        footer={null}
        centered
        destroyOnClose
    >
        <Typography.Text>To list your race, fill in the details below:</Typography.Text>
        <DraftReportForm pk={pk} closeModal={closeModal} />
        <Typography.Text className="race-marketer__privacy-note">
            Race contact details may be publicly displayed on some race calendars.
        </Typography.Text>
    </Modal>
);

export default ListModal;

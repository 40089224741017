export const removeHtmlTagsAndEntities = (htmlString: string): string => {
    if (typeof htmlString !== "string") return "";
    const noTags = htmlString.replace(/<[^>]*>/g, "");

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = noTags;
    const decodedString = tempDiv.textContent || tempDiv.innerText || "";

    return decodedString;
};

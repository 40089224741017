/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ThemeConfig } from "antd";
import { defaultVariables } from "./defaultVariables";

interface ExtendedThemeConfig extends ThemeConfig {
    token?: Partial<{ [key: string]: string | number }>;
}

export const defaultTheme: ExtendedThemeConfig = {
    token: {
        fontFamily: "Basier Circle,sans-serif",
        borderRadius: 6,
        ...defaultVariables,
    },
    components: {
        Button: {
            controlHeight: 37,
            borderRadius: 6,
            colorPrimary: defaultVariables.primaryColor,
            colorPrimaryHover: defaultVariables.primaryHover,
            // @ts-ignore
            fontWeight: 500,
            colorPrimaryActive: defaultVariables.primaryHover,
        },
        Input: {
            controlHeight: 37,
            borderRadius: 6,
            colorPrimaryHover: defaultVariables.primaryHover,
            colorPrimary: defaultVariables.primaryColor,
            colorError: defaultVariables.error,
        },
        Radio: {
            // @ts-ignore
            buttonSolidCheckedBg: defaultVariables.primaryColor,
            buttonCheckedBg: defaultVariables.primaryColor,
            colorPrimary: defaultVariables.primaryColor,
            colorPrimaryHover: defaultVariables.primaryHover,
        },
        InputNumber: {
            controlHeight: 37,
            borderRadius: 6,
            colorPrimaryHover: defaultVariables.primaryHover,
            colorPrimary: defaultVariables.primaryColor,
            colorError: defaultVariables.error,
        },
        Select: {
            controlHeight: 37,
            borderRadius: 6,
            colorPrimaryHover: defaultVariables.primaryHover,
            colorPrimary: defaultVariables.primaryColor,
            colorError: defaultVariables.error,
        },
        Typography: {
            colorText: defaultVariables.textBlack,
            fontWeightStrong: 500,
        },
        Switch: {
            colorPrimary: defaultVariables.primaryColor,
            colorPrimaryHover: defaultVariables.primaryHover,
            sizeSM: 250,
        },
        DatePicker: {
            // @ts-ignore
            activeBorderColor: defaultVariables.primaryColor,
            colorPrimary: defaultVariables.primaryColor,
            hoverBorderColor: defaultVariables.primaryHover,
            lineHeight: 1.9,
        },
        Pagination: {
            colorPrimary: defaultVariables.primaryColor,
            colorPrimaryHover: defaultVariables.primaryHover,
            colorPrimaryBorder: defaultVariables.primaryColor,
            colorText: defaultVariables.textMedium,
            colorBgTextHover: defaultVariables.grey50,
        },
        Tooltip: {
            fontSize: 13,
            paddingSM: 8,
        },
        Table: {
            fontFamily: "'Basier Circle', sans-serif",
            rowHoverBg: defaultVariables.primaryHoverOpacity,
            fontSize: 14,
            cellPaddingBlock: 10.5,
            cellPaddingInline: 10.5,
            cellPaddingBlockSM: 6,
            cellPaddingInlineSM: 16,
            colorText: "#424342",
        },
        Tabs: {
            inkBarColor: defaultVariables.primaryColor,
            itemActiveColor: defaultVariables.greyBlack,
            itemColor: defaultVariables.grey700,
            itemHoverColor: defaultVariables.greyBlack,
            itemSelectedColor: defaultVariables.greyBlack,
            colorBorder: defaultVariables.grey100,
            titleFontSize: 16,
        },
        Spin: {
            colorPrimary: defaultVariables.primaryColor,
        },
        Badge: {
            dotSize: 100,
            indicatorHeightSM: 100,
            textFontSizeSM: 100,
        },
        Dropdown: {
            borderRadiusLG: 4,
            boxShadow: "none",
            boxShadowSecondary: "0px 4px 12px 0px #1515150D",
        },
        Upload: {
            colorPrimary: defaultVariables.primaryColor,
        },
        Modal: {
            titleFontSize: 20,
            fontWeightStrong: 700,
        },
    },
};

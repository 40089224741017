export const detectChangedNodes = <T extends { pk?: number | string | undefined }>({
    oldNodes,
    newNodes,
}: {
    oldNodes: T[];
    newNodes: T[];
}): T[] => {
    const changedNodes: T[] = [];

    // if the node doesn't exist on the original race nodes delete it
    oldNodes.forEach((node) => {
        if (!newNodes.find((n) => n.pk === node.pk)) {
            changedNodes.push({ pk: node.pk } as T);
        }
    });

    // if the node doesn't exist on the original race nodes add it
    newNodes.forEach((node) => {
        if (!oldNodes.find((n) => n.pk === node.pk)) {
            changedNodes.push(node);
        }
    });

    // add the remaining nodes whose any property have changed
    newNodes.forEach((node) => {
        const originalNode = oldNodes.find((n) => n.pk === node.pk);
        if (originalNode) {
            const updatedNode = Object.keys(node).reduce((acc, nodeKey) => {
                if (node[nodeKey] !== originalNode[nodeKey]) {
                    acc[nodeKey] = node[nodeKey];
                }
                return acc;
            }, {} as Partial<T>);

            if (Reflect.ownKeys(updatedNode).length !== 0) {
                changedNodes.push({ pk: node.pk, ...updatedNode } as T);
            }
        }
    });

    return changedNodes;
};

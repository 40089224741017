import { useEffect, ReactNode, ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import URL from "app/constants/route_urls";
import { _isEmpty, trimLink } from "app/utils/helpers";
import userSlice from "app/store/user/user.slice";

const PublicRoute = ({
    children,
    title,
}: {
    children: ReactNode;
    title?: string;
}): ReactElement | ReactNode | null => {
    const { isAuthenticated, user } = userSlice((state) => state);
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location?.search);
    const redirectTo = query.get("redirectTo");
    const next = query.get("next");

    // Set page title
    useEffect(() => {
        if (title) {
            document.title = title;
        }
    }, [location, title]);

    useEffect(() => {
        if (!_isEmpty(user) && user.login_redirect_path && isAuthenticated) {
            navigate(user.login_redirect_path, {
                replace: true,
            });

            return;
        }

        if (next && isAuthenticated) {
            const nextUrl = next;

            // remove double forward slash
            window.location.href = trimLink(nextUrl);

            // Once user logs in, navigate to dashboard before redirecting to next
            // navigate(URL.RACES, {
            //     replace: true,
            // });

            return;
        }

        // Redirect to location confirm if location is not confirmed
        if (!_isEmpty(user) && isAuthenticated && !user.location_confirmed) {
            navigate(URL.LOCATION_CONFIRM, {
                state: location.state,
                replace: true,
            });
            return;
        }

        // Redirect to previously visited protected route
        if (isAuthenticated && location?.state?.from) {
            navigate(location.state.from + (location.state.search || ""), {
                replace: true,
            });
            return;
        }

        // Redirect to URL.RACES when user has no redirection preference
        if (isAuthenticated) {
            navigate(URL.RACES, {
                replace: true,
            });
        }

        // intnetionally not adding location to dependency array
    }, [isAuthenticated, navigate, redirectTo, user.location_confirmed, user, next]);

    return children;
};

export default PublicRoute;

import { Table } from "antd";
import { ReactNode } from "react";

// Number of all columns including invisible ones
type Index = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type Items = {
    [key in Index]?: ReactNode | string;
};

// items is a key value pair where key is 1 to 9 and value is the value of the cell
const VarianceTableSummary = ({
    items,
    classNames,
}: {
    items: Items;
    classNames?: {
        [key in Index]?: string;
    };
}) => (
    <Table.Summary.Row>
        <Table.Summary.Cell className={classNames && classNames[0]} index={0}>
            {items[0]}
        </Table.Summary.Cell>
        <Table.Summary.Cell className={classNames && classNames[1]} index={1}>
            {items[1]}
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right" className={classNames && classNames[2]} index={2}>
            {items[2]}
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right" className={classNames && classNames[3]} index={3}>
            {items[3]}
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right" index={4} className={classNames && classNames[4]}>
            {items[4]}
        </Table.Summary.Cell>
        <Table.Summary.Cell align="right" index={5} className={classNames && classNames[5]}>
            {items[5]}
        </Table.Summary.Cell>
    </Table.Summary.Row>
);

export default VarianceTableSummary;

export const obscureData = (
    data: string,
    type: "name" | "email" | "phone" | "currency" | "text"
): string => {
    if (!data) return "";

    switch (type) {
        case "name":
            return data
                .split(" ")
                .map((word) => word[0] + "*".repeat(word.length - 1))
                .join(" ");
        case "email":
            return data.replace(/[^@]/g, "*").replace(/(?<=@[^@]*)\./g, "*");
        case "phone":
            return data.replace(/\d/g, "0");
        case "currency":
            return data.replace(/\d/g, "▯");
        case "text":
            return data.replace(/[^ ]/g, "*");
        default:
            return data;
    }
};

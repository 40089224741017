import { object, string } from "yup";

const validationMessages = {
    required: "This field is required",
    min: (type: string): string => `${type} should be at least 2 characters long`,
};

export const profileValidationSchema = object().shape({
    username: string()
        .min(2, validationMessages.min("Username"))
        .required(validationMessages.required),
    first_name: string()
        .min(2, validationMessages.min("First name"))
        .required(validationMessages.required),
    last_name: string()
        .min(2, validationMessages.min("Last name"))
        .required(validationMessages.required),
});

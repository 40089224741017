import { useState } from "react";
import { useEventListener } from "app/hooks/useEventListener";

export const useWindowSize = (): { width: number; height: number } => {
    const [windowSize, setWindowSize] = useState<{ width: number; height: number }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEventListener("resize", () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    });

    return { width: windowSize.width, height: windowSize.height };
};

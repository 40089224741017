/* eslint-disable react/no-danger */
import { ReactElement, useState } from "react";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { message, Button, Popconfirm, Modal } from "antd";
import { BudgetItemType } from "app/types/budget/budget.types";
import { FormikInput, FormikNumber, FormikSelect } from "app/components/elements/Formik";
import { useGetBudget, useGetBudgetFormData } from "app/utils/api/queries/budget.query";
import { budgetItemValidationSchema } from "app/lib/validation_schemas/budget_item_validation.schema";
import { useUpdateBudgetItem } from "app/utils/api/mutations/budget.mutation";
import { _isEmpty } from "app/utils/helpers";
import userSlice from "app/store/user/user.slice";
import "./index.scss";
import racesSlice from "app/store/races/races.slice";
import { PerCustomOutlined, PerEventOutlined, PerPaticipantOutlined } from "assets";
import ProTooltip from "app/components/elements/wrappers/ProTooltip";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import { HELP_LINKS } from "../constants";

const qty_type_icons: {
    [key: string]: JSX.Element;
} = {
    participant: <PerPaticipantOutlined />,
    event: <PerEventOutlined />,
    custom: <PerCustomOutlined />,
};

type ExtendBudgetItemType = {
    virtual_type: string;
    virtual_group: string;
    virtual_search_key: string;
};

const AddEditBudgetItemModal = ({
    item,
    isVisible,
    closeModalHandler,
    mode,
    type,
}: {
    item:
        | Partial<
              BudgetItemType & {
                  quantity_override: number | null;
              }
          >
        | undefined;
    isVisible: boolean;
    mode: "add" | "edit";
    type: "cost" | "revenue";
    closeModalHandler: () => void;
}): ReactElement => {
    const { data: formData } = useGetBudgetFormData();
    const { user } = userSlice((state) => state);
    const { currentRace } = racesSlice((state) => state);
    const { data: budget } = useGetBudget(currentRace.pk!);
    const UserModule = User(user as IUser);
    const [addMode, setAddMode] = useState<"search" | "select">("search");
    const canAccessBB = useCanAccessBB();

    const { mutateAsync: updateBudgetItem, isPending: isLoading } = useUpdateBudgetItem({
        onSuccess: () => {
            closeModalHandler();
            setAddMode("search");
            message.success("Item updated successfully");
        },

        onError: (error) => {
            if (error.status === 500)
                message.error(error?.response?.data?.message || "Something went wrong");
        },
    });

    const { mutate: removeBudgetItem, isPending: isRemoving } = useUpdateBudgetItem({
        onSuccess: () => {
            closeModalHandler();
            setAddMode("search");
        },

        onError: (error) => {
            message.error(error?.response?.data?.message || "Something went wrong");
        },
    });

    const submitBudgetItem = async (
        values: BudgetItemType,
        actions: FormikHelpers<BudgetItemType & ExtendBudgetItemType>
    ) => {
        const updatedData = Object.keys(values).reduce((acc, key) => {
            if (item && values[key] !== item[key]) {
                acc[key] = values[key];
            }

            return acc;
        }, {} as BudgetItemType);

        if (!_isEmpty(updatedData)) {
            try {
                await updateBudgetItem({
                    racePk: currentRace.pk!,
                    item: {
                        ...updatedData,
                        pk: item?.pk,
                        event: updatedData.event?.pk ? { pk: updatedData.event.pk } : null,
                        category: updatedData.category
                            ? { pk: updatedData.category?.pk }
                            : undefined,
                    },
                });
            } catch (error) {
                if (error?.response?.data?.items) {
                    error.response.data.items.forEach((item: string[]) => {
                        Object.entries(item)?.forEach(([key, value]) => {
                            if (Array.isArray(value)) {
                                actions.setFieldError(key, value[0]);
                            }
                        });
                    });
                }
            }
        }

        actions.setSubmitting(false);
    };

    const quantityTypeOptions = Object.keys(formData?.quantity_types || {}).map((key) => ({
        key,
        label: formData?.quantity_types[key],
        value: key,
    }));

    const isSearchMode = mode === "add" && addMode === "search";
    const isSelectMode = mode === "add" && addMode === "select";
    const isAddMode = mode === "add";
    const isEditMode = mode === "edit";

    const handleRemoveItem = async (item: BudgetItemType) => {
        await removeBudgetItem({
            racePk: currentRace.pk!,
            item: { pk: item.pk },
        });
    };

    return (
        <Modal
            open={isVisible}
            onCancel={() => {
                setAddMode("search");
                closeModalHandler();
            }}
            title={`${mode === "edit" ? item?.category?.name : `Add new ${type} item`}`}
            width={480}
            // overflow={mode === "add" && addMode === "search"}
            className="add-edit-budget__modal"
            destroyOnClose
            footer={null}
            centered
        >
            <Formik
                validateOnChange
                validateOnMount
                initialValues={
                    {
                        ...(item as BudgetItemType),
                        virtual_type: item?.category?.type || type,
                        virtual_group: item?.category?.group || "",
                        virtual_search_key: "",
                    } as BudgetItemType & ExtendBudgetItemType
                }
                validationSchema={budgetItemValidationSchema}
                onSubmit={(values, actions) => {
                    submitBudgetItem(values, actions);
                    actions.setSubmitting(true);
                }}
            >
                {({ values, errors, isSubmitting, setFieldValue, setFieldError, setTouched }) => {
                    const virtualTypeOptions = [
                        { label: "Cost", value: "cost", key: "cost" },
                        { label: "Revenue", value: "revenue", key: "revenue" },
                    ];

                    const virtualGroups = new Set(
                        formData?.budget_item_categories
                            .filter((c) => c.type === values.virtual_type)
                            .map((c) => c.group)
                    );

                    const virtualGroupOptions = Array.from(virtualGroups).map((g) => ({
                        label: g,
                        value: g,
                        key: g,
                    }));

                    const cost_revenue = values.virtual_type === "cost" ? "cost" : "revenue";
                    const paid_earned = values.virtual_type === "cost" ? "paid for" : "earned on";

                    const renderGroupLabel = () => {
                        if (mode === "add") {
                            if (values.virtual_type === "cost") {
                                return "Cost group";
                            }

                            if (values.virtual_type === "revenue") {
                                return "Revenue group";
                            }
                        }

                        if (mode === "edit") {
                            if (item?.category?.type === "cost") {
                                return "Cost group";
                            }

                            if (item?.category?.type === "revenue") {
                                return "Revenue group";
                            }
                        }

                        return "Group";
                    };

                    const allSearchableIetms = formData?.budget_item_categories.filter(
                        (o) => o.name !== "Other"
                    );

                    const searchableItemsByType = allSearchableIetms?.filter(
                        (c) => c.type === values.virtual_type
                    );

                    const groupedSearchableItems = searchableItemsByType
                        ? Object.values(
                              searchableItemsByType.reduce((acc, option) => {
                                  const { group } = option;
                                  if (!acc[group]) {
                                      acc[group] = {
                                          label: group.replace(/\b\w/g, (match) =>
                                              match.toUpperCase()
                                          ),
                                          options: [],
                                      };
                                  }
                                  acc[group].options.push(option);
                                  return acc;
                              }, {})
                          )
                        : [];

                    const SearchField = (
                        <Field
                            label={`Pick a ${type} item:`}
                            name="virtual_search_key"
                            searchable
                            options={groupedSearchableItems}
                            component={FormikSelect}
                            onChange={(value: string) => {
                                const searchItem = JSON.parse(value);
                                setFieldValue("category", searchItem);
                                setFieldValue("virtual_group", searchItem.group);
                                setFieldValue("virtual_type", searchItem.type);
                            }}
                            iconVisible={false}
                            className="add-edit-budget__search"
                            dropdownAlign={{
                                overflow: { adjustX: 1, adjustY: -1 },
                            }}
                        />
                    );

                    const ItemFields = (
                        <>
                            <Field
                                label={renderGroupLabel()}
                                name="virtual_group"
                                required
                                searchable
                                options={virtualGroupOptions}
                                onChange={(value: string) => {
                                    setFieldValue("category", null);
                                    setTouched({ category: false });

                                    if (isSelectMode && !values.virtual_search_key) {
                                        setFieldValue(
                                            "category",
                                            formData?.budget_item_categories.find(
                                                (item) =>
                                                    item.group === value && item.name === "Other"
                                            )
                                        );
                                    }
                                }}
                                component={FormikSelect}
                                tooltipText={
                                    <span>
                                        The group this {cost_revenue} item belongs to.{" "}
                                        <a
                                            className="link-inline"
                                            href={
                                                cost_revenue === "cost"
                                                    ? HELP_LINKS.budgetItemEditor.costGroup
                                                    : HELP_LINKS.budgetItemEditor.revenueGroup
                                            }
                                            target="learnMoreFrame"
                                        >
                                            Learn more
                                        </a>
                                    </span>
                                }
                                dropdownAlign={{
                                    overflow: { adjustX: 1, adjustY: -1 },
                                }}
                            />

                            <Field
                                label="Item type"
                                name="category"
                                required
                                searchable
                                options={formData?.budget_item_categories.filter(
                                    (c) => c.group === values.virtual_group
                                )}
                                component={FormikSelect}
                                initialValue={
                                    isSelectMode && !values.virtual_group ? "Other" : undefined
                                }
                                isDisabled={isSelectMode && !values.virtual_group}
                                dropdownAlign={{
                                    overflow: { adjustX: 1, adjustY: -1 },
                                }}
                                tooltipText={
                                    <span>
                                        The type of {cost_revenue} represented by this item.{" "}
                                        <a
                                            className="link-inline"
                                            href={HELP_LINKS.budgetItemEditor.itemType}
                                            target="learnMoreFrame"
                                        >
                                            Learn more
                                        </a>
                                    </span>
                                }
                            />

                            {values.category?.name === "Other" && (
                                <Field
                                    label="Item name"
                                    name="name_override"
                                    searchable
                                    component={FormikInput}
                                    maxLength={48}
                                />
                            )}
                        </>
                    );

                    const OtherFields = (
                        <>
                            <div className="add-edit-budget__row">
                                {(!values.category || values.category?.items_editable) && (
                                    <>
                                        <Field
                                            label="Budgeted price"
                                            name="price"
                                            required
                                            min={0}
                                            prefix={user?.location?.ccy_symbol}
                                            component={FormikNumber}
                                            setFieldValue={setFieldValue}
                                            tooltipText={
                                                <span>
                                                    The amount that has been budgeted for this item.{" "}
                                                    <a
                                                        className="link-inline"
                                                        href={
                                                            HELP_LINKS.budgetItemEditor
                                                                .budgetedPrice
                                                        }
                                                        target="learnMoreFrame"
                                                    >
                                                        Learn more
                                                    </a>
                                                </span>
                                            }
                                        />

                                        <ProTooltip custom_cta={null} condition={canAccessBB}>
                                            <Field
                                                label="Actual price"
                                                name="actual_price"
                                                prefix={user?.location?.ccy_symbol}
                                                component={FormikNumber}
                                                allowEmpty
                                                setFieldValue={setFieldValue}
                                                min={0.01}
                                                tooltipText={
                                                    <span>
                                                        The actual amount {paid_earned} for this
                                                        item.{" "}
                                                        <a
                                                            className="link-inline"
                                                            href={
                                                                HELP_LINKS.budgetItemEditor
                                                                    .actualPrice
                                                            }
                                                            target="learnMoreFrame"
                                                        >
                                                            Learn more
                                                        </a>
                                                    </span>
                                                }
                                                disabled={!canAccessBB}
                                            />
                                        </ProTooltip>
                                    </>
                                )}
                            </div>

                            {(!values.category || values.category?.items_editable) && (
                                <Field
                                    label="Quantity type"
                                    name="quantity_type"
                                    required
                                    options={quantityTypeOptions.map((o) => ({
                                        ...o,
                                        label: (
                                            <>
                                                {qty_type_icons[o.value]}
                                                {o.label}
                                            </>
                                        ),
                                    }))}
                                    component={FormikSelect}
                                    rootClassName="add-edit-budget__quantity-type-field"
                                    tooltipText={
                                        <span>
                                            The quantity the price for this item applies to. Choose
                                            whether the price should be applied to all participants
                                            in an event (per participant), whether it's a fixed
                                            price for an entire event (=per event), or whether you
                                            would like to specify a custom quantity for this item.{" "}
                                            <a
                                                className="link-inline"
                                                href={HELP_LINKS.budgetItemEditor.quantityType}
                                                target="learnMoreFrame"
                                            >
                                                Learn more
                                            </a>
                                        </span>
                                    }
                                    dropdownAlign={{
                                        overflow: { adjustX: 1, adjustY: -1 },
                                    }}
                                />
                            )}

                            {values.quantity_type === "custom" ? (
                                <Field
                                    label="Quantity override"
                                    name="quantity_override"
                                    required
                                    component={FormikNumber}
                                    setFieldValue={setFieldValue}
                                    tooltipText="Set the quantity the price for this item should apply to."
                                    integer
                                />
                            ) : (
                                <Field
                                    label="Event"
                                    name="event"
                                    options={
                                        budget
                                            ? [
                                                  ...budget.events,
                                                  {
                                                      name: "All events",
                                                  },
                                              ]
                                            : null
                                    }
                                    component={FormikSelect}
                                    tooltipText={
                                        <span>
                                            The event this price applies to. If you are using
                                            per-participant pricing, the price will apply only to
                                            participants in that event. To apply the per-participant
                                            price to all participants across all events, set this to
                                            "All events".{" "}
                                            <a
                                                className="link-inline"
                                                href={HELP_LINKS.budgetItemEditor.event}
                                                target="learnMoreFrame"
                                            >
                                                Learn more
                                            </a>
                                        </span>
                                    }
                                    dropdownAlign={{
                                        overflow: { adjustX: 1, adjustY: 1 },
                                    }}
                                />
                            )}
                        </>
                    );

                    const SaveButton = (
                        <Button
                            type="primary"
                            loading={isLoading}
                            disabled={isLoading || isRemoving}
                            className="add-edit-budget__cta"
                            htmlType="submit"
                        >
                            <span>Save changes</span>
                        </Button>
                    );

                    const DeleteButton = (
                        <Popconfirm
                            title="Delete item"
                            description=" Are you sure you want to delete this item?"
                            onConfirm={() => handleRemoveItem(item as BudgetItemType)}
                            okText="Delete"
                            cancelText="Cancel"
                            okButtonProps={{ loading: isLoading || isRemoving, danger: true }}
                            getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode as HTMLElement
                            }
                        >
                            <Button
                                type="default"
                                loading={isRemoving}
                                disabled={isLoading || isRemoving}
                                danger
                                className="add-edit-budget__delete"
                            >
                                <span>Delete item</span>
                            </Button>
                        </Popconfirm>
                    );

                    return (
                        <Form className="add-edit-budget__form">
                            {isSearchMode && (
                                <>
                                    {SearchField}
                                    <div className="ant-modal-footer">
                                        <Button
                                            disabled={isLoading}
                                            className="ant-btn-primary-outline"
                                            type="default"
                                            onClick={() => {
                                                setTouched({
                                                    virtual_group: true,
                                                });
                                                setFieldValue("virtual_search_key", "");
                                                setFieldValue("category", null);
                                                setFieldValue("virtual_group", "");
                                                setAddMode("select");
                                            }}
                                        >
                                            <span>Add other {type} item</span>
                                        </Button>

                                        <Button
                                            type="primary"
                                            disabled={!values.virtual_search_key}
                                            onClick={() => setAddMode("select")}
                                        >
                                            <span>Add item</span>
                                        </Button>
                                    </div>
                                </>
                            )}

                            {isSelectMode && (
                                <>
                                    {ItemFields}
                                    {OtherFields}
                                    {SaveButton}
                                </>
                            )}

                            {isEditMode && (
                                <>
                                    {ItemFields}
                                    {OtherFields}

                                    <div>
                                        {SaveButton}
                                        {DeleteButton}
                                    </div>
                                </>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default AddEditBudgetItemModal;

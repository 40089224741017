import { trimLink } from "app/utils/helpers";

const PUBLIC_SITE_URL = {
    JOIN: trimLink(`${import.meta.env.VITE_BASE_URL}/join/`),
    HELP: trimLink("https://help.racedirectorshq.com/"),
    TERMS_AND_CONDITIONS: trimLink(`${import.meta.env.VITE_BASE_URL}/terms-conditions/`),
    CONTACT: trimLink(`${import.meta.env.VITE_BASE_URL}/contact/`),
    BUSINESS_LISTINGS: trimLink(`${import.meta.env.VITE_BASE_URL}/business-listings/`),
};

export default PUBLIC_SITE_URL;

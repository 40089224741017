import PageWithLoader from "app/hoc/page_with_loader";
import "./index.scss";
import { Button, Typography } from "antd";
import { Field, Formik, useFormik } from "formik";
import { FormikSelect, FormikTextArea } from "app/components/elements/Formik";
import { addReviewValidation } from "app/lib/validation_schemas/add_review_validation_schema";

const AddReview = () => (
    <PageWithLoader isLoading={false}>
        <div className="add-review">
            <Typography.Title level={1}>Your review for X</Typography.Title>

            <Formik
                key="x"
                validationSchema={addReviewValidation}
                // onSubmit={(values, actions) => {
                //     console.log(values);
                // }}
                initialValues={{
                    content: "",
                    rating: null,
                }}
            >
                {({ values, errors, submitForm, setFieldValue, setFieldError, dirty }) => (
                    <div className="add-review__form">
                        <Field
                            label="Your comments"
                            name="content"
                            required
                            component={FormikTextArea}
                        />

                        <Field
                            label="Your rating"
                            name="rating"
                            required
                            component={FormikSelect}
                            options={[1, 2, 3, 4, 5]}
                            hidePlaceholder
                        />

                        <div className="add-review__form-cta">
                            <Button
                                type="primary"
                                loading={false}
                                disabled={false}
                                className="responsive-cta"
                                onClick={async () => {
                                    submitForm();
                                }}
                            >
                                <span>Submit</span>
                            </Button>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    </PageWithLoader>
);

export default AddReview;

import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import { BudgetFormDataType, BudgetType } from "app/types/budget/budget.types";
import { QUERY_KEYS } from "./constants";

export const useGetBudget = (
    racePk: number | string,
    options?: UseQueryOptionsType
): UseQueryReturnType<BudgetType | undefined> => {
    const { data, isPending, isError, error } = useQuery<BudgetType>({
        queryKey: [QUERY_KEYS.BUDGET, racePk],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<BudgetType>(`${API_URL.RACES}${racePk}/budget/`, {
                signal,
            });
            return data;
        },
        retry(failureCount, error) {
            if ((error as AxiosError).response?.status === 404) return false;
            return failureCount < 3;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

export const useGetBudgetFormData = (
    options?: UseQueryOptionsType
): UseQueryReturnType<BudgetFormDataType | undefined> => {
    const { data, isPending, isError, error } = useQuery<BudgetFormDataType>({
        queryKey: [QUERY_KEYS.BUDGET_FORM_DATA],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<BudgetFormDataType>(`${API_URL.BUDGETS_FORM_DATA}`, {
                signal,
            });
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

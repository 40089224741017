import React, { ReactElement, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { IUser } from "app/store/types/user.types";
import User from "app/utils/user";
import userSlice from "app/store/user/user.slice";
import URL from "app/constants/route_urls";

const ProtectedBusinessRoute = ({ children }: { children: ReactNode }): ReactElement | null => {
    const { isAuthenticated, user } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const canLoadBusinessDashboard =
        (isAuthenticated && UserModule.isStaff) || import.meta.url.includes("dash-staging");

    if (!canLoadBusinessDashboard) return <Navigate to={URL.UPCOMING_RACES} />;

    return React.isValidElement(children) ? children : null;
};

export default ProtectedBusinessRoute;

import { ReactElement } from "react";
import logo from "assets/logo_short.svg";
import "./loading_indicator.scss";

const LoadingIndicator = (): ReactElement => (
    <div className="loading-indicator">
        <div className="app-loading">
            <img className="main-logo" src={logo} alt="" />
            <div className="logo-wrap" />
        </div>
    </div>
);

export default LoadingIndicator;

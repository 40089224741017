import { useMemo } from "react";
import { IUserListingCredits } from "app/store/types/user.types";
import userSlice from "app/store/user/user.slice";

// TODO -> Add this to the User Module instead
export const useHasListingCredits = () => {
    const { user } = userSlice((state) => state);

    const userHasListingCredits: boolean = useMemo(() => {
        const credits: number[] = Object.values(user?.listing_credits as IUserListingCredits);
        const calcSum: number = credits.reduce<number>(
            (obj: number, item: number) => obj + item,
            0 as number
        );

        if (calcSum > 0) return true;
        return false;
    }, [user]);

    return userHasListingCredits;
};

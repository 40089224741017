import { object, string, number, array } from "yup";

const validationMessages = {
    required: "This field is required",
};

export const budgetEventValidationSchema = () =>
    object().shape({
        nodes: array().of(
            object({
                pk: number().optional(),
                date: string().required(validationMessages.required),
                entry_fee: number().required(validationMessages.required),
                participants_curr: number().required(validationMessages.required),
                participants_proj: number()
                    .required(validationMessages.required)
                    .min(1, "Proj. Participants cannot be zero"),
            })
        ),
    });

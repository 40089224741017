import { ReactElement, ReactNode, useState } from "react";
import { Button, message, Typography } from "antd";
import { Link } from "react-router-dom";
import { SponsorType } from "app/types/sponsors/sponsor.types";
import { useSponsors } from "app/utils/api/queries/sponsors.query";
import { _isEmpty } from "app/utils/helpers";
import { IUser } from "app/store/types/user.types";
import API_URL from "app/constants/api_urls";
import API from "app/utils/api/axios";
import PageWithLoader from "app/hoc/page_with_loader";
import SponsorCard from "app/components/elements/cards/sponsor_card";
import userSlice from "app/store/user/user.slice";
import modalsSlice from "app/store/modals/modals.slice";
import User from "app/utils/user";
import URL from "app/constants/route_urls";
import "./index.scss";
import racesSlice from "app/store/races/races.slice";
import { useGetWidgetRaces } from "app/utils/api/queries/races.query";

const { Text, Title } = Typography;

const SponsorError = ({ children }: { children: ReactNode }) => (
    <div className="sponsors">
        <Title level={1}>Sponsor Finder</Title>
        <Text>Search online sponsorship programs matching your race location and profile.</Text>
        <div className="sponsors__error">{children}</div>
    </div>
);

const SponsorFinder = (): ReactElement => {
    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);
    const { data: widgetRaces, isLoading: isWidgetRacesLoading } = useGetWidgetRaces({
        display: "menu",
    });
    const { currentRace } = racesSlice((state) => state);
    const { toggleImportRaceModal, toggleMemberFeatureShowResultsModal } = modalsSlice(
        (state) => state
    );
    const racePk = currentRace.pk!;
    const {
        data,
        isLoading: isSponsorsLoading,
        isError,
    } = useSponsors(racePk, {
        enabled: !!currentRace.pk,
        refetchOnMount: true,
    });

    const isLoading = (!!currentRace.pk && isSponsorsLoading) || isWidgetRacesLoading;

    // Broken links
    const [brokenLinks, setBrokenLinks] = useState<number[]>([]);

    // API call to report broken links
    const reportBrokenLink = async (pk: number): Promise<void> => {
        try {
            setBrokenLinks([...brokenLinks, pk]);
            const { data } = await API.get(`${API_URL.SPONSORS_BROKEN_LINK}${pk}/report-broken/`);
            message.success(data);
        } catch (error) {
            message.error("Something went wrong. Please try again");
            setBrokenLinks((prevState: number[]) =>
                prevState.filter((link: number) => link !== pk)
            );
        }
    };

    const renderSponsorCards = (sponsor: SponsorType, index: number): JSX.Element => (
        <SponsorCard
            key={index}
            pk={sponsor.pk}
            name={sponsor.name}
            description={sponsor.description}
            logo={sponsor.logo}
            image={sponsor.image}
            benefits={sponsor.benefits}
            category={sponsor.category}
            response_by={sponsor.response_by}
            apply_url={sponsor.apply_url}
            apply_mode={sponsor.apply_mode}
            reportLink={reportBrokenLink}
            brokenLinks={brokenLinks}
            obscured={
                UserModule.sponsorFinder().leads !== undefined && data !== undefined
                    ? index >= (UserModule.sponsorFinder().leads ?? 0)
                    : false
            }
        />
    );

    // Sponsors content
    const renderSponsorsContent = (): JSX.Element | null => {
        const checkForUpcomingRace = widgetRaces?.find((race) => race.groupLabel === "upcoming");

        if (isError)
            return (
                <SponsorError>
                    <Text>Something went wrong. Please try again.</Text>
                </SponsorError>
            );

        if (currentRace.type === "past") {
            return (
                <SponsorError>
                    <Text>You cannot use Sponsor Finder on a past race.</Text>
                    <Text>
                        To use this tool, please select an{" "}
                        <Link className="link" to={URL.UPCOMING_RACES}>
                            upcoming race
                        </Link>{" "}
                        or{" "}
                        <span className="link" role="presentation" onClick={toggleImportRaceModal}>
                            add a new race
                        </span>
                        .
                    </Text>
                </SponsorError>
            );
        }

        if (!checkForUpcomingRace && !isWidgetRacesLoading) {
            return (
                <SponsorError>
                    <Text>
                        To use this tool, please{" "}
                        <span
                            role="presentation"
                            onClick={() => {
                                toggleImportRaceModal();
                            }}
                            className="link"
                        >
                            add a race{" "}
                        </span>
                        first.
                    </Text>
                </SponsorError>
            );
        }

        if (_isEmpty(currentRace))
            return (
                <SponsorError>
                    <Text>To find sponsors for your event, please select a race first.</Text>
                </SponsorError>
            );

        if (_isEmpty(data) && data !== undefined) {
            return (
                <SponsorError>
                    <Text>
                        No sponsors were found for <b>{currentRace.name}</b>. Please select another
                        race.
                    </Text>
                </SponsorError>
            );
        }

        return (
            <div className="sponsors">
                <div className="page__header">
                    <Title level={1}>Sponsor Finder</Title>
                    <Text>
                        Search online sponsorship programs matching your race location and profile.
                    </Text>
                </div>

                {!_isEmpty(currentRace) && (
                    <div className="page__toolbar">
                        <Text strong>
                            {data?.length} sponsorship programs found for {currentRace.name}
                        </Text>
                    </div>
                )}
                {!_isEmpty(data) && (
                    <div className={`sponsors__content ${!data ? "isLoading" : ""}`}>
                        <div className="sponsors__content-cards-wrap">
                            {data?.map((sponsor: SponsorType, index: number) =>
                                renderSponsorCards(sponsor, index)
                            )}
                        </div>
                        {user?.plan?.upgradeable && (
                            <Button
                                type="primary"
                                className="sponsors__more-results"
                                onClick={toggleMemberFeatureShowResultsModal}
                            >
                                Show all results
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return <PageWithLoader isLoading={isLoading}>{renderSponsorsContent()}</PageWithLoader>;
};

export default SponsorFinder;

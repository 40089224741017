import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import API_URL from "app/constants/api_urls";
import API from "app/utils/api/axios";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import userSlice from "app/store/user/user.slice";
import racesSlice from "app/store/races/races.slice";
import { RaceType } from "app/types/races/race.types";
import { useRacesWidget } from "app/hooks/useRacesWidget";
import { prepareRaceBody } from "../../helpers/prepare_race_body";
import { QUERY_KEYS } from "../queries/constants";

const deleteRace = async (racePk: number): Promise<void> => {
    await API.delete(`${API_URL.DELETE_RACE}${racePk}/delete/`);
};

const createRace = async (formData: any): Promise<{ result: RaceType }> => {
    const body = prepareRaceBody(formData, "add");

    const { data }: { data: RaceType } = await API.post(`${API_URL.ADD_RACE}`, body, {
        timeout: 60_000,
    });

    return { result: data };
};

const updateRace = async (formData: any): Promise<{ result: RaceType }> => {
    const { pk }: { pk: number } = formData;
    const body = prepareRaceBody(formData, "edit");

    const { data } = await API.patch(`${API_URL.RACES}${pk}/update/`, body, {
        timeout: 60000,
    });

    return { result: data };
};

export const useDeleteRace = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<void, AxiosError<{ message: string }>, { racePk: number }>({
        mutationFn: ({ racePk }) => deleteRace(racePk),
        onSuccess: (_, { racePk }) => {
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.RACES],
            });

            queryClient
                .invalidateQueries({
                    queryKey: ["widget_races"],
                })
                .then(() => {
                    if (racesSlice.getState().currentRace.pk === racePk) {
                        Storage.remove(
                            `${STORAGE_CONSTANTS.currentRace}.${userSlice.getState().user?.email}`
                        );

                        racesSlice.getState().setCurrentRace({});
                    }
                });

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

export const useCreateRace = ({
    onSuccess,
    onError,
}: {
    onSuccess?: (result: RaceType) => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();
    const { handleSelectRaceString } = useRacesWidget();

    return useMutation<{ result: RaceType }, AxiosError<any>, { formData: any }>({
        mutationFn: ({ formData }: { formData: any }) => createRace(formData),
        onSuccess: (response, { formData }) => {
            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.RACES],
            });

            queryClient.invalidateQueries({
                queryKey: ["widget_races"],
            });
            handleSelectRaceString(
                JSON.stringify({
                    pk: response.result.pk,
                    name: response.result.name,
                    type: "upcoming",
                })
            );

            if (onSuccess) {
                onSuccess(response.result);
            }
        },

        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

export const useUpdateRace = ({
    onSuccess,
    onError,
}: {
    onSuccess?: (result: RaceType) => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();
    const { handleSelectRaceString } = useRacesWidget();

    return useMutation<{ result: RaceType }, AxiosError<any>, { formData: { pk: number } }>({
        mutationFn: ({ formData }: { formData: { pk: number } }) => updateRace(formData),
        onSuccess: (response, { formData }) => {
            queryClient.setQueryData<RaceType>(
                [QUERY_KEYS.RACE_DETAILS, formData.pk.toString()],
                response.result
            );

            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.RACES],
            });

            queryClient
                .invalidateQueries({
                    queryKey: ["widget_races"],
                })
                .then(() => {
                    handleSelectRaceString(
                        JSON.stringify({
                            pk: response.result.pk,
                            name: response.result.name,
                            type: "upcoming",
                        })
                    );
                });

            queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.BUDGET, formData?.pk],
            });

            if (onSuccess) {
                onSuccess(response.result);
            }
        },

        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};

export const QUERY_KEYS = {
    RACES: "RACES",
    WIDGET_RACES: "widget_races",
    RACE_DETAILS: "RACE_DETAILS",
    BUDGET: "BUDGET",
    BUDGET_FORM_DATA: "BUDGET_FORM_DATA",
    DOCUMENTS: "DOCUMENTS",
    OFFERS: "OFFERS",
    STRIPE_KEY: "STRIPE_KEY",
    SPONSORS: "SPONSORS",
    CUSTOMER_PORTAL: "CUSTOMER_PORTAL",
    RACE_FORM_DATA: "RACE_FORM_DATA",
    RACE_REPORT: "RACE_REPORT",
    RACE_REPORT_STATUS: "RACE_REPORT_sTATUS",
    USER_FREE_TRIALS: "USER_FREE_TRIALS",
};

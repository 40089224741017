import { ReactElement, useState } from "react";
import { theme, Typography, Button } from "antd";
import { BadgeType } from "app/types";
import "./vendor_badge_card.scss";

type VendorBadgeType = {
    openModal: () => void;
    id: string;
    color: BadgeType["colour"];
    styling: BadgeType["style"];
    image: string;
    height: number | null;
    width: number | null;
    description: string;
    tier: number;
};

const downloadPng = (imageUrl: string): void => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;

        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.href = dataURL;
        downloadLink.download = "downloaded_image.png";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    img.src = imageUrl;
};

const VendorBadgeCard = ({
    openModal,
    height,
    width,
    image,
    description,
}: Partial<VendorBadgeType>): ReactElement => {
    const { token } = theme.useToken();
    const imgUrl = `${import.meta.env.VITE_BASE_URL}${image}`;
    const [imgHasLoaded, setImgHasLoaded] = useState<boolean>(false);

    return (
        <div className="vendor-badge-card" style={{ borderRadius: token.borderRadius }}>
            <div className="vendor-badge-card__logo">
                <img
                    src={imgUrl}
                    width={width as number}
                    height={height as number}
                    className={`load-image ${imgHasLoaded ? "image-loaded" : ""}`}
                    onLoad={() => setImgHasLoaded(true)}
                    alt=""
                />
            </div>
            <div className="vendor-badge-card__title-cta-wrap">
                <div className="vendor-badge-card__title">
                    <Typography.Title level={5}>{description}</Typography.Title>
                </div>
                <div className="vendor-badge-card__cta">
                    <Button className="ant-btn-primary-outline" type="default" onClick={openModal}>
                        Embed code
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default VendorBadgeCard;

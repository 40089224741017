import { create } from "zustand";
import { devtools } from "zustand/middleware";
import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";

type ModalTypes =
    | "membersProgram"
    | "noListingCredits"
    | "importRaceModal"
    | "memberFeatureVendorOffersModal"
    | "memberFeatureShowResultsModal"
    | "memberFeatureListReportModal"
    | "noListingCreditsListReportModal"
    | "businessDashboardModal"
    | "listingsUpgradeModal"
    | "budgetBuilderTrialModal";

type InitialMiscStateType = {
    modals: {
        [Key in ModalTypes]: {
            isVisible: boolean;
        };
    };
    navbar: {
        isToggled: boolean;
    };
};

type IModalsSliceType = {
    modals: {
        [key: string]: {
            isVisible: boolean;
        };
    };
    navbar: {
        isToggled: boolean;
    };
    toggleListingsUpgradeModal: () => void;
    toggleMembersProgramModal: () => void;
    toggleNoListingCreditsModal: () => void;
    toggleImportRaceModal: () => void;
    toggleMemberFeatureVendorOffersModal: () => void;
    toggleMemberFeatureShowResultsModal: () => void;
    toggleNoListingCreditsListReportModal: () => void;
    toggleMemberFeatureListReportModal: () => void;
    toggleBusinessDashboardModal: () => void;
    toggleBudgetBuilderTrialModal: () => void;
    toggleNavbar: () => void;
    openNavbar: () => void;
    closeNavbar: () => void;
};

// Calculate default navbar state
const calcNavbarIsToggled = () => {
    // Always not toggled on mobile
    if (window.innerWidth <= 992) {
        return false;
    }

    const isNavbarToggled = Storage.get(STORAGE_CONSTANTS.isNavbarToggled);

    // Set toggled value based on local storage
    if (isNavbarToggled && typeof isNavbarToggled === "string") return JSON.parse(isNavbarToggled);

    // Default toggled on desktop
    return true;
};

const initialState: InitialMiscStateType = {
    modals: {
        listingsUpgradeModal: {
            isVisible: false,
        },
        businessDashboardModal: {
            isVisible: false,
        },
        membersProgram: {
            isVisible: false,
        },
        noListingCredits: {
            isVisible: false,
        },
        importRaceModal: {
            isVisible: false,
        },
        memberFeatureVendorOffersModal: {
            isVisible: false,
        },
        memberFeatureShowResultsModal: {
            isVisible: false,
        },
        memberFeatureListReportModal: {
            isVisible: false,
        },
        noListingCreditsListReportModal: {
            isVisible: false,
        },
        budgetBuilderTrialModal: {
            isVisible: false,
        },
    },
    navbar: {
        isToggled: calcNavbarIsToggled(),
    },
};

const toggleSpecificModal = (key: string) => (get: () => IModalsSliceType) => ({
    modals: {
        [key]: {
            isVisible: !get().modals[key]?.isVisible,
        },
    },
});

const modalsSlice = create<IModalsSliceType>()(
    devtools(
        (set, get) => ({
            ...initialState,
            toggleListingsUpgradeModal: (): void => {
                set(
                    toggleSpecificModal("listingsUpgradeModal")(get),
                    false,
                    "modalsSlice/toggleListingsUpgradeModal"
                );
            },
            toggleMembersProgramModal: (): void => {
                set(
                    toggleSpecificModal("membersProgram")(get),
                    false,
                    "modalsSlice/toggleMembersProgramModal"
                );
            },
            toggleNoListingCreditsModal: (): void => {
                set(
                    toggleSpecificModal("noListingCredits")(get),
                    false,
                    "modalsSlice/toggleNoListingCreditsModal"
                );
            },
            toggleImportRaceModal: (): void => {
                set(
                    toggleSpecificModal("importRaceModal")(get),
                    false,
                    "modalsSlice/toggleImportRaceModal"
                );
            },
            toggleMemberFeatureVendorOffersModal: (): void => {
                set(
                    toggleSpecificModal("memberFeatureVendorOffersModal")(get),
                    false,
                    "modalsSlice/toggleMemberFeatureVendorOffersModal"
                );
            },
            toggleMemberFeatureShowResultsModal: (): void => {
                set(
                    toggleSpecificModal("memberFeatureShowResultsModal")(get),
                    false,
                    "modalsSlice/toggleMemberFeatureShowResultsModal"
                );
            },
            toggleMemberFeatureListReportModal: (): void => {
                set(
                    toggleSpecificModal("memberFeatureListReportModal")(get),
                    false,
                    "modalsSlice/toggleMemberFeatureListReportModal"
                );
            },
            toggleNoListingCreditsListReportModal: (): void => {
                set(
                    toggleSpecificModal("noListingCreditsListReportModal")(get),
                    false,
                    "modalsSlice/toggleNoListingCreditsListReportModal"
                );
            },
            toggleBusinessDashboardModal: (): void => {
                set(
                    toggleSpecificModal("businessDashboardModal")(get),
                    false,
                    "modalsSlice/toggleBusinessDashboardModal"
                );
            },
            toggleBudgetBuilderTrialModal: (): void => {
                set(
                    toggleSpecificModal("budgetBuilderTrialModal")(get),
                    false,
                    "modalsSlice/toggleBudgetBuilderTrialModal"
                );
            },
            toggleNavbar: (): void => {
                const newIsToggled = !get().navbar.isToggled;
                set(
                    {
                        navbar: {
                            isToggled: newIsToggled,
                        },
                    },
                    false,
                    "navbar/toggleNavbar"
                );
                Storage.set(STORAGE_CONSTANTS.isNavbarToggled, JSON.stringify(newIsToggled));
            },
            openNavbar: (): void => {
                set(
                    {
                        navbar: {
                            isToggled: true,
                        },
                    },
                    false,
                    "navbar/openNavbar"
                );
                Storage.set(STORAGE_CONSTANTS.isNavbarToggled, JSON.stringify(true));
            },
            closeNavbar: (): void => {
                set(
                    {
                        navbar: {
                            isToggled: false,
                        },
                    },
                    false,
                    "navbar/closeNavbar"
                );

                Storage.set(STORAGE_CONSTANTS.isNavbarToggled, JSON.stringify(false));
            },
        }),
        { name: "Modals slice", enabled: !import.meta.env.PROD }
    )
);

export default modalsSlice;

import { FieldInputProps, FormikState } from "formik";
import { InputNumber, Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { fieldError } from "app/utils/helpers/formik";

export default ({
    label,
    required,
    field,
    form,
    prefix,
    allowNegative,
    setFieldValue,
    min = allowNegative ? undefined : 1,
    tooltipText,
    tooltipPlacement = "right",
    extendOnChange,
    roundDecimals = false,
    integer = false,
    allowEmpty = false,
    ...rest
}: {
    label: string;
    required: boolean;
    field: FieldInputProps<string>;
    form: FormikState<string>;
    prefix?: string;
    allowNegative?: boolean;
    tooltipText?: string;
    min?: number;
    tooltipPlacement?: TooltipPlacement;
    roundDecimals?: boolean;
    integer?: boolean;
    allowEmpty?: boolean;
    extendOnChange?: (valNum: string | number | null) => void;
    setFieldValue: (name: string, val: number) => void;
}) => {
    const error = fieldError(form, field);

    return (
        <div>
            {label && (
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <Typography.Text className="ant-label" strong>
                        <span className={`${tooltipText ? "tooltip-underline" : ""}`}>{label}</span>{" "}
                        {required ? <span className="ant-label-required">*</span> : null}
                    </Typography.Text>
                </Tooltip>
            )}
            <div>
                <InputNumber
                    controls={false}
                    min={min}
                    status={`${error ? "error" : ""}`}
                    style={{ width: "100%" }}
                    prefix={prefix}
                    name={field.name}
                    value={field.value as number | string}
                    onBlur={field.onBlur}
                    onKeyPress={(event) => {
                        if (integer) {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }
                    }}
                    // formatter={(val) => `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    // parser={(val) => (val ? val.replace(/,/g, ".") : "")}
                    // formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    // parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    onChange={(valNum) => {
                        if (!allowEmpty && valNum === null) return;

                        let roundedValue = valNum;
                        if (roundDecimals && !Number.isInteger(valNum)) {
                            roundedValue = Math.round(+valNum);
                        }

                        setFieldValue(field.name, valNum as number);

                        if (extendOnChange) {
                            extendOnChange(roundedValue);
                        }
                    }}
                    {...rest}
                />
                {error && !form.isSubmitting && (
                    <Typography.Text className="ant-error-label">{error}</Typography.Text>
                )}
            </div>
        </div>

        // <FormControl isInvalid={error && !form.isSubmitting} className={className}>
        //     {label && (
        //         <FormLabel htmlFor={field.name}>
        //             {label} {required ? <span>*</span> : null}
        //             {tooltip && <FormikTooltip>{tooltip}</FormikTooltip>}
        //         </FormLabel>
        //     )}
        //     <InputGroup size="lg" h="full">
        //         <InputNumber
        //             style={{ height: "37px" }}
        //             controls={false}
        //             min={min}
        //             status={`${error ? "error" : ""}`}
        //             prefix={prefix}
        //             name={field.name}
        //             value={field.value as number | string}
        //             onBlur={field.onBlur}
        //             formatter={(val) => `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        //             parser={(val) => (val ? val.replace(/,/g, ".") : "")}
        //             onChange={(valNum) => {
        //                 if (valNum === null) return;

        //                 if (valNum) {
        //                     setFieldValue(field.name, valNum as number);
        //                 } else {
        //                     setFieldValue(field.name, 0);
        //                 }
        //             }}
        //         />
        //     </InputGroup>

        //     {!form.isSubmitting && <FormErrorMessage>{error}</FormErrorMessage>}
        // </FormControl>
    );
};

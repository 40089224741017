import React, {
    ReactElement,
    useEffect,
    useState,
    useRef,
    KeyboardEvent,
    useLayoutEffect,
} from "react";
import { ChevronDownOutlined } from "rdhq-icons";
import * as Sentry from "@sentry/react";
import { _isEmpty } from "app/utils/helpers";
import { kFormatter } from "app/utils/helpers/kFormatter";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import userSlice from "app/store/user/user.slice";
import { ISelectProps, Option } from "./select.types";
import "./index.scss";

function Select({
    options,
    // placeholder,
    name,
    iconVisible = true,
    disabled = false,
    // optionalName,
    grouped,
    searchable = false,
    readonly = false,
    error,
    value,
    hasPadding = false,
    onChange,
    onBlur,
}: ISelectProps): ReactElement {
    const searchValue = value;
    const ref = useRef<HTMLDivElement>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string | undefined>(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const [values, setValues] = useState<string[]>([]);
    const [focusedValue, setFocusedValue] = useState<number>(-1);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectFilteredOptions, setSelectFilteredOptions] = useState<Option[]>(options);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [optionsHeight, setOptionsHeight] = useState<string>("0");
    const [parentWidth, setParentWidth] = useState<number>(0);

    const handleClickOutside = (e: Event): void => {
        const children: HTMLCollectionOf<Element> | undefined =
            parentRef?.current?.getElementsByTagName("*");

        if (children) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of children) {
                if (item === e.target) return;
            }
        }

        setIsOpen(false);
    };

    useEffect(() => {
        setSelectFilteredOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(options)]);

    useEffect(() => {
        if (!_isEmpty(searchTerm)) setIsOpen(true);
    }, [searchTerm]);

    useEffect(() => {
        window.addEventListener("click", handleClickOutside);
        return () => window.removeEventListener("click", handleClickOutside);
    }, []);

    useLayoutEffect(() => {
        const calcOptionsHeight: number = (options && options.length * 1.95 * 2) ?? 0;
        const calculatedOptionsHeight: number = calcOptionsHeight <= 16 ? calcOptionsHeight : 16;
        const parentWidth = parentRef.current?.offsetWidth;

        setOptionsHeight(calculatedOptionsHeight.toString());
        if (parentWidth) setParentWidth(parentWidth);
    }, [options, name]);

    const keyDownHandler = (event: KeyboardEvent<any> | any): void => {
        switch (event.key) {
            case "Enter":
                setIsOpen(true);
                event.target.focus();
                break;
            case "Delete":
                setIsOpen(false);
                setValues([]);
                break;
            case "Escape":
                setIsOpen(false);
                setValues([]);
                break;
            case "Tab":
                if (!isOpen) setIsOpen(true);
                break;
            default:
        }

        if (ref.current) {
            const items = ref.current.querySelectorAll("button");
            const id = event.shiftKey ? 1 : items.length - 1;

            if (event.target === items[id]) setIsOpen(false);
        }
    };

    const selectOption = ({
        value,
        name,
        icon,
        selected,
        index,
        optionalName,
    }: {
        value: string;
        name: string;
        icon: string | undefined;
        selected: boolean;
        index: number;
        optionalName: string | undefined;
    }) => (
        <div
            key={value + name}
            data-value={value}
            ref={ref}
            className={`option ${selected ? "selected" : ""} ${
                index === focusedValue ? "focused" : ""
            } ${searchValue === value ? "isSelected" : ""}`}
            onClick={(event) => {
                if (event.screenX === 0 && event.screenY === 0) setIsOpen(!isOpen);
                else setIsOpen(!isOpen);

                if (onChange) onChange(value);
                setFocusedValue(-1);
                if (inputRef && inputRef.current) {
                    inputRef.current.value = [name] as unknown as string;
                }
            }}
            onKeyUp={(event) => {
                if (event.key === "Tab") {
                    if (onChange) onChange(...[name]);
                    if (inputRef && inputRef.current) {
                        inputRef.current.value = [name] as unknown as string;
                    }
                }
            }}
            role="presentation"
        >
            <button
                className={`option__btn ${searchValue === value ? "isSelected" : ""}`}
                type="button"
            >
                {icon && (
                    <img className="option__img" src={icon && `/assets/${icon}.png`} alt={icon} />
                )}
                <span
                    className="option__name "
                    style={{ width: icon ? `${(parentWidth - 80).toString()}px` : "100%" }}
                >
                    {name} {optionalName && `- ${optionalName}`}
                </span>
            </button>
        </div>
    );

    const renderSelectOptions = () => {
        const isSearchable = searchTerm.length > 1 ? selectFilteredOptions : options;
        if (grouped) {
            const reducedGroupedOptions = isSearchable.reduce(
                (acc: { [x: string]: Option[] }, item: Option) => {
                    if (item?.groupLabel) {
                        acc[item.groupLabel] = acc[item.groupLabel] || [];
                        acc[item.groupLabel].push(item);
                        return acc;
                    }

                    return acc;
                },
                {}
            );

            return Object.keys(reducedGroupedOptions)
                .reverse()
                .map((item: string) => (
                    <div key={item} className="group-wrap">
                        <div className="group-label">
                            <span>{item}</span>
                            <span
                                className={`group-label-count ${
                                    reducedGroupedOptions[item].length > 99
                                        ? "group-label-count--lg"
                                        : ""
                                }`}
                            >
                                {kFormatter(reducedGroupedOptions[item].length)}
                            </span>
                        </div>
                        <div>
                            {reducedGroupedOptions[item].map((option: Option, index: number) => {
                                const { value, name, icon, optionalName } = option;
                                const selected = values.includes(value);

                                return selectOption({
                                    value,
                                    name,
                                    icon,
                                    selected,
                                    optionalName,
                                    index,
                                });
                            })}
                        </div>
                    </div>
                ));
        }
        return isSearchable.map((option: Option, index: number) => {
            const { value, name, icon } = option;
            const selected = values.includes(value);

            return (
                <div
                    key={value + name}
                    data-value={value}
                    ref={ref}
                    className={`option ${selected ? "selected" : ""} ${
                        index === focusedValue ? "focused" : ""
                    }`}
                    onClick={() => {
                        setIsOpen(!isOpen);
                        if (onChange) onChange(value);
                        setFocusedValue(-1);
                        if (inputRef && inputRef.current) {
                            inputRef.current.value = [name] as unknown as string;
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === "Tab") {
                            if (onChange) onChange(...[name]);
                            if (inputRef && inputRef.current) {
                                inputRef.current.value = [name] as unknown as string;
                            }
                        }
                    }}
                    role="presentation"
                >
                    <button className="option__btn" type="button">
                        {icon && (
                            <img
                                className="option__img"
                                src={icon && `/assets/${icon}.png`}
                                alt={icon}
                            />
                        )}
                        <span
                            className="option__name"
                            style={{ width: icon ? `${(parentWidth - 80).toString()}px` : "90%" }}
                        >
                            {name}
                        </span>
                    </button>
                </div>
            );
        });
    };

    const filterOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setInputValue(event.target.value);

        if (searchTerm !== "") {
            const searchRegex = new RegExp(event.target.value, "gi");

            const filteredOptions = options.reduce((acc: Option[], item: Option) => {
                if (item.name && item.name.match(searchRegex)) {
                    acc.push(item);
                }

                return acc;
            }, []);

            setSelectFilteredOptions(filteredOptions);
        } else setSelectFilteredOptions(options);
    };

    const selectedOption = options && options.find((option: Option) => option.value === value);
    const icon = selectedOption?.icon;

    const renderInputValue = () => {
        const selectedOption =
            selectFilteredOptions && selectFilteredOptions.find((o) => o.value === inputValue);
        if (selectedOption) {
            return selectedOption.name;
        }

        return inputValue === "{}" ? " " : inputValue;
    };

    const displayValue = renderInputValue();

    const { user } = userSlice((state) => state);

    // Report a bug if the rendered selected option isn't in alphanumerical format
    useEffect(() => {
        if (displayValue && !/^[^{}]*$/.test(displayValue)) {
            Sentry.captureMessage("Rendered selected option isn't in alphanumerical format", {
                tags: {
                    field: name,
                    displayValue,
                    inputValue,
                    value,
                    raceFromStorage: Storage.get(`${STORAGE_CONSTANTS.currentRace}.${user?.email}`),
                },
                extra: {
                    options,
                    selectFilteredOptions,
                },
            });
        }
    }, [displayValue, inputValue, name, options, selectFilteredOptions, user?.email, value]);

    return (
        <div
            className={`rdhq-select ${hasPadding ? "rdhq-select--has-padding" : ""}`}
            onKeyDown={keyDownHandler}
            ref={parentRef}
            role="presentation"
        >
            <div
                className={`rdhq-select__wrap ${isOpen ? "isOpen" : ""} ${
                    readonly ? "isReadonly" : ""
                }`}
                onClick={() => setIsOpen(!isOpen)}
                role="presentation"
            >
                {iconVisible && !disabled && !readonly && (
                    <ChevronDownOutlined className="rdhq-select__chevron" />
                )}
                {icon && iconVisible && (
                    <img
                        className="rdhq-select__icon"
                        src={icon && `/assets/${icon}.png`}
                        alt={icon}
                    />
                )}
                <input
                    type="text"
                    name={name ?? ""}
                    ref={inputRef}
                    placeholder={values.length === 0 ? inputValue : values[0]}
                    className={`value ${error ? "error" : ""} ${isOpen ? "isOpen" : ""} ${
                        icon && iconVisible ? "hasIcon" : ""
                    }`}
                    readOnly={!searchable || readonly}
                    autoComplete="off"
                    disabled={disabled || readonly}
                    onChange={filterOptions}
                    onBlur={onBlur}
                    value={displayValue}
                />
                {!disabled && !readonly && (
                    <div
                        className={`options ${isOpen ? "isOpen" : ""}`}
                        style={{ height: `${optionsHeight}rem` }}
                    >
                        {!_isEmpty(options) && renderSelectOptions()}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Select;

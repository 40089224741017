export const defaultVariables: { [key: string]: string } = {
    primaryColor: "#1bc537",
    primaryHover: "#16a12d",
    primaryHoverOpacity: "rgba(22, 161, 45, 0.05)",
    secondaryColor: "hsl(75, 68%, 54%)",
    teritaryColor: "hsl(192, 59%, 56%)",
    mainBackgroundColor: "#ffffff",
    darkBackgroundColor: "#000f29",
    success: "#48bb78",
    error: "#FF4D4F",
    warning: "#faf089",
    starColor: "#fcb003",
    textPrimary: "#646868",
    textMedium: "a8a8a8",
    textDark: "#393d47",
    textBlack: "#090B0B",
    grey50: "#f6f7f6",
    grey100: "#e8e8e8",
    grey200: "#f6f6f6",
    grey250: "#ededed",
    grey300: "#e0e0e0",
    grey350: "#d3d3d3",
    grey400: "#a8a8a8",
    grey500: "#656765",
    grey600: "#4d4d4d",
    grey700: "#424342",
    grey800: "#1e2e3d",
    greyBlack: "#191a19",
    green50: "#f0faf0",
    green200: "#cdeecd",
    green700: "#118425",
    bluePrimry: "#1853be",
    blue50: "#eef4ff",
};

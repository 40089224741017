import { FieldInputProps, FormikState } from "formik";
import { Input, Tooltip, Typography } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { fieldError } from "app/utils/helpers/formik";

const { TextArea } = Input;

export default ({
    label,
    required,
    field,
    form,
    rows = 6,
    tooltipText,
    tooltipPlacement = "right",
}: {
    label: string;
    required: boolean;
    field: FieldInputProps<string>;
    form: FormikState<string>;
    rows?: number;
    tooltipText?: string;
    tooltipPlacement?: TooltipPlacement;
}) => {
    const error = fieldError(form, field);
    return (
        <div>
            {label && (
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    <Typography.Text className="ant-label" strong>
                        <span className={`${tooltipText ? "tooltip-underline" : ""}`}>{label}</span>{" "}
                        {required ? <span className="ant-label-required">*</span> : null}
                    </Typography.Text>
                </Tooltip>
            )}
            <TextArea
                id={field.name}
                rows={rows}
                status={`${error ? "error" : ""}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                data-hj-allow
            />
            {error && !form.isSubmitting && (
                <Typography.Text className="ant-error-label">{error}</Typography.Text>
            )}
        </div>
    );
};

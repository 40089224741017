import { object, string, number } from "yup";
import { BudgetItemCategoryType } from "app/types/budget/budget.types";

const validationMessages = {
    required: "This field is required",
};

export const budgetItemValidationSchema = () =>
    object().shape({
        pk: number().optional(),
        price: number(),
        category: object()
            .shape({
                name: string(),
            })

            .required(validationMessages.required)
            .nullable(),
        event: object()
            .shape({
                pk: number(),
                name: string(),
            })
            .optional()
            .nullable(),
        quantity_type: string().when("category", {
            is: (category: BudgetItemCategoryType) => !category || category.items_editable,
            then: (schema) => schema.required(validationMessages.required),
        }),
        virtual_type: string().required(validationMessages.required),
        virtual_group: string().required("Please pick a revenue/cost group for this item"),
        quantity_override: number()
            .nullable()
            .when("quantity_type", {
                is: (quantity_type: string) => quantity_type === "custom",
                then: (schema) => schema.required(validationMessages.required),
            }),
        name_override: string()
            .nullable()
            .optional()
            .max(48, "Item name must be less than 48 characters"),
    });

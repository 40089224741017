/* eslint-disable react/no-unstable-nested-components */
import { TableColumnsType, Tooltip } from "antd";
import { BudgetItemType } from "app/types/budget/budget.types";
import { formatCurrency } from "app/utils/helpers/format_currency";
import { formatNumber } from "app/utils/helpers/format_number";
import { IUser } from "app/store/types/user.types";
import "./index.scss";
import { AIcon, PerCustomOutlined, PerEventOutlined, PerPaticipantOutlined } from "assets";
import { obscureData } from "app/utils/helpers/obscure_data";
import { HELP_LINKS } from "../constants";
import ObscuredBBWrapper from "../obscured_bb_wrapper";

const qty_type_icons: {
    [key: string]: JSX.Element;
} = {
    participant: <PerPaticipantOutlined />,
    event: <PerEventOutlined />,
    custom: <PerCustomOutlined />,
};

export const budget_columns = ({
    with: user,
    canAccessBB,
    type,
}: {
    with: Partial<IUser>;
    canAccessBB: boolean;
    type?: "cost" | "revenue";
}): TableColumnsType<BudgetItemType> => {
    const renderPremiumValue = (val: string) => (canAccessBB ? val : obscureData(val, "currency"));

    return [
        {
            title: " ",
            dataIndex: ["category", "name"],
            key: "name",
            render: (val, record) =>
                record.category?.name === "Other" ? record.name_override || "Other" : val,
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The event this item applies to.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.budgetView.event}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Event</span>
                </Tooltip>
            ),
            dataIndex: ["event", "name"],
            key: "event",
            ellipsis: true,
            render: (val) => val || "All events",
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The price {type === "cost" ? "spent" : "earned"} on this item.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.budgetView.price}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Price</span>
                </Tooltip>
            ),
            dataIndex: "price",
            key: "price",
            width: "8%",
            align: "right",
            className: "budget-sheet__price-cell",
            render: (val, record) => {
                const hasDecimals = (val: number) => val % 1 !== 0;
                const format = (val: number) =>
                    formatCurrency(val, user?.location?.ccy, 0, {
                        minDigits:
                            record.quantity_type === "participant" && hasDecimals(val) ? 2 : 0,
                        maxDigits:
                            record.quantity_type === "participant" && hasDecimals(val) ? 2 : 0,
                    });

                return (
                    <div>
                        {record.actualized ? format(record.actual_price) : format(record.price)}
                    </div>
                );
            },
        },
        {
            title: " ",
            dataIndex: "actualized",
            key: "actualized",
            width: 25,
            className: "budget-sheet__actualized-cell",
            render: (actualized) =>
                actualized && (
                    <Tooltip title="This is the actual price for this item">
                        <AIcon />
                    </Tooltip>
                ),
        },
        {
            title: " ",
            dataIndex: "quantity_type",
            key: "quantity_type",
            width: 60,
            align: "center",
            className: "budget-sheet__qty-type-cell",
            render: (val) => (
                <div>
                    <Tooltip title={val === "custom" ? "Custom quantity" : `Per ${val}`}>
                        {qty_type_icons[val]}
                    </Tooltip>
                </div>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The quantity this item applies to based on current participant numbers.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.budgetView.quantity}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Quantity</span>
                </Tooltip>
            ),
            dataIndex: "quantity_curr",
            key: "quantity_curr",
            className: "budget-sheet__current-cell-wrap budget-sheet__border-left-cell",
            width: "10%",
            align: "right",
            render: (val) => (
                <ObscuredBBWrapper>
                    <div className="budget-sheet__quantity-cell">
                        <span>x</span>
                        {renderPremiumValue(formatNumber(val))}
                    </div>
                </ObscuredBBWrapper>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title={
                        <span>
                            The total for this item based on current participant numbers.{" "}
                            <a
                                className="link-inline"
                                href={HELP_LINKS.budgetView.total}
                                target="learnMoreFrame"
                            >
                                Learn more
                            </a>
                        </span>
                    }
                    placement="top"
                >
                    <span className="tooltip-underline">Total</span>
                </Tooltip>
            ),
            align: "right",
            dataIndex: "total_curr",
            key: "total_curr",
            className: "budget-sheet__current-cell-wrap",
            width: "10%",
            render: (val, record) => (
                <ObscuredBBWrapper>
                    <div className="budget-sheet__total-cell">
                        <span>=</span>
                        <span>
                            {renderPremiumValue(
                                formatCurrency(Math.round(val), user?.location?.ccy, 0)
                            )}
                        </span>
                    </div>
                </ObscuredBBWrapper>
            ),
        },
        // placeholder column to add space
        {
            dataIndex: "",
            key: "",
            title: " ",
            width: 0,
        },
        {
            title: () => (
                <Tooltip
                    title="The quantity this item applies to based on the number of projected participants on race day."
                    placement="top"
                >
                    <span className="tooltip-underline">Quantity</span>
                </Tooltip>
            ),
            dataIndex: "quantity_proj",
            key: "quantity_proj",
            className: "budget-sheet__proj-cell-wrap budget-sheet__border-left-cell",
            width: "10%",
            align: "right",
            render: (val) => (
                <div className="budget-sheet__quantity-cell">
                    <span>x</span>
                    {formatNumber(val)}
                </div>
            ),
        },
        {
            title: () => (
                <Tooltip
                    title="The total for this item based on the number of projected participants on race day."
                    placement="top"
                >
                    <span className="tooltip-underline">Total</span>
                </Tooltip>
            ),
            align: "right",
            dataIndex: "total_proj",
            key: "total_proj",
            className: "budget-sheet__proj-cell-wrap budget-sheet__border-right-cell",
            width: "10%",
            render: (val, record) => (
                <div className="budget-sheet__total-cell">
                    <span>=</span>
                    <span>{formatCurrency(Math.round(val), user?.location?.ccy, 0)}</span>
                </div>
            ),
        },
    ];
};

/* eslint-disable import/no-extraneous-dependencies */
import { useQuery } from "@tanstack/react-query";
import { produce } from "immer";
import { AxiosError } from "axios";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import { RaceDetailsType, WidgetRaceType } from "app/types/races/race.types";
import Storage from "app/utils/storage/local";
import STORAGE_CONSTANTS from "app/constants/storage";
import racesSlice, { IRacesSlice } from "app/store/races/races.slice";
import { RaceFormDataTypes } from "app/store/races/races.types";
import { QUERY_KEYS } from "./constants";

// TODO ( Bojan ) -> Add proper types for the 'races' instead of 'any'
export const useGetRaces = (
    props: {
        filter?: "upcoming" | "past";
        page?: number;
    },
    options?: UseQueryOptionsType
): UseQueryReturnType<any | undefined> => {
    const { filter, page } = props;

    const { data, isPending, isError, error, isRefetching } = useQuery<any>({
        queryKey: [QUERY_KEYS.RACES, filter, page],
        queryFn: async ({ signal }) => {
            const userLocation = Storage.get(STORAGE_CONSTANTS.userLocation);

            // Update Zustand store
            racesSlice.setState(
                produce((state: IRacesSlice) => {
                    if (filter && userLocation) state.races[filter].location = userLocation;
                    if (filter && page) state.races[filter].page = page;
                })
            );

            let RACES_URL = API_URL.RACES;
            if (filter || page) RACES_URL += "?";
            if (filter) RACES_URL += `filter=${filter}&`;
            if (page) RACES_URL += `page=${page}&`;

            const { data } = await API.get<any>(RACES_URL, {
                signal,
            });
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending || isRefetching,
        isError,
        error,
    };
};

export const useGetWidgetRaces = (
    props: {
        filter?: "upcoming" | "past";
        display?: "menu" | "";
        page?: number;
    },
    options?: UseQueryOptionsType
): UseQueryReturnType<Partial<WidgetRaceType>[] | undefined> => {
    const { filter, display, page } = props;

    const { data, isPending, isError, error } = useQuery<Partial<WidgetRaceType>[]>({
        queryKey: [QUERY_KEYS.WIDGET_RACES, filter, display, page],
        queryFn: async ({ signal }) => {
            let RACES_URL = API_URL.RACES;
            if (filter || display || page) RACES_URL += "?";
            if (filter) RACES_URL += `filter=${filter}&`;
            if (display) RACES_URL += `display=${display}&`;
            if (page) RACES_URL += `page=${page}&`;

            const { data } = await API.get<{
                results: WidgetRaceType[];
            }>(RACES_URL, { signal });

            const raceData = data.results.map((race) => ({
                pk: race.pk,
                name: race.name,
                value: JSON.stringify(race),
                groupLabel: race.display_group,
            }));

            return raceData;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

export const useGetRaceDetails = (
    props: {
        pk: number | string;
        copied?: boolean;
    },
    options?: UseQueryOptionsType
): UseQueryReturnType<RaceDetailsType | undefined> => {
    const { pk, copied } = props;

    const { data, isPending, isError, error } = useQuery<RaceDetailsType>({
        queryKey: [QUERY_KEYS.RACE_DETAILS, pk],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<RaceDetailsType>(`${API_URL.RACE_DETAILS}${pk}/`, {
                signal,
            });
            if (copied) {
                data["start_date"] = "";
                Reflect.deleteProperty(data, "pk");
            }
            return data;
        },
        retry(failureCount, error) {
            if ((error as AxiosError).response?.status === 404) return false;
            return failureCount < 3;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

export const useGetRaceFormData = (
    props: Record<string, never>,
    options?: UseQueryOptionsType
): UseQueryReturnType<Partial<RaceFormDataTypes> | undefined> => {
    const { data, isPending, isError, error } = useQuery<Partial<RaceFormDataTypes>>({
        queryKey: [QUERY_KEYS.RACE_FORM_DATA],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<Partial<RaceFormDataTypes>>(API_URL.RACE_FORM_DATA, {
                signal,
            });

            const regions = data?.regions?.map((region) => {
                const regionCode = region.code ? region.code : region.name.split(" ").join("");
                return {
                    ...region,
                    code: regionCode,
                };
            });

            return {
                ...data,
                regions,
            };
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

import { useEffect, ReactNode } from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import Storage from "app/utils/storage/local";
import URL from "app/constants/route_urls";
import STORAGE_CONSTANTS from "app/constants/storage";

const API = axios.create({
    baseURL: import.meta.env.PROD ? import.meta.env.VITE_BASE_URL : "",
    timeout: 35_000,
    headers: {
        "Content-Type": "application/json",
    },
});

const getCookieValue = (name: string): string =>
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || "";

API.interceptors.request.use(
    (config: AxiosRequestConfig | any) => {
        const token: string | null = Storage.get(STORAGE_CONSTANTS.accessToken);
        const googleAnalytics: string | undefined = getCookieValue("_ga");

        if (!config?.headers)
            throw new Error("Expected 'config' and 'config.headers' not to be undefined");

        if (googleAnalytics) {
            // eslint-disable-next-line no-underscore-dangle
            config.headers._ga = googleAnalytics;
        }

        if (token) {
            config.headers = {
                Authorization: token && `Token ${token}`,
            };
        }

        config.headers["Content-Type"] = "application/json";
        config.withCredentials = true;
        axios.defaults.withCredentials = true;

        return config;
    },
    (error: AxiosError) => {
        Promise.reject(error);
    }
);

const AxiosInterceptor = ({ children }: { children: ReactNode }): any => {
    const navigate = useNavigate();
    useEffect(() => {
        const resInterceptor = (response: AxiosResponse) => response;

        const errInterceptor = (error: AxiosError) => {
            if (error?.response?.status === 401) {
                Storage.remove("access_token");
                window.location.href = URL.LOGIN;
            }
            if (error?.response?.status === 403) navigate(URL.FORBIDDEN);
            return Promise.reject(error);
        };

        API.interceptors.response.use(resInterceptor, errInterceptor);
    }, [navigate]);

    return children;
};

export default API;
export { AxiosInterceptor };

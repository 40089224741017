import { useQuery } from "@tanstack/react-query";
import { UseQueryReturnType, UseQueryOptionsType } from "app/utils/api/queries";
import { DocumentType } from "app/types/documents/document.types";
import API from "app/utils/api/axios";
import API_URL from "app/constants/api_urls";
import { QUERY_KEYS } from "./constants";

export const useGetDocuments = (
    options?: UseQueryOptionsType
): UseQueryReturnType<DocumentType[] | undefined> => {
    const { isPending, isError, data, error } = useQuery({
        queryKey: [QUERY_KEYS.DOCUMENTS],
        queryFn: async ({ signal }) => {
            const { data } = await API.get<DocumentType[]>(API_URL.DOCUMENTS, { signal });
            return data;
        },
        ...options,
    });

    return {
        data,
        isLoading: isPending,
        isError,
        error,
    };
};

import { ReactElement } from "react";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import googleIcon from "assets/icons/google.svg";
import "./index.scss";

function GoogleAuthLogin({
    text = "Google",
    isLoading,
    onError,
    tokenHandler,
}: {
    text: string;
    isLoading: boolean;
    onError: () => void;
    tokenHandler: (arg: string) => void;
}): ReactElement {
    const login = useGoogleLogin({
        onSuccess: (tokenResponse: TokenResponse): void => {
            tokenHandler(tokenResponse.access_token);
        },
        onError: (): void => onError(),
    });

    return (
        <button
            className={`google-auth ${isLoading ? "isLoading" : ""}`}
            type="button"
            onClick={() => login()}
        >
            <img className="google-auth__icon" src={googleIcon} alt="" />
            <span>{text}</span>
        </button>
    );
}

export default GoogleAuthLogin;

import { ReactElement, useEffect, useCallback } from "react";
import { Typography, Button, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useVendorListings } from "app/utils/api/queries";
import { ListingType } from "app/types";
import { _isEmpty } from "app/utils/helpers";
import listingsSlice from "app/store/listings/listings.slice";
import PageWithLoader from "app/hoc/page_with_loader";
import ListingCard from "app/components/elements/cards/listing_card/listing_card";
import { PlusOutlined } from "assets";
import URL from "app/constants/route_urls";
import { sortListingsInOrder } from "./listings_utils";
import "./listings.scss";

const VendorListings = (): ReactElement => {
    const [messageApi, contextHolder] = message.useMessage();
    const { data, isLoading, error, isError } = useVendorListings();
    const { currentListingSelection, setListingsCount } = listingsSlice((state) => state);
    const isListings = data && data?.length > 1 ? "listings" : "listing";

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSuccess = useCallback(
        (message: string): void => {
            messageApi.success(message);
        },
        [messageApi]
    );

    useEffect(() => {
        setListingsCount(data?.length as number);
    }, [data?.length, setListingsCount]);

    useEffect(() => {
        const listingSuccess = searchParams.get("success");
        if (listingSuccess) {
            const message = searchParams.get("success");
            handleSuccess(message as string);
            searchParams.delete("success");
            setSearchParams();
        }
    }, [searchParams, handleSuccess, setSearchParams]);

    const renderListings = (): JSX.Element[] | null => {
        if (!data) return null;
        const { firstListing, restListings } = sortListingsInOrder(data, currentListingSelection);

        if (!firstListing) return null;

        return [firstListing, ...restListings].map((listing: ListingType) => (
            <ListingCard key={listing.pk} {...listing} />
        ));
    };

    return (
        <>
            {contextHolder}
            <PageWithLoader isLoading={isLoading} error={isError} errorText={error?.message}>
                <div className="vendor-listings">
                    <div className="vendor-listings__top page__header">
                        <div className="vendor-listings__top-item">
                            <div>
                                <Typography.Title level={1}>Listings</Typography.Title>
                                <Typography.Text>
                                    A list of your directory business listings.
                                </Typography.Text>
                            </div>
                        </div>
                        <div className="vendor-listings__top-item">
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => navigate(URL.BUSINESS_LISTINGS_ADD)}
                            >
                                Add listing
                            </Button>
                        </div>
                    </div>
                    <div className="page__toolbar">
                        {!_isEmpty(data) ? (
                            <Typography.Text strong>
                                {data?.length} {isListings}
                            </Typography.Text>
                        ) : null}
                    </div>
                    <div className="vendor-listings__content">
                        {_isEmpty(data) ? (
                            <div className="vendor-listings__empty">
                                <Typography.Text>You have no listings to display.</Typography.Text>
                                <Typography.Text>
                                    <span
                                        role="presentation"
                                        className="link"
                                        onClick={() => navigate(URL.BUSINESS_LISTINGS_ADD)}
                                    >
                                        Add a listing
                                    </span>{" "}
                                    to start using your business dashboard tools.
                                </Typography.Text>
                            </div>
                        ) : (
                            <div className="vendor-listings__content-wrap">{renderListings()}</div>
                        )}
                    </div>
                </div>
            </PageWithLoader>
        </>
    );
};

export default VendorListings;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import API_URL from "app/constants/api_urls";
import { UserFreeTrial } from "app/types/user/free_trials.type";
import API from "../axios";
import { QUERY_KEYS } from "../queries/constants";

export const useStartFreeTrial = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (error: AxiosError<{ message: string }>) => void;
}) => {
    const queryClient = useQueryClient();

    return useMutation<
        UserFreeTrial,
        AxiosError<{ message: string }>,
        {
            app: string;
        }
    >({
        mutationFn: async ({ app }) => {
            const res = await API.post<UserFreeTrial>(`${API_URL.ADD_FREE_TRIAL}`, {
                program: {
                    app__slug: app,
                },
            });

            return res.data;
        },
        onSuccess: (data) => {
            queryClient.setQueryData([QUERY_KEYS.USER_FREE_TRIALS], (prev: UserFreeTrial[]) => [
                ...prev,
                { ...data },
            ]);

            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
        },
    });
};
